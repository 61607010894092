export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateOnly: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type Acl = {
  __typename?: 'ACL';
  basket?: Maybe<Basket>;
  contract?: Maybe<Contract>;
  createdAt: Scalars['Date']['output'];
  crossReferenceList?: Maybe<CrossReferenceList>;
  customer?: Maybe<Customer>;
  id: Scalars['ID']['output'];
  level: AcLevelEnum;
  offer?: Maybe<Offer>;
  organization?: Maybe<Organization>;
  owningCustomer: Customer;
  part?: Maybe<Part>;
  type: AcTypeEnum;
  updatedAt: Scalars['Date']['output'];
  user?: Maybe<User>;
};

export enum AcLevelEnum {
  Customer = 'customer',
  Global = 'global',
  Organization = 'organization',
  User = 'user'
}

export enum AcTypeEnum {
  Admin = 'admin',
  Read = 'read',
  Restrict = 'restrict',
  Write = 'write'
}

export type Activate = {
  __typename?: 'Activate';
  getSourcingPlan: SourcingPlan;
  startSourcingPlan: SourcingPlan;
};


export type ActivateGetSourcingPlanArgs = {
  id: Scalars['String']['input'];
};


export type ActivateStartSourcingPlanArgs = {
  input: StartSourcingPlanInput;
};

export type AddContractAttachmentInput = {
  contractId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
};

export type AddNetworkMemberInput = {
  networkId: Scalars['String']['input'];
  users: Array<InvitedUserInput>;
};

export type AddOrganizationToCustomerInput = {
  customerId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type AddSourcingPlanBasketInput = {
  basketId: Scalars['String']['input'];
  /** By pass checking if this Basket is already used in other in-process Sourcing Plans */
  force?: InputMaybe<Scalars['Boolean']['input']>;
  sourcingPlanId: Scalars['String']['input'];
  targetSavings?: InputMaybe<Scalars['Float']['input']>;
  targetSpend?: InputMaybe<Scalars['Float']['input']>;
};

export type AddSourcingPlanBasketsStakeholdersInput = {
  basketId: Scalars['String']['input'];
  /** This Contact ID could be `User`.`id` or `Contact`.`id`, set this to `null` and use `contactInput` field, if you want to create new contact */
  contactId?: InputMaybe<Scalars['String']['input']>;
  /** Manually input Contact information, `contactId` should be `null` when using this field */
  contactInput?: InputMaybe<CreateContactInput>;
  role: SourcingPlanStakeholderRole;
  sourcingPlanId: Scalars['String']['input'];
};

export type AddSourcingPlanStakeholderInput = {
  /** This Contact ID could be `User`.`id` or `Contact`.`id`, set this to `null` and use `contactInput` field, if you want to create new contact */
  contactId?: InputMaybe<Scalars['String']['input']>;
  /** Manually input Contact information, `contactId` should be `null` when using this field */
  contactInput?: InputMaybe<CreateContactInput>;
  role: SourcingPlanStakeholderRole;
  sourcingPlanId: Scalars['String']['input'];
};

export type AdditionalInfo = {
  __typename?: 'AdditionalInfo';
  keyContact?: Maybe<OpportunityKeyContact>;
  provider?: Maybe<OpportunityProvider>;
};

export type AdminRedeemOpportunityInput = {
  customerId: Scalars['String']['input'];
  invitationId?: InputMaybe<Scalars['String']['input']>;
  secretCode: Scalars['String']['input'];
};

export type AdvancedSearchGroup = {
  children?: InputMaybe<Array<AdvancedSearchGroup>>;
  field?: InputMaybe<Scalars['String']['input']>;
  isNegated?: InputMaybe<Scalars['Boolean']['input']>;
  operator?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AdvancedSearchGroupObj = {
  __typename?: 'AdvancedSearchGroupObj';
  children?: Maybe<Array<AdvancedSearchGroupObj>>;
  field?: Maybe<Scalars['String']['output']>;
  isNegated?: Maybe<Scalars['Boolean']['output']>;
  operator?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Array<Scalars['String']['output']>>;
};

export type AggregatedItemCommodityType = {
  __typename?: 'AggregatedItemCommodityType';
  description: Scalars['String']['output'];
  gtin: Scalars['String']['output'];
  manufacturer: Scalars['String']['output'];
  materialCode?: Maybe<Scalars['String']['output']>;
  medId?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
};

export type AggregatedItemSpend = {
  __typename?: 'AggregatedItemSpend';
  actualImpact?: Maybe<Scalars['Float']['output']>;
  benchmarks?: Maybe<Benchmark>;
  commodity?: Maybe<AggregatedItemCommodityType>;
  contract?: Maybe<Scalars['String']['output']>;
  currentPrice?: Maybe<Scalars['Float']['output']>;
  curvo25?: Maybe<Scalars['Float']['output']>;
  curvo50?: Maybe<Scalars['Float']['output']>;
  curvo75?: Maybe<Scalars['Float']['output']>;
  curvo90?: Maybe<Scalars['Float']['output']>;
  curvoCategory?: Maybe<Scalars['String']['output']>;
  datepurchase?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  esDescription?: Maybe<Scalars['String']['output']>;
  esProductNormalized?: Maybe<Scalars['String']['output']>;
  esProductStripped?: Maybe<Scalars['String']['output']>;
  esSupplierName?: Maybe<Scalars['String']['output']>;
  esSupplierNormalized?: Maybe<Scalars['String']['output']>;
  esVendorItemNormalized?: Maybe<Scalars['String']['output']>;
  esVendorNormalized?: Maybe<Scalars['String']['output']>;
  facilityId: Scalars['String']['output'];
  facilityItem?: Maybe<Scalars['String']['output']>;
  facilityName?: Maybe<Scalars['String']['output']>;
  firstPurchaseDate?: Maybe<Scalars['Float']['output']>;
  gicId?: Maybe<Scalars['Int']['output']>;
  gicName?: Maybe<Scalars['String']['output']>;
  gmdn?: Maybe<Array<Scalars['String']['output']>>;
  gudid?: Maybe<ItemGudid>;
  id: Scalars['String']['output'];
  impact3m?: Maybe<Scalars['Float']['output']>;
  impact12m?: Maybe<Scalars['Float']['output']>;
  itemName?: Maybe<Scalars['String']['output']>;
  l12mBenchmarks?: Maybe<Benchmark>;
  last12MonthsQuantity?: Maybe<Scalars['Float']['output']>;
  last12MonthsSpend?: Maybe<Scalars['Float']['output']>;
  lastPurchaseDate?: Maybe<Scalars['Date']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerItem?: Maybe<Scalars['String']['output']>;
  matchId?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  organizationItem?: Maybe<Scalars['String']['output']>;
  poNum?: Maybe<Scalars['String']['output']>;
  poNums: Array<Scalars['String']['output']>;
  pricingTrends?: Maybe<Trend>;
  qty?: Maybe<Scalars['Float']['output']>;
  stan?: Maybe<AggregatedItemStanType>;
  toLastPurchaseDays?: Maybe<Scalars['Int']['output']>;
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  totalSpend?: Maybe<Scalars['Float']['output']>;
  transactions?: Maybe<Array<Scalars['Int']['output']>>;
  tsId?: Maybe<Scalars['String']['output']>;
  typeOne?: Maybe<Scalars['String']['output']>;
  typeTwo?: Maybe<Scalars['String']['output']>;
  uom?: Maybe<Scalars['String']['output']>;
  uomConversion?: Maybe<Scalars['Float']['output']>;
  utilization?: Maybe<Scalars['Float']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  vendorItem?: Maybe<Scalars['String']['output']>;
};

export type AggregatedItemStanType = {
  __typename?: 'AggregatedItemStanType';
  brand?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  gicId: Scalars['Int']['output'];
  gicName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  manufacturer: Scalars['String']['output'];
  manufacturerId: Scalars['String']['output'];
  material?: Maybe<Scalars['String']['output']>;
  partNumber: Scalars['String']['output'];
  productline?: Maybe<Scalars['String']['output']>;
  segment?: Maybe<Scalars['String']['output']>;
  size1?: Maybe<Scalars['Float']['output']>;
  size2?: Maybe<Scalars['Float']['output']>;
  size3?: Maybe<Scalars['Float']['output']>;
  subSegment?: Maybe<Scalars['String']['output']>;
  typeOne?: Maybe<Scalars['String']['output']>;
  typeTwo?: Maybe<Scalars['String']['output']>;
};

export type AggregatedSearchResult = {
  __typename?: 'AggregatedSearchResult';
  fullResponse: Scalars['Boolean']['output'];
  hits: Array<AggregatedItemSpend>;
  savedSearch?: Maybe<SavedSearch>;
  searchFieldsFilterTerms: Array<SearchFieldFilterTerms>;
  totalHits: Scalars['Int']['output'];
  totalSpend: Scalars['Float']['output'];
};

export type ApproveStudyLinkInput = {
  isGudidApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isMedApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isStanApproved?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AttachmentUploadedFrom {
  Hospital = 'hospital',
  Vendor = 'vendor'
}

export type AuditLog = {
  __typename?: 'AuditLog';
  actor?: Maybe<User>;
  actorId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  eventName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  objectId: Scalars['String']['output'];
  objectType: Scalars['String']['output'];
};

export type AwardPrioritiesInput = {
  opportunityId: Scalars['String']['input'];
};

export type AwardPrioritiesResult = {
  __typename?: 'AwardPrioritiesResult';
  data: Array<OfferScenarioPriority>;
};

export type AwardScenarioInput = {
  opportunityId: Scalars['String']['input'];
  scenarioId: Scalars['String']['input'];
  status: Scalars['Boolean']['input'];
};

export type BaseAclRecordInput = {
  id: Scalars['String']['input'];
  owner?: InputMaybe<Scalars['String']['input']>;
  type: AcTypeEnum;
};

export type BaseTierRuleInput = {
  basketIds?: InputMaybe<Array<Scalars['String']['input']>>;
  metricType: TierRuleMetricType;
  name?: InputMaybe<Scalars['String']['input']>;
  operator: TierRuleOperator;
  periodType: TierRulePeriodType;
  ruleType: TierRuleType;
  scope?: InputMaybe<TierRuleScope>;
  valueFirst: Scalars['Float']['input'];
  valueSecond?: InputMaybe<Scalars['Float']['input']>;
};

export type BaseUpdateCatalogPartTierInput = {
  partsTsId: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  uom: Scalars['String']['input'];
  uomConversion?: InputMaybe<Scalars['Int']['input']>;
};

export type BaseUpdateOfferItemCross = {
  comment?: InputMaybe<OfferItemComment>;
  id: Scalars['String']['input'];
  noCross?: InputMaybe<Scalars['Boolean']['input']>;
  noCrossComment?: InputMaybe<Scalars['String']['input']>;
  offerTsId?: InputMaybe<Scalars['String']['input']>;
  pack?: InputMaybe<Array<CatalogPartPackInput>>;
};

export type BaseUpdateOfferItemLinkage = {
  createNew?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  itemTsId?: InputMaybe<Scalars['String']['input']>;
  type: CatalogPartType;
};

export type BaseUpdateOfferItemTierPrice = {
  conversion?: InputMaybe<Scalars['String']['input']>;
  /** Offer Item's ID */
  id: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  tierId: Scalars['String']['input'];
  uom?: InputMaybe<Scalars['String']['input']>;
};

export type BaseUpdateOfferPricingItemTierPrice = {
  conversion?: InputMaybe<Scalars['String']['input']>;
  /** This ID has 2 posible meaning, `BaseUpdateOfferItemTierPrice.id` or `CatalogPart.ts_id` based on the prefix: `offerItem#...` or `catalogPart#...` */
  id: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  tierId: Scalars['String']['input'];
  uom?: InputMaybe<Scalars['String']['input']>;
};

export type BaseUpdateOfferScenarioItemInput = {
  award?: InputMaybe<Scalars['Boolean']['input']>;
  exclude?: InputMaybe<Scalars['Boolean']['input']>;
  itemId: Scalars['String']['input'];
  tierId?: InputMaybe<Scalars['String']['input']>;
};

export type BaseUpdateStudyLink = {
  description?: InputMaybe<Scalars['String']['input']>;
  gudidManufacturer?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  matchGudidId?: InputMaybe<Scalars['String']['input']>;
  matchMedId?: InputMaybe<Scalars['String']['input']>;
  matchStanId?: InputMaybe<Scalars['String']['input']>;
  medManufacturer?: InputMaybe<Scalars['String']['input']>;
  mfgitem?: InputMaybe<Scalars['String']['input']>;
  stanManufacturerId?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
  venitem?: InputMaybe<Scalars['String']['input']>;
};

export type Basket = {
  __typename?: 'Basket';
  archivedAt?: Maybe<Scalars['Date']['output']>;
  calculatedDashboards: Array<Dashboard>;
  catalogId?: Maybe<Scalars['String']['output']>;
  complexity?: Maybe<Complexity>;
  context: Array<BasketContextResult>;
  contextStats?: Maybe<BasketContextStatsResult>;
  contract?: Maybe<Contract>;
  contractsUsedIn: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  creator?: Maybe<User>;
  customer?: Maybe<Customer>;
  fullProducts: BasketFullProductsResponse;
  id: Scalars['ID']['output'];
  insights: BasketInsights;
  insights12m?: Maybe<BasketInsights>;
  isCatalogBasket: Scalars['Boolean']['output'];
  isContractBasket: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  isSearchAll: Scalars['Boolean']['output'];
  isSmartBasket: Scalars['Boolean']['output'];
  isUploadBasket: Scalars['Boolean']['output'];
  likelihoods?: Maybe<BasketLikelihood>;
  name: Scalars['String']['output'];
  numItems: Scalars['Int']['output'];
  opportunitiesUsedIn: Scalars['Int']['output'];
  organization?: Maybe<Organization>;
  percentiles?: Maybe<Array<Scalars['Float']['output']>>;
  products?: Maybe<Array<BasketProduct>>;
  project?: Maybe<Project>;
  rules: Array<SmartBasketRule>;
  smartBasketConfig?: Maybe<SmartBasketConfig>;
  smartRules: Array<SmartRule>;
  sourcingPlanSuppliers?: Maybe<SourcingPlanSuppliers>;
  status: BasketStatusEnum;
  totalSpend?: Maybe<Scalars['Float']['output']>;
  type: BasketType;
  typeLabel: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};


export type BasketContextArgs = {
  input: GetBasketContextInput;
};


export type BasketFullProductsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type BasketInsightsArgs = {
  facilityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  includePercentiles?: InputMaybe<Scalars['Boolean']['input']>;
  spendDateRange?: InputMaybe<SpendDateRangeInput>;
};


export type BasketInsights12mArgs = {
  facilityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type BasketLikelihoodsArgs = {
  facilityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum BasketContextAggregateType {
  Customer = 'customer',
  Facility = 'facility',
  TopParent = 'topParent'
}

export type BasketContextResult = {
  __typename?: 'BasketContextResult';
  aggregateId: Scalars['String']['output'];
  aggregateName: Scalars['String']['output'];
  manufacturerTotal: Scalars['Float']['output'];
  marketShare: Scalars['Float']['output'];
  normalizedManufacturer: Scalars['String']['output'];
  percentiles: Array<Scalars['Float']['output']>;
  priceScore: Scalars['Int']['output'];
};

export type BasketContextStatsResult = {
  __typename?: 'BasketContextStatsResult';
  avgMarketShareTopVendor: Scalars['Float']['output'];
  catalogId: Scalars['String']['output'];
  pricingTrend: Scalars['Float']['output'];
  top3VendorsAvgMarketShare: Array<BasketContextStatsTop3>;
};

export type BasketContextStatsTop3 = {
  __typename?: 'BasketContextStatsTop3';
  avgMarketShare: Scalars['Float']['output'];
  normalizedManufacturer: Scalars['String']['output'];
};

export type BasketDashboard = {
  __typename?: 'BasketDashboard';
  basket?: Maybe<Basket>;
  basketId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  dashboard?: Maybe<Dashboard>;
  dashboardId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type BasketFacilityStat = {
  __typename?: 'BasketFacilityStat';
  basketId: Scalars['String']['output'];
  basketName: Scalars['String']['output'];
  facilityId: Scalars['Float']['output'];
  facilityName: Scalars['String']['output'];
  sumIqo25: Scalars['Float']['output'];
  sumIqo50: Scalars['Float']['output'];
  sumIqo75: Scalars['Float']['output'];
  sumIqo90: Scalars['Float']['output'];
  sumIqoByPercentile: Scalars['Float']['output'];
  sumProjectedSpend: Scalars['Float']['output'];
  sumTotalSpend: Scalars['Float']['output'];
};

export type BasketFullProductsResponse = {
  __typename?: 'BasketFullProductsResponse';
  data: Array<AggregatedItemSpend>;
  total: Scalars['Float']['output'];
};

export type BasketInsights = {
  __typename?: 'BasketInsights';
  basketId?: Maybe<Scalars['String']['output']>;
  complexity?: Maybe<Complexity>;
  facilityIds: Array<Scalars['Int']['output']>;
  iqo25?: Maybe<Scalars['Float']['output']>;
  iqo50?: Maybe<Scalars['Float']['output']>;
  iqo75?: Maybe<Scalars['Float']['output']>;
  iqo90?: Maybe<Scalars['Float']['output']>;
  normalizedVendors?: Maybe<Array<Scalars['String']['output']>>;
  numItems: Scalars['Int']['output'];
  opportunity25?: Maybe<Scalars['Float']['output']>;
  opportunity50?: Maybe<Scalars['Float']['output']>;
  opportunity75?: Maybe<Scalars['Float']['output']>;
  opportunity90?: Maybe<Scalars['Float']['output']>;
  percentiles?: Maybe<Array<Scalars['Float']['output']>>;
  processingJobId?: Maybe<Scalars['String']['output']>;
  projectedSpend?: Maybe<Scalars['Float']['output']>;
  sourcingPlanSuppliers?: Maybe<SourcingPlanSuppliers>;
  topIncumbent?: Maybe<SourcingPlanTopIncumbent>;
  totalSpend: Scalars['Float']['output'];
};

export type BasketLikelihood = {
  __typename?: 'BasketLikelihood';
  catalogId?: Maybe<Scalars['String']['output']>;
  curvo25: Scalars['Float']['output'];
  curvo50: Scalars['Float']['output'];
  curvo75: Scalars['Float']['output'];
  curvo90: Scalars['Float']['output'];
  customerId: Scalars['ID']['output'];
  percentiles: Array<Scalars['Float']['output']>;
  priceScore: Scalars['Int']['output'];
  priceScoreLikelihoods: Array<Scalars['Float']['output']>;
  targetPriceScore: Scalars['Int']['output'];
  totalSpend: Scalars['Float']['output'];
};

export type BasketMetricsUpdateResult = {
  __typename?: 'BasketMetricsUpdateResult';
  basket: Basket;
  error?: Maybe<Scalars['String']['output']>;
};

export type BasketPriority = {
  __typename?: 'BasketPriority';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BasketProduct = {
  __typename?: 'BasketProduct';
  basket: Basket;
  catalogId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  creator?: Maybe<User>;
  facilityId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  normalizedManufacturer?: Maybe<Scalars['String']['output']>;
  percentiles?: Maybe<Array<Scalars['Float']['output']>>;
  quantity?: Maybe<Scalars['Float']['output']>;
  strippedManufacturerItem?: Maybe<Scalars['String']['output']>;
  tsId: Scalars['String']['output'];
  uom: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type BasketProductInput = {
  facilityId: Scalars['String']['input'];
  tsId: Scalars['String']['input'];
  uom: Scalars['String']['input'];
};

export type BasketSearchInput = {
  facilities?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type BasketSmartRuleInput = {
  commodityId?: InputMaybe<Scalars['String']['input']>;
  gicTypeOneTypeTwo?: InputMaybe<Scalars['String']['input']>;
  gmdnName?: InputMaybe<Scalars['String']['input']>;
  gudidBrand?: InputMaybe<Scalars['String']['input']>;
  gudidId?: InputMaybe<Scalars['String']['input']>;
  /** If null, create new rule otherwise update existing rule */
  id?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  stanId?: InputMaybe<Scalars['String']['input']>;
  tsId?: InputMaybe<Scalars['String']['input']>;
  unspscClassTitle?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
};

export type BasketSort = {
  dir: Scalars['String']['input'];
  field: BasketSortEnum;
};

export enum BasketSortEnum {
  Likelihood = 'likelihood',
  Name = 'name',
  NumItems = 'numItems',
  Opportunity = 'opportunity',
  TargetSavings = 'targetSavings',
  TotalSpend = 'totalSpend'
}

export type BasketSpendDateRangeInput = {
  dateRange?: InputMaybe<SpendDateRange>;
  spendDateRange: BasketSpendDateRanges;
};

export enum BasketSpendDateRanges {
  AllTime = 'AllTime',
  CustomDateRange = 'CustomDateRange',
  Last12Months = 'Last12Months'
}

export enum BasketStatusEnum {
  Active = 'active',
  Archived = 'archived',
  Draft = 'draft'
}

export enum BasketType {
  Archived = 'archived',
  Catalog = 'catalog',
  Contract = 'contract',
  Custom = 'custom',
  Public = 'public',
  Uploaded = 'uploaded'
}

export type BasketsFacilitiesChartFilter = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<BasketType>;
};

export type BasketsResult = {
  __typename?: 'BasketsResult';
  hits: Array<Basket>;
  totalHits: Scalars['Int']['output'];
};

export type Benchmark = {
  __typename?: 'Benchmark';
  averagePrice?: Maybe<Scalars['Float']['output']>;
  hospitalCount?: Maybe<Scalars['Float']['output']>;
  iqo25?: Maybe<Scalars['Float']['output']>;
  iqo50?: Maybe<Scalars['Float']['output']>;
  iqo75?: Maybe<Scalars['Float']['output']>;
  iqo90?: Maybe<Scalars['Float']['output']>;
  maxUnitPrice?: Maybe<Scalars['Float']['output']>;
  minUnitPrice?: Maybe<Scalars['Float']['output']>;
  percentiles?: Maybe<Array<Scalars['Float']['output']>>;
  priceSpray?: Maybe<Array<PriceSpray>>;
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  totalSpend?: Maybe<Scalars['Float']['output']>;
};

export enum BenchmarkPercentiles {
  Iqo25 = 'iqo25',
  Iqo50 = 'iqo50',
  Iqo75 = 'iqo75',
  Iqo90 = 'iqo90'
}

export type BulkApproveStudyLinkInput = {
  catalog?: InputMaybe<StudyLinkCatalogEnum>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  studyId: Scalars['Int']['input'];
  tsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  update: ApproveStudyLinkInput;
  vendor?: InputMaybe<Scalars['String']['input']>;
};

export type BulkUpdateBasketSmartRulesInput = {
  basketId: Scalars['String']['input'];
  /** If true, replace all existing rules by the new input rules */
  replace: Scalars['Boolean']['input'];
  rules: Array<BasketSmartRuleInput>;
};

export type BulkUpdateCatalogPartTierInput = {
  tierId: Scalars['String']['input'];
  updates: Array<BaseUpdateCatalogPartTierInput>;
};

export type BulkUpdateHomepageFeaturedContentInput = {
  /** If customerId is null, which is global, remove existing global featured contents that are not in `updates`. Same with customer is not null, remove existing customer based featured contents which are not in `updates */
  customerId?: InputMaybe<Scalars['String']['input']>;
  /** If true, replace all current existing FeaturedContent with the updates */
  replaceAll?: InputMaybe<Scalars['Boolean']['input']>;
  updates: Array<HomepageFeaturedContentUpdate>;
};

export type BulkUpdateOfferItemCrossInput = {
  offerId: Scalars['String']['input'];
  savingToCrossList?: InputMaybe<Scalars['Boolean']['input']>;
  updates: Array<BaseUpdateOfferItemCross>;
};

export type BulkUpdateOfferItemLinkageInput = {
  offerId: Scalars['String']['input'];
  savingToCrossList?: InputMaybe<Scalars['Boolean']['input']>;
  updates: Array<BaseUpdateOfferItemLinkage>;
  updatingCross?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulkUpdateOfferItemTiersPriceInput = {
  offerId: Scalars['String']['input'];
  updates: Array<BaseUpdateOfferItemTierPrice>;
};

export type BulkUpdateOfferItemsUploadInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  data: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  offerId: Scalars['String']['input'];
  selectedExcelSheet?: InputMaybe<Scalars['String']['input']>;
};

export type BulkUpdateOfferPricingItemTiersPriceInput = {
  offerId: Scalars['String']['input'];
  updates: Array<BaseUpdateOfferPricingItemTierPrice>;
};

export type BulkUpdateOfferScenarioItemsInput = {
  inputs: Array<BaseUpdateOfferScenarioItemInput>;
  scenarioId: Scalars['String']['input'];
};

export type BulkUpdateOpportunityItemsPricingTargetInput = {
  ids: Array<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  pricingTarget?: InputMaybe<Scalars['Float']['input']>;
  pricingTargetType?: InputMaybe<OfferPricingTargetType>;
};

export type BulkUpdateStudyLinkInput = {
  catalog?: InputMaybe<StudyLinkCatalogEnum>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  studyId: Scalars['Int']['input'];
  tsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  update: BaseUpdateStudyLink;
  vendor?: InputMaybe<Scalars['String']['input']>;
};

export type BulkUserOrganizationRoleUpdateInput = {
  organizationsId: Scalars['String']['input'];
  updates: Array<UserOrganizationRoleUpdateInputBase>;
};

export type BulkUserPermissionUpdateInput = {
  orgs: Array<UserOrganizationPermissionInput>;
};

export type CatalogPart = {
  __typename?: 'CatalogPart';
  /** Type of the `CatalogPart` item. `mine` means the item comes from a manufacturer/vendor which this customer is providing. Take a note that `mine` does not mean the item is in this customer's Catalog. `isCompetitor` means the items comes from a manufacturer/vendor that this customer is not providing items from */
  catalogPartType: CatalogPartType;
  category?: Maybe<Scalars['String']['output']>;
  commodityId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  customer: Customer;
  description?: Maybe<Scalars['String']['output']>;
  gicId?: Maybe<Scalars['Int']['output']>;
  gicName?: Maybe<Scalars['String']['output']>;
  gmdn?: Maybe<Array<Scalars['String']['output']>>;
  gtin?: Maybe<Scalars['String']['output']>;
  gudId?: Maybe<Scalars['String']['output']>;
  isImplantable?: Maybe<Scalars['Boolean']['output']>;
  isNormalized: Scalars['Boolean']['output'];
  linkedItem?: Maybe<CatalogPart>;
  linkedTsId?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerItem?: Maybe<Scalars['String']['output']>;
  materialId?: Maybe<Scalars['String']['output']>;
  normalizedManufacturer?: Maybe<Scalars['String']['output']>;
  offerItems?: Maybe<Array<OfferItem>>;
  originalDescription?: Maybe<Scalars['String']['output']>;
  pack: Array<CatalogPartPack>;
  partTiers?: Maybe<Array<CatalogPartTier>>;
  segmentationId?: Maybe<Scalars['String']['output']>;
  side?: Maybe<Scalars['String']['output']>;
  sizeOne: Scalars['Float']['output'];
  sizeThree: Scalars['Float']['output'];
  sizeTwo: Scalars['Float']['output'];
  /** The source of Catalog Part, to check whether the item comes from uploading (`catalogUpload`) or generated (`spend`) as needed for Offer/Opportunity, Cross Reference features. `catalogUpload` means the item is in Vendor Catalog */
  source: CatalogPartSource;
  stanId?: Maybe<Scalars['String']['output']>;
  strippedManufacturerItem?: Maybe<Scalars['String']['output']>;
  subCategory?: Maybe<Scalars['String']['output']>;
  suggestion?: Maybe<CatalogPartSuggestion>;
  tsId: Scalars['String']['output'];
  typeOneId?: Maybe<Scalars['String']['output']>;
  typeOneName?: Maybe<Scalars['String']['output']>;
  typeTwoId?: Maybe<Scalars['String']['output']>;
  typeTwoName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  vendor?: Maybe<Scalars['String']['output']>;
  vendorItem?: Maybe<Scalars['String']['output']>;
};

export type CatalogPartPack = {
  __typename?: 'CatalogPartPack';
  catalogPart: CatalogPart;
  id: Scalars['String']['output'];
  numberPerPack: Scalars['Int']['output'];
  packId: Scalars['String']['output'];
  tsId: Scalars['String']['output'];
  uom: Scalars['String']['output'];
  uomConversion: Scalars['Int']['output'];
};

export type CatalogPartPackInput = {
  numberPerPack?: Scalars['Int']['input'];
  tsId: Scalars['String']['input'];
  uom?: Scalars['String']['input'];
  uomConversion?: Scalars['Int']['input'];
};

export type CatalogPartResult = {
  __typename?: 'CatalogPartResult';
  hits: Array<CatalogPart>;
  totalHits: Scalars['Int']['output'];
};

export enum CatalogPartSortFieldEnum {
  Category = 'category',
  Description = 'description',
  Manufacturer = 'manufacturer',
  ManufacturerItem = 'manufacturerItem',
  SubCategory = 'subCategory',
  UpdatedAt = 'updatedAt',
  Vendor = 'vendor',
  VendorItem = 'vendorItem'
}

export enum CatalogPartSource {
  CatalogUpload = 'catalogUpload',
  Pack = 'pack',
  Spend = 'spend'
}

export type CatalogPartSuggestion = {
  __typename?: 'CatalogPartSuggestion';
  isMine: Scalars['Boolean']['output'];
  item: CatalogPartSuggestionItem;
};

export type CatalogPartSuggestionItem = {
  __typename?: 'CatalogPartSuggestionItem';
  commodityId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  gudId?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerItem?: Maybe<Scalars['String']['output']>;
  stanId?: Maybe<Scalars['String']['output']>;
  tsId: Scalars['String']['output'];
  vendor?: Maybe<Scalars['String']['output']>;
  vendorItem?: Maybe<Scalars['String']['output']>;
};

export type CatalogPartTier = {
  __typename?: 'CatalogPartTier';
  averagePrice?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Date']['output'];
  curvo25?: Maybe<Scalars['Float']['output']>;
  curvo50?: Maybe<Scalars['Float']['output']>;
  curvo75?: Maybe<Scalars['Float']['output']>;
  curvo90?: Maybe<Scalars['Float']['output']>;
  customerId: Scalars['String']['output'];
  /** True means this price item was modified and is different from its correspondence (same parts_ts_id) parent tier */
  modifiedPrice?: Maybe<Scalars['Boolean']['output']>;
  pack?: Maybe<Array<CatalogPartTier>>;
  part: CatalogPart;
  partsTsId: Scalars['String']['output'];
  percentiles?: Maybe<Array<Scalars['Float']['output']>>;
  price: Scalars['Float']['output'];
  score?: Maybe<Scalars['Int']['output']>;
  tier: Tier;
  tiersId: Scalars['ID']['output'];
  uom: Scalars['String']['output'];
  uomConversion: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type CatalogPartTierResult = {
  __typename?: 'CatalogPartTierResult';
  hits: Array<CatalogPartTier>;
  totalHits: Scalars['Int']['output'];
};

export type CatalogPartTiersFilterInput = {
  priceFilter?: InputMaybe<CatalogPartTiersPricingFilterInput>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type CatalogPartTiersPricingFilterInput = {
  operator: TierFilterOperatorEnum;
  priceFirst?: InputMaybe<Scalars['Float']['input']>;
  priceSecond?: InputMaybe<Scalars['Float']['input']>;
};

export enum CatalogPartType {
  IsCompetitor = 'isCompetitor',
  Pack = 'pack',
  ToBeDeterminedMine = 'toBeDeterminedMine',
  VendorCatalog = 'vendorCatalog'
}

export type CatalogPartsSortInput = {
  direction?: InputMaybe<OrderDirection>;
  field: CatalogPartSortFieldEnum;
};

export type CatalogSearchInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isMasterCatalogSearch?: Scalars['Boolean']['input'];
  normalizedManufacturer?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type CatalogStats = {
  __typename?: 'CatalogStats';
  lastUpdatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CategoryConsultRequest = {
  __typename?: 'CategoryConsultRequest';
  /** a.k.a `Category` */
  basket?: Maybe<Basket>;
  basketId: Scalars['String']['output'];
  estimatedComplexity?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  stakeholderSupport?: Maybe<Scalars['Boolean']['output']>;
  status: CategoryConsultRequestStatus;
  suppliers?: Maybe<Scalars['Boolean']['output']>;
  targetSpendLikelihood?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

export type CategoryConsultRequestInput = {
  estimatedComplexity?: InputMaybe<Scalars['Boolean']['input']>;
  stakeholderSupport?: InputMaybe<Scalars['Boolean']['input']>;
  suppliers?: InputMaybe<Scalars['Boolean']['input']>;
  targetSpendLikelihood?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CategoryConsultRequestStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Finished = 'finished'
}

export type Change = {
  __typename?: 'Change';
  after?: Maybe<Scalars['JSON']['output']>;
  before?: Maybe<Scalars['JSON']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export type CheckExistVendorInput = {
  email: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
};

export type CheckPermissionInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

export type ClinicalStudies = {
  __typename?: 'ClinicalStudies';
  completionDate?: Maybe<Scalars['String']['output']>;
  leadSponsorName?: Maybe<Scalars['String']['output']>;
  nctId: Scalars['String']['output'];
  officialTitle?: Maybe<Scalars['String']['output']>;
  studyType?: Maybe<Scalars['String']['output']>;
};

export type ClinicalStudiesInput = {
  gudid: Scalars['String']['input'];
};

export type ClinicalStudiesResult = {
  __typename?: 'ClinicalStudiesResult';
  hits: Array<ClinicalStudies>;
  totalHit: Scalars['Int']['output'];
};

export type ClusterCatalogPart = {
  __typename?: 'ClusterCatalogPart';
  createdAt: Scalars['Date']['output'];
  crossReferenceCluster: CrossReferenceCluster;
  crossReferenceClusterId: Scalars['ID']['output'];
  customerId: Scalars['String']['output'];
  fromVendor?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  part: CatalogPart;
  partTsId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type ClusterPart = {
  __typename?: 'ClusterPart';
  createdAt: Scalars['Date']['output'];
  crossReferenceCluster: CrossReferenceCluster;
  crossReferenceClusterId: Scalars['ID']['output'];
  customerId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  part: Part;
  partTsId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type ColumnAttributes = {
  __typename?: 'ColumnAttributes';
  field: SearchField;
  filter?: Maybe<ColumnFilter>;
  hide?: Maybe<Scalars['Boolean']['output']>;
  pin?: Maybe<Scalars['Boolean']['output']>;
  searchOn?: Maybe<Scalars['Boolean']['output']>;
  sortBy?: Maybe<OrderDirection>;
  type?: Maybe<ColumnDataType>;
};

export type ColumnAttributesInput = {
  field: SearchField;
  filter?: InputMaybe<ColumnFilterInput>;
  hide?: InputMaybe<Scalars['Boolean']['input']>;
  pin?: InputMaybe<Scalars['Boolean']['input']>;
  searchOn?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<OrderDirection>;
  type?: InputMaybe<ColumnDataType>;
};

export enum ColumnDataType {
  Benchmark = 'benchmark',
  Date = 'date',
  Number = 'number',
  String = 'string'
}

export type ColumnFilter = {
  __typename?: 'ColumnFilter';
  benchmarkComparison?: Maybe<Comparison>;
  benchmarkPercentile?: Maybe<BenchmarkPercentiles>;
  dateFrom?: Maybe<Scalars['Date']['output']>;
  dateTo?: Maybe<Scalars['Date']['output']>;
  filterTerms?: Maybe<Array<Scalars['String']['output']>>;
  numberFrom?: Maybe<Scalars['Float']['output']>;
  numberTo?: Maybe<Scalars['Float']['output']>;
};

export type ColumnFilterInput = {
  benchmarkComparison?: InputMaybe<Comparison>;
  benchmarkPercentile?: InputMaybe<BenchmarkPercentiles>;
  dateFrom?: InputMaybe<Scalars['Date']['input']>;
  dateTo?: InputMaybe<Scalars['Date']['input']>;
  filterTerms?: InputMaybe<Array<Scalars['String']['input']>>;
  numberFrom?: InputMaybe<Scalars['Float']['input']>;
  numberTo?: InputMaybe<Scalars['Float']['input']>;
};

export enum Comparison {
  Eq = 'eq',
  Gt = 'gt',
  Lt = 'lt'
}

export type Complexity = {
  __typename?: 'Complexity';
  measuredAt: Scalars['Date']['output'];
  metrics: Array<ComplexityMetrics>;
  overallLevel: ComplexityLevelEnum;
};

export enum ComplexityLevelEnum {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export type ComplexityMetrics = {
  __typename?: 'ComplexityMetrics';
  level: ComplexityLevelEnum;
  name: ComplexityMetricsEnum;
  recordedMeasurement?: Maybe<Scalars['Float']['output']>;
};

export enum ComplexityMetricsEnum {
  ImplantableProductPercentage = 'implantableProductPercentage',
  Skus = 'skus',
  UniqueManufacturers = 'uniqueManufacturers'
}

export enum ConstructMasterGroup {
  Arthroplasty = 'Arthroplasty',
  Other = 'Other',
  Spine = 'Spine',
  Trauma = 'Trauma'
}

export type Contact = {
  __typename?: 'Contact';
  customer?: Maybe<Customer>;
  customerId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

/** A wrapper Object for getting contacts, which are mixed of users and contacts */
export type ContactUserWrapper = {
  __typename?: 'ContactUserWrapper';
  /** Contact Information, if `user` is not null this contains `user` information */
  contact: Contact;
  user?: Maybe<User>;
};

export type Contract = {
  __typename?: 'Contract';
  activeDue: Scalars['DateOnly']['output'];
  activeSince: Scalars['DateOnly']['output'];
  attachments?: Maybe<Array<ContractAttachment>>;
  baskets: Array<Basket>;
  category?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  crossReferenceList?: Maybe<CrossReferenceList>;
  crossReferenceListId?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Customer>;
  description: Scalars['String']['output'];
  editable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  latestUploadJob?: Maybe<UploadJob>;
  latestXrefUploadJob?: Maybe<UploadJob>;
  name: Scalars['String']['output'];
  numTiers?: Maybe<Scalars['Int']['output']>;
  organizations: Array<ContractOrganization>;
  partsCount: Scalars['Int']['output'];
  reportStatus: ContractReportStatusEnum;
  sourcingOfficer: Scalars['String']['output'];
  status?: Maybe<ContractStatusEnum>;
  subCategories?: Maybe<Array<Scalars['String']['output']>>;
  supplier: Scalars['String']['output'];
  tiers?: Maybe<Array<ContractTier>>;
  updatedAt: Scalars['Date']['output'];
};

export type ContractAttachment = {
  __typename?: 'ContractAttachment';
  contractId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  s3Key: Scalars['String']['output'];
};

export type ContractBasketInput = {
  contractId: Scalars['String']['input'];
};

export enum ContractFilterStatus {
  Active = 'active',
  Draft = 'draft',
  Expired = 'expired',
  ExpiringSoon = 'expiringSoon',
  Pending = 'pending'
}

export type ContractHistory = {
  __typename?: 'ContractHistory';
  descriptiveActions: Array<DescriptiveAction>;
  log: AuditLog;
};

export type ContractHistoryInput = {
  contractId: Scalars['String']['input'];
};

export type ContractHistoryResult = {
  __typename?: 'ContractHistoryResult';
  hits: Array<ContractHistory>;
  total: Scalars['Int']['output'];
};

export type ContractMappedFieldsType = {
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  mfgitem?: InputMaybe<Scalars['String']['input']>;
  subCategory?: InputMaybe<Scalars['String']['input']>;
  tiers?: InputMaybe<Array<ContractTierMapping>>;
  uom?: InputMaybe<Scalars['String']['input']>;
  uomConversion?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
  venitem?: InputMaybe<Scalars['String']['input']>;
};

export type ContractMember = {
  __typename?: 'ContractMember';
  contractId: Scalars['String']['output'];
  currentPriceScore?: Maybe<Scalars['Int']['output']>;
  declinedBy?: Maybe<Scalars['String']['output']>;
  declinedByEmail?: Maybe<Scalars['String']['output']>;
  declinedByFirstName?: Maybe<Scalars['String']['output']>;
  declinedByLastName?: Maybe<Scalars['String']['output']>;
  extendedStats: ContractMemberExtendedStats;
  /** Organization's ID */
  id: Scalars['String']['output'];
  /** ContractOrganization's ID */
  memberId?: Maybe<Scalars['String']['output']>;
  /** Organization's name */
  name: Scalars['String']['output'];
  numItems?: Maybe<Scalars['Int']['output']>;
  percentiles?: Maybe<Array<Scalars['Float']['output']>>;
  priceActivationDate?: Maybe<Scalars['Date']['output']>;
  priceDeactivationDate?: Maybe<Scalars['Date']['output']>;
  tierDescription?: Maybe<Scalars['String']['output']>;
  /** Selected Tier's ID */
  tierId?: Maybe<Scalars['String']['output']>;
  tierName?: Maybe<Scalars['String']['output']>;
  tiersStats?: Maybe<Array<ContractMemberTierStats>>;
  /** Organization's Top Parent's ID */
  topParentId: Scalars['String']['output'];
  /** Organization's Top Parent's name */
  topParentName: Scalars['String']['output'];
  totalSpend?: Maybe<Scalars['Float']['output']>;
  totalUnits?: Maybe<Scalars['Float']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userFirstName?: Maybe<Scalars['String']['output']>;
  userLastName?: Maybe<Scalars['String']['output']>;
};

export type ContractMemberExtendedStats = {
  __typename?: 'ContractMemberExtendedStats';
  contractId: Scalars['String']['output'];
  /** Organization's ID */
  id: Scalars['String']['output'];
  last12MonthsSpend: Array<ContractMemberMonthlySpend>;
  lastPurchaseDate: Scalars['Date']['output'];
  manufacturersMarketShare: Array<ContractMemberManufacturerStats>;
};

export type ContractMemberInput = {
  declined?: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  memberId?: InputMaybe<Scalars['String']['input']>;
  priceActivationDate?: InputMaybe<Scalars['Date']['input']>;
  priceDeactivationDate?: InputMaybe<Scalars['Date']['input']>;
  tierId?: InputMaybe<Scalars['String']['input']>;
};

export type ContractMemberManufacturerStats = {
  __typename?: 'ContractMemberManufacturerStats';
  /** Market Share in percentage */
  marketShare: Scalars['Float']['output'];
  normalizedManufacturer?: Maybe<Scalars['String']['output']>;
  totalSpend: Scalars['Float']['output'];
};

export type ContractMemberMonthlySpend = {
  __typename?: 'ContractMemberMonthlySpend';
  month: Scalars['String']['output'];
  totalSpend?: Maybe<Scalars['Float']['output']>;
};

export type ContractMemberTierStats = {
  __typename?: 'ContractMemberTierStats';
  coveredTotalSpend: Scalars['Float']['output'];
  coveredUnits: Scalars['Float']['output'];
  targetPriceScore?: Maybe<Scalars['Int']['output']>;
  targetSpend: Scalars['Float']['output'];
  targetSpendAfterRebate: Scalars['Float']['output'];
  tierId: Scalars['String']['output'];
  tierName: Scalars['String']['output'];
  tierRulesPassed: TierRuleValidation;
};

export type ContractOrganization = {
  __typename?: 'ContractOrganization';
  contract: Contract;
  contractsId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  facilityId: Scalars['Int']['output'];
  organization: Organization;
  organizationsId: Scalars['ID']['output'];
  tier: Tier;
  tiersId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  updator?: Maybe<User>;
};

export type ContractOrganizationHistory = {
  __typename?: 'ContractOrganizationHistory';
  contract: Contract;
  contractOrganization: ContractOrganization;
  contractOrganizationId: Scalars['ID']['output'];
  contractsId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  facilityId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  organization: Organization;
  organizationsId: Scalars['ID']['output'];
  priceActivationDate?: Maybe<Scalars['Date']['output']>;
  priceDeactivationDate?: Maybe<Scalars['Date']['output']>;
  tier: Tier;
  tiersId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  updator?: Maybe<User>;
};

export enum ContractReportStatusEnum {
  Available = 'Available',
  NotAvailable = 'NotAvailable',
  Outdated = 'Outdated'
}

export enum ContractSearchFieldEnum {
  Category = 'category',
  Code = 'code',
  Description = 'description',
  Name = 'name',
  SourcingOfficer = 'sourcingOfficer',
  SubCategory = 'subCategory',
  Supplier = 'supplier'
}

export enum ContractStatusEnum {
  Active = 'active',
  Draft = 'draft',
  Expired = 'expired',
  Pending = 'pending'
}

export type ContractTier = {
  __typename?: 'ContractTier';
  contract: Contract;
  contractsId: Scalars['ID']['output'];
  tier: Tier;
  tiersId: Scalars['ID']['output'];
};

export type ContractTierInput = {
  contractsId?: InputMaybe<Scalars['String']['input']>;
  tiersId?: InputMaybe<Scalars['String']['input']>;
};

export type ContractTierMapping = {
  field: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type ContractXrefMappedFieldsType = {
  competitorDescription?: InputMaybe<Scalars['String']['input']>;
  competitorManufacturer?: InputMaybe<Scalars['String']['input']>;
  competitorMfgitem?: InputMaybe<Scalars['String']['input']>;
  competitorVendor?: InputMaybe<Scalars['String']['input']>;
  competitorVenitem?: InputMaybe<Scalars['String']['input']>;
  offerDescription?: InputMaybe<Scalars['String']['input']>;
  offerManufacturer?: InputMaybe<Scalars['String']['input']>;
  offerMfgitem?: InputMaybe<Scalars['String']['input']>;
  offerVendor?: InputMaybe<Scalars['String']['input']>;
  offerVenitem?: InputMaybe<Scalars['String']['input']>;
};

export type CopyCurvoBasketInput = {
  categoryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CopyCurvoBasketResponse = {
  __typename?: 'CopyCurvoBasketResponse';
  basket: Basket;
  jobId: Scalars['String']['output'];
};

export type CreateBasketDashboardInput = {
  baskets: Array<Scalars['String']['input']>;
  dashboardId: Scalars['String']['input'];
};

export type CreateCatalogPartInput = {
  commodityId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  gicId?: InputMaybe<Scalars['Float']['input']>;
  gicName?: InputMaybe<Scalars['String']['input']>;
  gmdn?: InputMaybe<Array<Scalars['String']['input']>>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  gudId?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  manufacturerItem?: InputMaybe<Scalars['String']['input']>;
  partTiers?: InputMaybe<Array<PriceTierInput>>;
  stanId?: InputMaybe<Scalars['String']['input']>;
  typeOneId?: InputMaybe<Scalars['String']['input']>;
  typeOneName?: InputMaybe<Scalars['String']['input']>;
  typeTwoId?: InputMaybe<Scalars['String']['input']>;
  typeTwoName?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
  vendorItem?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContractDashboardInput = {
  contracts: Array<Scalars['String']['input']>;
  dashboardId: Scalars['String']['input'];
};

export type CreateContractInput = {
  activeDue: Scalars['Date']['input'];
  activeSince: Scalars['Date']['input'];
  category?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sourcingOfficer?: InputMaybe<Scalars['String']['input']>;
  subCategories?: InputMaybe<Array<Scalars['String']['input']>>;
  supplier: Scalars['String']['input'];
};

export type CreateContractTierInput = {
  contractsId: Scalars['String']['input'];
  organizationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tier?: InputMaybe<TierInput>;
  tierRules?: InputMaybe<TierRuleInput>;
  tiersId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCrossReferenceListInput = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCustomerInput = {
  address: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  geoCode?: InputMaybe<Scalars['String']['input']>;
  maxUser: Scalars['Int']['input'];
  medicareId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDashboardInput = {
  angleTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  arnValue?: InputMaybe<Scalars['String']['input']>;
  /** Valid CSS color value */
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  basketList?: InputMaybe<Array<Scalars['String']['input']>>;
  contractList?: InputMaybe<Array<Scalars['String']['input']>>;
  dashboardId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of Ant Design Icons e.g DashboardOutlined */
  icon?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateInitiativeInput = {
  attachments?: InputMaybe<Array<InitiativeAttachmentInput>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  completedAt?: InputMaybe<Scalars['Date']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactFirstName?: InputMaybe<Scalars['String']['input']>;
  contactLastName?: InputMaybe<Scalars['String']['input']>;
  hospitalArea: Scalars['String']['input'];
  isPublic: Scalars['Boolean']['input'];
  medtech?: InputMaybe<Scalars['String']['input']>;
  networkId?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['Date']['input']>;
  status: InitiativeStatusEnum;
  title: Scalars['String']['input'];
  valueElement?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateMessageTemplateInput = {
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type CreateNetworkWithUsersInput = {
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  users?: InputMaybe<Array<InvitedUserInput>>;
};

export type CreateOfferInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateOfferScenarioInput = {
  name: Scalars['String']['input'];
  offerIds: Array<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  priorities: Array<OfferScenarioPriorityInput>;
  tierIds: Array<Scalars['String']['input']>;
};

export type CreateOpportunityInput = {
  basketIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  estimateDueDate?: InputMaybe<Scalars['Date']['input']>;
  estimateStartDate?: InputMaybe<Scalars['Date']['input']>;
  goal?: InputMaybe<Scalars['Float']['input']>;
  goalThresholdMax?: InputMaybe<Scalars['Float']['input']>;
  /** Key Contact User ID */
  keyContactId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sharedUsers?: InputMaybe<Array<BaseAclRecordInput>>;
  skipZeroQuantityItems?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateOrganizationInput = {
  address: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  customerIds: Array<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  facilityId?: InputMaybe<Scalars['Int']['input']>;
  geoCode?: InputMaybe<Scalars['String']['input']>;
  medicareId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationTypeId?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrganizationTypeInput = {
  name: Scalars['String']['input'];
};

export type CreateQuicksightGroupInput = {
  groupName: Scalars['String']['input'];
};

export type CreateQuicksightGroupType = {
  __typename?: 'CreateQuicksightGroupType';
  Group?: Maybe<QuicksightGroupType>;
};

export type CreateSavedMappingInput = {
  mappedFields?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  type: MappingTypesEnum;
};

export type CreateSavedSearchInput = {
  advancedSearchQuery?: InputMaybe<Array<AdvancedSearchGroup>>;
  grouping?: InputMaybe<Array<GroupField>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  includeAggs?: InputMaybe<IncludeAggsEnum>;
  isRecent?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  pin?: InputMaybe<Scalars['Boolean']['input']>;
  resultCount?: InputMaybe<Scalars['Int']['input']>;
  searchFields: Array<ColumnAttributesInput>;
  searchText: Scalars['String']['input'];
  spendDateRangeInput?: InputMaybe<SpendDateRangeSearchInput>;
};

export type CreateSmartBasketInput = {
  isSearchAll: Scalars['Boolean']['input'];
  isSmartBasket?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  products?: InputMaybe<Array<BasketProductInput>>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  rules: Array<SmartBasketRuleInput>;
  search?: InputMaybe<CreateSavedSearchInput>;
  uploadedSearch?: InputMaybe<SearchByUploadedSearchIdInput>;
};

export type CreateTierInput = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateUserInput = {
  customerId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateVendorInput = {
  address: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  vendorFeatures?: InputMaybe<VendorFeaturesInput>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVendorUserInput = {
  appianId: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  isCurvoAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  role: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export enum CrossListOperationEnum {
  ApplyOnNonCrossed = 'applyOnNonCrossed',
  ResetAndApply = 'resetAndApply'
}

export type CrossReferenceCluster = {
  __typename?: 'CrossReferenceCluster';
  clusterCatalogParts: Array<ClusterCatalogPart>;
  clusterParts: Array<ClusterPart>;
  createdAt: Scalars['Date']['output'];
  crossReferenceList?: Maybe<CrossReferenceList>;
  crossReferenceListId?: Maybe<Scalars['ID']['output']>;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type CrossReferenceClustersResult = {
  __typename?: 'CrossReferenceClustersResult';
  hits: Array<CrossReferenceCluster>;
  totalHits: Scalars['Int']['output'];
};

export type CrossReferenceList = {
  __typename?: 'CrossReferenceList';
  contract?: Maybe<Contract>;
  createdAt: Scalars['Date']['output'];
  crossReferenceClusters: Array<CrossReferenceCluster>;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isSystem: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  offerCrossStats: OfferCrossStats;
  plainCrossReferences: PlainCrossReferenceList;
  supplierCrossReference?: Maybe<SupplierCrossReference>;
  supplierCrossReferences: SupplierCrossReferenceResult;
  /** Typeahead terms query for `supplierCrossReferences` */
  supplierCrossReferencesTypeAhead: Array<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  user?: Maybe<User>;
};


export type CrossReferenceListOfferCrossStatsArgs = {
  offerId: Scalars['String']['input'];
};


export type CrossReferenceListPlainCrossReferencesArgs = {
  crossStatus?: InputMaybe<Scalars['Boolean']['input']>;
  matchType?: InputMaybe<CrossReferenceType>;
  pagination?: InputMaybe<Pagination>;
  searchOnFields?: InputMaybe<Array<SupplierCrossReferenceSearchOnField>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<SupplierCrossReferencesSortInput>>;
  tsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  xrefTsIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type CrossReferenceListSupplierCrossReferenceArgs = {
  xrefTsId: Scalars['String']['input'];
};


export type CrossReferenceListSupplierCrossReferencesArgs = {
  crossStatus?: InputMaybe<Scalars['Boolean']['input']>;
  mappingsLimit?: InputMaybe<Scalars['Float']['input']>;
  matchType?: InputMaybe<CrossReferenceType>;
  pagination?: InputMaybe<Pagination>;
  searchOnFields?: InputMaybe<Array<SupplierCrossReferenceSearchOnField>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<SupplierCrossReferencesSortInput>>;
  tsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  xrefTsIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type CrossReferenceListSupplierCrossReferencesTypeAheadArgs = {
  crossStatus?: InputMaybe<Scalars['Boolean']['input']>;
  searchOnFields: Array<SupplierCrossReferenceSearchOnField>;
  termsOnField: SupplierCrossReferenceSearchField;
  xrefTsIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CrossReferenceListResult = {
  __typename?: 'CrossReferenceListResult';
  hits: Array<CrossReferenceList>;
  totalHits: Scalars['Int']['output'];
};

export type CrossReferenceMap = {
  __typename?: 'CrossReferenceMap';
  catalogPart?: Maybe<CatalogPart>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  crossReferenceList?: Maybe<CrossReferenceList>;
  crossReferenceListId: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  noCross?: Maybe<OfferItemComment>;
  tsId: Scalars['String']['output'];
  type?: Maybe<CrossReferenceType>;
  updatedAt: Scalars['Date']['output'];
  xrefCatalogPart?: Maybe<CatalogPart>;
  xrefTsId?: Maybe<Scalars['String']['output']>;
};

export type CrossReferenceMapStats = {
  __typename?: 'CrossReferenceMapStats';
  lastUpdatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CrossReferenceOfferItemInput = {
  crossReferenceListIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  operationType?: InputMaybe<CrossListOperationEnum>;
};

export enum CrossReferenceType {
  Alternative = 'alternative',
  Exact = 'exact',
  Other = 'other',
  Similar = 'similar'
}

export type CrossStats = {
  __typename?: 'CrossStats';
  totalCrossed: Scalars['Float']['output'];
  totalItems: Scalars['Float']['output'];
  totalLeftUncrossed: Scalars['Float']['output'];
  totalUncrossed: Scalars['Float']['output'];
};

export enum CrossedStatus {
  Crossed = 'crossed',
  LeftUncrossed = 'leftUncrossed',
  Uncrossed = 'uncrossed'
}

export enum CurvoErrorCode {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  ValidationError = 'VALIDATION_ERROR'
}

export type Customer = {
  __typename?: 'Customer';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  geoCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  mainContact?: Maybe<User>;
  maxUser: Scalars['Int']['output'];
  medicareId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  normalizedName?: Maybe<Scalars['String']['output']>;
  organizations: Array<Organization>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  stripeId: Scalars['String']['output'];
  topUsers: Array<User>;
  totalContracts?: Maybe<Scalars['Int']['output']>;
  totalOrganizations?: Maybe<Scalars['Int']['output']>;
  totalPowerUsers?: Maybe<Scalars['Int']['output']>;
  totalUsers?: Maybe<Scalars['Int']['output']>;
  type: CustomerTypeEnum;
  updatedAt: Scalars['Date']['output'];
  user: User;
  users: Array<User>;
  vendorFeatures?: Maybe<VendorFeatures>;
  website?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type CustomerTopUsersArgs = {
  isPowerUser?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerActivationInput = {
  id: Scalars['String']['input'];
};

export type CustomerMainContactInput = {
  customerId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export enum CustomerTypeEnum {
  ServiceProvider = 'serviceProvider',
  Supplier = 'supplier'
}

export type CustomerView = {
  __typename?: 'CustomerView';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  geoCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  mainContact?: Maybe<User>;
  medicareId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  normalizedName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  type: CustomerTypeEnum;
  website?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  angleTypes?: Maybe<Array<Scalars['String']['output']>>;
  arnValue?: Maybe<Scalars['String']['output']>;
  backgroundColor?: Maybe<Scalars['String']['output']>;
  basketDashboards: Array<BasketDashboard>;
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  supplierSection?: Maybe<Array<SupplierSectionEnum>>;
  updatedAt: Scalars['Date']['output'];
};

export type DashboardFilter = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type DashboardSort = {
  dir: Scalars['String']['input'];
  field: DashboardSortEnum;
};

export enum DashboardSortEnum {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type DashboardsResult = {
  __typename?: 'DashboardsResult';
  hits: Array<Dashboard>;
  totalHits: Scalars['Int']['output'];
};

export type DeleteCrossReferenceMapsInput = {
  /** If this parameter is null, the System will try to use the System Generated List */
  crossReferenceListId?: InputMaybe<Scalars['String']['input']>;
  partTsIds: Array<Scalars['String']['input']>;
};

export type DeleteSavedMappingInput = {
  id: Scalars['String']['input'];
};

export type DescriptiveAction = {
  __typename?: 'DescriptiveAction';
  action: Scalars['String']['output'];
  changes?: Maybe<Array<Change>>;
  object: Scalars['String']['output'];
};

export type DetailedSearchInput = {
  facilityId: Scalars['Int']['input'];
  tsId: Scalars['String']['input'];
};

export type DoUploadSearchInput = {
  mappedFields: MappedFieldsType;
  mappedManufacturers: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
  searchFields: Array<ColumnAttributesInput>;
  selectedExcelSheet?: InputMaybe<Scalars['String']['input']>;
};

export type DoUploadSearchResult = {
  __typename?: 'DoUploadSearchResult';
  uploadedSearchId: Scalars['String']['output'];
};

export type DownloadVendorOfferInput = {
  customerId: Scalars['String']['input'];
  offerId: Scalars['String']['input'];
};

export type ExportAggregatedSearchInput = {
  advancedSearchQuery?: InputMaybe<Array<AdvancedSearchGroup>>;
  exportPo: Scalars['Boolean']['input'];
  grouping?: InputMaybe<Array<GroupField>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  includeAggs?: InputMaybe<IncludeAggsEnum>;
  isRecent?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  pin?: InputMaybe<Scalars['Boolean']['input']>;
  resultCount?: InputMaybe<Scalars['Int']['input']>;
  searchFields: Array<ColumnAttributesInput>;
  searchText: Scalars['String']['input'];
  uploadedSearchId?: InputMaybe<Scalars['String']['input']>;
};

export enum ExportCatalogPartsFieldEnum {
  Category = 'category',
  Description = 'description',
  Gtin = 'gtin',
  Manufacturer = 'manufacturer',
  ManufacturerItem = 'manufacturerItem',
  Price = 'price',
  SubCategory = 'subCategory',
  TierName = 'tierName',
  Uom = 'uom',
  UomConversion = 'uomConversion',
  Vendor = 'vendor',
  VendorItem = 'vendorItem'
}

export type ExportCatalogPartsInput = {
  exportedFields?: InputMaybe<Array<ExportCatalogPartsFieldEnum>>;
  tierId?: InputMaybe<Scalars['String']['input']>;
};

export type ExportCatalogPartsPayload = {
  __typename?: 'ExportCatalogPartsPayload';
  downloadUrl: Scalars['String']['output'];
  s3Key?: Maybe<Scalars['String']['output']>;
};

export enum ExportCrossRefercenceColumn {
  CrossedPartDescription = 'crossedPartDescription',
  CrossedPartManufacturer = 'crossedPartManufacturer',
  CrossedPartManufacturerItem = 'crossedPartManufacturerItem',
  CrossedPartVendor = 'crossedPartVendor',
  CrossedPartVendorItem = 'crossedPartVendorItem',
  NoCrossComment = 'noCrossComment',
  PartDescription = 'partDescription',
  PartManufacturer = 'partManufacturer',
  PartManufacturerItem = 'partManufacturerItem',
  PartVendor = 'partVendor',
  PartVendorItem = 'partVendorItem'
}

export type ExportCrossReferencesOption = {
  columns?: InputMaybe<Array<ExportCrossRefercenceColumn>>;
  crossStatus?: InputMaybe<CrossReferenceCrossStatus>;
  matchType?: InputMaybe<CrossReferenceType>;
  pagination?: InputMaybe<Pagination>;
  searchOnFields?: InputMaybe<Array<SupplierCrossReferenceSearchOnField>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sorts?: InputMaybe<Array<SupplierCrossReferencesSortInput>>;
  tsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  xrefTsIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ExportEventResultType = {
  __typename?: 'ExportEventResultType';
  downloadUrl: Scalars['String']['output'];
};

export type ExportHistories = {
  __typename?: 'ExportHistories';
  data: Array<UploadJob>;
};

export type ExportItemPoTransactionsInput = {
  facilityId: Scalars['Int']['input'];
  tsId: Scalars['String']['input'];
};

export type ExtendedOfferItem = {
  __typename?: 'ExtendedOfferItem';
  averagePrice?: Maybe<Scalars['Float']['output']>;
  basket?: Maybe<Basket>;
  comment?: Maybe<OfferItemComment>;
  createdAt: Scalars['Date']['output'];
  crossReferenceSuggestions?: Maybe<Array<CatalogPart>>;
  crossStatus?: Maybe<Scalars['Boolean']['output']>;
  curvo25?: Maybe<Scalars['Float']['output']>;
  curvo50?: Maybe<Scalars['Float']['output']>;
  curvo75?: Maybe<Scalars['Float']['output']>;
  curvo90?: Maybe<Scalars['Float']['output']>;
  customer?: Maybe<Customer>;
  customerId: Scalars['String']['output'];
  esId: Scalars['String']['output'];
  facilityId?: Maybe<Scalars['String']['output']>;
  facilityName?: Maybe<Scalars['String']['output']>;
  /** A group ID for items with the same product */
  groupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  item?: Maybe<CatalogPart>;
  itemConversion?: Maybe<Scalars['String']['output']>;
  itemEnrichment?: Maybe<OfferItemEnrichment>;
  itemTsId: Scalars['String']['output'];
  itemUoM?: Maybe<Scalars['String']['output']>;
  masterCatalogData?: Maybe<MasterCatalogData>;
  matchedCrossRefVendorItems?: Maybe<Scalars['Int']['output']>;
  noCross?: Maybe<Scalars['Boolean']['output']>;
  noCrossComment?: Maybe<Scalars['String']['output']>;
  offer?: Maybe<Offer>;
  offerId: Scalars['String']['output'];
  offerItem?: Maybe<CatalogPart>;
  offerTsId?: Maybe<Scalars['String']['output']>;
  percentiles?: Maybe<Array<Scalars['Float']['output']>>;
  priceTiers?: Maybe<Array<CatalogPartTier>>;
  pricingTarget?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  secondOfferItem?: Maybe<CatalogPart>;
  secondOfferTsId?: Maybe<Scalars['String']['output']>;
  selectedPriceTierIds?: Maybe<Array<Scalars['String']['output']>>;
  submittedItem?: Maybe<CatalogPart>;
  submittedTsId: Scalars['String']['output'];
  suggestion?: Maybe<OfferItemSuggestion>;
  unitprice?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type ExtendedQueuedLinkProcess = {
  __typename?: 'ExtendedQueuedLinkProcess';
  aiGudidOverwrite?: Maybe<Scalars['Boolean']['output']>;
  aiMedOverwrite?: Maybe<Scalars['Boolean']['output']>;
  aiStanOverwrite?: Maybe<Scalars['Boolean']['output']>;
  asp?: Maybe<Scalars['Float']['output']>;
  bamfGudidId?: Maybe<Scalars['String']['output']>;
  bamfMedId?: Maybe<Scalars['String']['output']>;
  bamfStanId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionOrg?: Maybe<Scalars['String']['output']>;
  gmdn?: Maybe<Array<Scalars['String']['output']>>;
  gudidCatalogNumber?: Maybe<Scalars['String']['output']>;
  gudidCompanyName?: Maybe<Scalars['String']['output']>;
  gudidDescription?: Maybe<Scalars['String']['output']>;
  gudidManufacturer?: Maybe<Scalars['String']['output']>;
  gudidMatch: Scalars['String']['output'];
  /** curvo normalized names of gudid matched suppliers */
  gudidMatchedNormSuppliers?: Maybe<Array<Scalars['String']['output']>>;
  gudidState: Scalars['String']['output'];
  gudidVersionModelNumber?: Maybe<Scalars['String']['output']>;
  hide?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  ignoreManufacturer?: Maybe<Scalars['Boolean']['output']>;
  ignoreRow?: Maybe<Scalars['Boolean']['output']>;
  ignoreVendor?: Maybe<Scalars['Boolean']['output']>;
  isGudidApproved?: Maybe<Scalars['Boolean']['output']>;
  isGudidSupplierVen?: Maybe<Scalars['Boolean']['output']>;
  isMedApproved?: Maybe<Scalars['Boolean']['output']>;
  isMedSupplierVen?: Maybe<Scalars['Boolean']['output']>;
  isReprocessed?: Maybe<Scalars['Boolean']['output']>;
  isStanApproved?: Maybe<Scalars['Boolean']['output']>;
  isStanSupplierVen?: Maybe<Scalars['Boolean']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerGudidType?: Maybe<Scalars['String']['output']>;
  manufacturerMedType?: Maybe<Scalars['String']['output']>;
  manufacturerOrg?: Maybe<Scalars['String']['output']>;
  manufacturerStanType?: Maybe<Scalars['String']['output']>;
  matchGudidId?: Maybe<Scalars['String']['output']>;
  matchMedId?: Maybe<Scalars['String']['output']>;
  matchStanId?: Maybe<Scalars['String']['output']>;
  medDescription?: Maybe<Scalars['String']['output']>;
  medManufacturer?: Maybe<Scalars['String']['output']>;
  medMatch: Scalars['String']['output'];
  /** curvo normalized names of medline/commodity matched suppliers */
  medMatchedNormSuppliers?: Maybe<Array<Scalars['String']['output']>>;
  medMaterialCode?: Maybe<Scalars['String']['output']>;
  medSku?: Maybe<Scalars['String']['output']>;
  medState: Scalars['String']['output'];
  mfgitem?: Maybe<Scalars['String']['output']>;
  mfgitemOrg?: Maybe<Scalars['String']['output']>;
  priceTiers?: Maybe<Array<TransactionTier>>;
  qtypurchase?: Maybe<Scalars['Int']['output']>;
  stanManufacturer?: Maybe<Scalars['String']['output']>;
  stanManufacturerId?: Maybe<Scalars['String']['output']>;
  stanManufacturerOb?: Maybe<Manufacturer>;
  stanMatch: Scalars['String']['output'];
  stanPartDescription?: Maybe<Scalars['String']['output']>;
  stanPartNumber?: Maybe<Scalars['String']['output']>;
  stanState: Scalars['String']['output'];
  study: QueuedStudy;
  /** curvo normalized names of normalized vendor and normalized manufacturer */
  supplierNormCurvos?: Maybe<Array<Scalars['String']['output']>>;
  /** manufacturer IDs of normalized vendor and normalized manufacturer */
  supplierNormManufacturerIds?: Maybe<Array<Scalars['String']['output']>>;
  /** whether this supplier produces reprocessed items */
  supplierProducesReprocessed?: Maybe<Scalars['Boolean']['output']>;
  totalSpend?: Maybe<Scalars['Float']['output']>;
  tsId: Scalars['String']['output'];
  uom?: Maybe<Scalars['String']['output']>;
  uomconversion?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  vendorOrg?: Maybe<Scalars['String']['output']>;
  venitem?: Maybe<Scalars['String']['output']>;
  venitemOrg?: Maybe<Scalars['String']['output']>;
};

export type ExternalAuthConnection = {
  __typename?: 'ExternalAuthConnection';
  id: Scalars['String']['output'];
  isDisabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type FacilityInfo = {
  __typename?: 'FacilityInfo';
  batchId?: Maybe<Scalars['String']['output']>;
  facilities?: Maybe<Array<FacilityInfoItem>>;
};

export type FacilityInfoItem = {
  __typename?: 'FacilityInfoItem';
  facilityId: Scalars['Float']['output'];
  spendDateFrom?: Maybe<Scalars['Date']['output']>;
  spendDateTo?: Maybe<Scalars['Date']['output']>;
};

export type FeaturedBasket = {
  __typename?: 'FeaturedBasket';
  basket: Basket;
  featuredTag?: Maybe<Scalars['String']['output']>;
  featuredValue?: Maybe<Scalars['Float']['output']>;
};

export type FetchMappingFieldsInput = {
  s3Key: Scalars['String']['input'];
};

export type FetchMappingFieldsResult = {
  __typename?: 'FetchMappingFieldsResult';
  data: Array<MapField>;
};

export type FieldLiteralMapping = {
  type: MappingTypeEnum;
  value: Scalars['String']['input'];
};

export type FilterColumnInput = {
  columnName: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
  selectedExcelSheet?: InputMaybe<Scalars['String']['input']>;
};

export type FilterColumnResult = {
  __typename?: 'FilterColumnResult';
  data: Array<Scalars['String']['output']>;
};

export type GmdnTerm = {
  __typename?: 'GMDNTerm';
  gmdnptdefinition?: Maybe<Scalars['String']['output']>;
  gmdnptname: Scalars['String']['output'];
};

export type GenerateBasketSmartRulesInput = {
  basketId: Scalars['String']['input'];
};

export type GetBasketAclByCriteriaInput = {
  basketId?: InputMaybe<Scalars['ID']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  level?: InputMaybe<AcLevelEnum>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<AcTypeEnum>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetBasketContextInput = {
  aggregateType: BasketContextAggregateType;
};

export type GetBasketProductsByCriteriaInput = {
  basketId?: InputMaybe<Scalars['String']['input']>;
  creatorId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type GetBasketsByCriteriaInput = {
  creatorId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<BasketSort>;
  targetPercentile?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<BasketType>;
};

export type GetCatalogPartsByCriteriaInput = {
  linked?: InputMaybe<Scalars['Boolean']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  tsIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetContractAclByCriteriaInput = {
  contractId?: InputMaybe<Scalars['ID']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  level?: InputMaybe<AcLevelEnum>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<AcTypeEnum>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetContractMemberInput = {
  contractId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type GetContractMembersInput = {
  contractId: Scalars['String']['input'];
  pagination?: InputMaybe<Pagination>;
  withoutStats?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetContractOrganizationsByCriteriaInput = {
  contractsId?: InputMaybe<Scalars['String']['input']>;
  organizationsId?: InputMaybe<Scalars['String']['input']>;
  tiersId?: InputMaybe<Scalars['String']['input']>;
};

export type GetContractPartsInput = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
};

export type GetContractsByCriteriaInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchIns?: InputMaybe<Array<ContractSearchFieldEnum>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Sort>;
  status?: InputMaybe<ContractFilterStatus>;
  subCategories?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetCrossReferenceListAclByCriteriaInput = {
  crossReferenceListId?: InputMaybe<Scalars['ID']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<AcLevelEnum>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<AcTypeEnum>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetCrossReferenceListsByCriteriaInput = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GetCustomersByCriteriaInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CustomerTypeEnum>;
  vendorFeatures?: InputMaybe<VendorFeaturesInput>;
};

export type GetDashboardEmbedUrlInput = {
  dashboardId: Scalars['String']['input'];
};

export type GetDashboardInput = {
  dashboardId?: InputMaybe<Scalars['String']['input']>;
};

export type GetDashboardsByCriteriaInput = {
  filter?: InputMaybe<DashboardFilter>;
  sort?: InputMaybe<Array<DashboardSort>>;
};

export type GetInitiativesByCriteriaInput = {
  filter?: InputMaybe<InitiativeFilter>;
  sort?: InputMaybe<Array<InitiativeSort>>;
};

export type GetOrganizationTypeByCriteriaInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GetOrganizationsByCriteriaInput = {
  /** Bulk search Organization by name */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type GetPartAclByCriteriaInput = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  level?: InputMaybe<AcLevelEnum>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  partTsId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<AcTypeEnum>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetPartsTiersByCriteriaInput = {
  partsTsId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  tiersId?: InputMaybe<Scalars['String']['input']>;
  uom?: InputMaybe<Scalars['String']['input']>;
  uomConversion?: InputMaybe<Scalars['Int']['input']>;
};

export type GetProjectsByCriteriaInput = {
  creatorId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type GetRelatedSearchInput = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  tsId: Scalars['String']['input'];
};

export type GetSavedMappingInput = {
  type: MappingTypesEnum;
};

export type GetSourcingPlansByCriteriaInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  owner?: InputMaybe<SourcingPlanOwnerEnum>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type GetSuggestedVendorsInput = {
  normalizedVendors?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetSupplierDashboardsInput = {
  supplierSection?: InputMaybe<SupplierSectionEnum>;
};

export type GetSystemByCriteriaInput = {
  creatorId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type GetTiersByCriteriaInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  showClonedTiers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetUserActivitiesByCriteriaInput = {
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GetUsersByCriteriaInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facilityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isCurvoAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  loggedInUsername?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
};

export type GetVendorsByCriteriaInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  vendorFeatures?: InputMaybe<VendorFeaturesInput>;
};

export type Gic = {
  __typename?: 'Gic';
  allowedTypeOnes: Array<GicTypeOne>;
  createdAt: Scalars['Date']['output'];
  excludes?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  includes?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sizeOneDescription?: Maybe<Scalars['String']['output']>;
  sizeThreeDescription?: Maybe<Scalars['String']['output']>;
  sizeTwoDescription?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GicTypeOne = {
  __typename?: 'GicTypeOne';
  createdAt: Scalars['Date']['output'];
  excludes?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  includes?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type GicTypeTwo = {
  __typename?: 'GicTypeTwo';
  createdAt: Scalars['Date']['output'];
  excludes?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  includes?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export enum GroupField {
  EsProductNormalized = 'esProductNormalized',
  EsSupplierNormalized = 'esSupplierNormalized',
  EsVendorItemNormalized = 'esVendorItemNormalized',
  EsVendorNormalized = 'esVendorNormalized',
  FacilityName = 'facilityName',
  Manufacturer = 'manufacturer',
  ManufacturerItem = 'manufacturerItem',
  Vendor = 'vendor',
  VendorItem = 'vendorItem'
}

export type HasSimilarProductsInput = {
  /** Extended use graph clustered 510k data to cross with not only direct 510k items but all 510k items connected in the cluster */
  extended?: InputMaybe<Scalars['Boolean']['input']>;
  gudids: Array<Scalars['String']['input']>;
};

export type HideSourcingPlanInput = {
  basketId: Scalars['String']['input'];
  hide: Scalars['Boolean']['input'];
  sourcingPlanId?: InputMaybe<Scalars['String']['input']>;
};

export type HomepageFeaturedContent = {
  __typename?: 'HomepageFeaturedContent';
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type HomepageFeaturedContentQueryInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
};

export type HomepageFeaturedContentUpdate = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** ID to update the existing feature, null ID will create new feature */
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type IdArgs = {
  id: Scalars['String']['input'];
};

export type IOfferScenarioPriority = {
  isDisabled: Scalars['Boolean']['output'];
  isStrict: Scalars['Boolean']['output'];
  type: OfferScenarioPriorityType;
};

export enum IncludeAggsEnum {
  All = 'all',
  None = 'none',
  TotalSpend = 'totalSpend'
}

export type Initiative = {
  __typename?: 'Initiative';
  attachments?: Maybe<Array<InitiativeAttachment>>;
  comment?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['Date']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactFirstName?: Maybe<Scalars['String']['output']>;
  contactLastName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  dealRequestCount: Scalars['Int']['output'];
  embeddedIssueUrl?: Maybe<Scalars['String']['output']>;
  hospitalArea: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interested: Scalars['Boolean']['output'];
  interestedCount: Scalars['Int']['output'];
  isPublic: Scalars['Boolean']['output'];
  medtech?: Maybe<Scalars['String']['output']>;
  network?: Maybe<Network>;
  startedAt?: Maybe<Scalars['Date']['output']>;
  status: InitiativeStatusEnum;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  user: User;
  valueElement?: Maybe<Array<Scalars['String']['output']>>;
};

export type InitiativeActivity = {
  __typename?: 'InitiativeActivity';
  action: InitiativeActivityActionEnum;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  initiative: Initiative;
  updatedAt: Scalars['Date']['output'];
  user: User;
};

export enum InitiativeActivityActionEnum {
  Interested = 'interested',
  RequestedDeal = 'requestedDeal'
}

export type InitiativeActivityInput = {
  action: InitiativeActivityActionEnum;
  initiativeId: Scalars['String']['input'];
};

export type InitiativeAttachment = {
  __typename?: 'InitiativeAttachment';
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  initiativeId: Scalars['String']['output'];
  isPrivate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  s3Key: Scalars['String']['output'];
};

export type InitiativeAttachmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  /** For updating existing Attachment. Omit this will create new Attachment Entity */
  id?: InputMaybe<Scalars['String']['input']>;
  isPrivate: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
};

export type InitiativeFilter = {
  myNetworksOnly?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<InitiativeType>;
};

export type InitiativeSelectorsList = {
  __typename?: 'InitiativeSelectorsList';
  hospitalAreas: Array<Scalars['String']['output']>;
  valueElements: Array<Scalars['String']['output']>;
};

export type InitiativeSort = {
  dir: Scalars['String']['input'];
  field: InitiativeSortEnum;
};

export enum InitiativeSortEnum {
  Collaboratior = 'collaboratior',
  CreatedAt = 'createdAt',
  Interested = 'interested'
}

export enum InitiativeStatusEnum {
  Completed = 'completed',
  InProgress = 'inProgress'
}

export enum InitiativeType {
  Completed = 'completed',
  Favorites = 'favorites',
  InProgress = 'inProgress'
}

export type InitiativesResult = {
  __typename?: 'InitiativesResult';
  hits: Array<Initiative>;
  totalHits: Scalars['Int']['output'];
};

export type InvitationMeta = {
  __typename?: 'InvitationMeta';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export enum InvitationStatusEnum {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type InviteMoreVendorsInput = {
  id: Scalars['String']['input'];
  vendors: Array<OpportunityInviteBaseInput>;
};

export type InviteUserInput = {
  customerId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  externalAuthConnectionId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isTopPowerUser?: InputMaybe<Scalars['Boolean']['input']>;
  isTopUser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organizations: Array<OrganizationPermission>;
  permissions?: InputMaybe<Array<UserPermissionFeature>>;
  qsGroups?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InvitedUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  /** Network role */
  role?: InputMaybe<UserNetworkRoleEnum>;
};

export type ItemGudid = {
  __typename?: 'ItemGudid';
  brandName?: Maybe<Scalars['String']['output']>;
  catalogNumber?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gmdnName?: Maybe<Array<Scalars['String']['output']>>;
  gmdnTerms?: Maybe<Array<GmdnTerm>>;
  gudid: Scalars['String']['output'];
  manufacturer: Scalars['String']['output'];
  productCodes?: Maybe<Array<ProductCode>>;
  submissionNumbers?: Maybe<Array<Scalars['String']['output']>>;
  versionModelNumber?: Maybe<Scalars['String']['output']>;
};

export type ItemRulePartDetail = {
  __typename?: 'ItemRulePartDetail';
  description?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerItem?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  vendorItem?: Maybe<Scalars['String']['output']>;
};

export type ItemSpend = {
  __typename?: 'ItemSpend';
  actualImpact?: Maybe<Scalars['Float']['output']>;
  benchmarks?: Maybe<Benchmark>;
  commodity?: Maybe<AggregatedItemCommodityType>;
  contract?: Maybe<Scalars['String']['output']>;
  currentPrice?: Maybe<Scalars['Float']['output']>;
  curvo25?: Maybe<Scalars['Float']['output']>;
  curvo50?: Maybe<Scalars['Float']['output']>;
  curvo75?: Maybe<Scalars['Float']['output']>;
  curvo90?: Maybe<Scalars['Float']['output']>;
  datepurchase?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  facilityId: Scalars['String']['output'];
  facilityItem?: Maybe<Scalars['String']['output']>;
  facilityName?: Maybe<Scalars['String']['output']>;
  gicId?: Maybe<Scalars['Int']['output']>;
  gicName?: Maybe<Scalars['String']['output']>;
  gmdn?: Maybe<Array<Scalars['String']['output']>>;
  gudid?: Maybe<ItemGudid>;
  id: Scalars['String']['output'];
  impact3m?: Maybe<Scalars['Float']['output']>;
  impact12m?: Maybe<Scalars['Float']['output']>;
  itemName?: Maybe<Scalars['String']['output']>;
  l12mBenchmarks?: Maybe<Benchmark>;
  last12MonthsQuantity?: Maybe<Scalars['Float']['output']>;
  last12MonthsSpend?: Maybe<Scalars['Float']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerItem?: Maybe<Scalars['String']['output']>;
  matchId?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  organizationItem?: Maybe<Scalars['String']['output']>;
  poNum?: Maybe<Scalars['String']['output']>;
  pricingTrends?: Maybe<Trend>;
  qty?: Maybe<Scalars['Float']['output']>;
  stan?: Maybe<AggregatedItemStanType>;
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  totalSpend?: Maybe<Scalars['Float']['output']>;
  transactions?: Maybe<Array<Scalars['Int']['output']>>;
  tsId?: Maybe<Scalars['String']['output']>;
  typeOne?: Maybe<Scalars['String']['output']>;
  typeTwo?: Maybe<Scalars['String']['output']>;
  uom?: Maybe<Scalars['String']['output']>;
  uomConversion?: Maybe<Scalars['Float']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  vendorItem?: Maybe<Scalars['String']['output']>;
};

export type ItemTransaction = {
  __typename?: 'ItemTransaction';
  datePurchase?: Maybe<Scalars['Date']['output']>;
  facDesc?: Maybe<Scalars['String']['output']>;
  facName?: Maybe<Scalars['String']['output']>;
  facilityItem?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  mfgItem?: Maybe<Scalars['String']['output']>;
  poNum?: Maybe<Scalars['String']['output']>;
  qtyPurchase?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  uom?: Maybe<Scalars['String']['output']>;
  uomConversion?: Maybe<Scalars['Float']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  vendorItem?: Maybe<Scalars['String']['output']>;
};

export type ItemTransactionResult = {
  __typename?: 'ItemTransactionResult';
  hits: Array<ItemTransaction>;
  totalHit: Scalars['Int']['output'];
};

export type JobProgressEvent = {
  __typename?: 'JobProgressEvent';
  current: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type LastPurchaseDateByFacility = {
  __typename?: 'LastPurchaseDateByFacility';
  facilityId: Scalars['Float']['output'];
  facilityName: Scalars['String']['output'];
  lastPurchaseDate?: Maybe<Scalars['Date']['output']>;
};

export type LastPurchaseDateResult = {
  __typename?: 'LastPurchaseDateResult';
  data: Array<LastPurchaseDateByFacility>;
};

export type LinkageSearchInput = {
  searchText: Scalars['String']['input'];
};

export type ListDashboardsForUserInput = {
  userId: Scalars['String']['input'];
};

export type Manufacturer = {
  __typename?: 'Manufacturer';
  city: Scalars['String']['output'];
  comments?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  producesReprocessedItems?: Maybe<Scalars['Boolean']['output']>;
  retry?: Maybe<Array<ManufacturerRetry>>;
  segmentationId?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  ticker?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ManufacturerType>;
  updatedAt: Scalars['Date']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type ManufacturerRetry = {
  __typename?: 'ManufacturerRetry';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Type of Manufacturers */
export enum ManufacturerType {
  B = 'B',
  I = 'I',
  L = 'L',
  O = 'O',
  Other = 'Other',
  P = 'P',
  Unknown = 'Unknown'
}

export type MapField = {
  __typename?: 'MapField';
  columnName: Scalars['String']['output'];
  previewData?: Maybe<Scalars['String']['output']>;
  sheetIndex: Scalars['Int']['output'];
  sheetName: Scalars['String']['output'];
};

export type MappedFieldsType = {
  description?: InputMaybe<Scalars['String']['input']>;
  facilityId?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  manufacturerItem?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['String']['input']>;
  unitPrice?: InputMaybe<Scalars['String']['input']>;
  uom?: InputMaybe<Scalars['String']['input']>;
  uomConversion?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
  vendorItem?: InputMaybe<Scalars['String']['input']>;
};

export enum MappingTypeEnum {
  Field = 'field',
  Literal = 'literal'
}

export enum MappingTypesEnum {
  Basket = 'Basket',
  Contract = 'Contract',
  CrossReference = 'CrossReference',
  Search = 'Search'
}

export type MarketTrend = {
  __typename?: 'MarketTrend';
  lrR2: Scalars['Float']['output'];
  lrSlope: Scalars['Float']['output'];
  numDataPoint: Scalars['Int']['output'];
  priceDelta: Scalars['Float']['output'];
  priceDeltaPercentage: Scalars['Float']['output'];
};

export type MasterCatalog = {
  __typename?: 'MasterCatalog';
  commodityId?: Maybe<Scalars['String']['output']>;
  normalizedDescription?: Maybe<Scalars['String']['output']>;
  normalizedManufacturer?: Maybe<Scalars['String']['output']>;
  normalizedManufacturerItem?: Maybe<Scalars['String']['output']>;
  normalizedVendor?: Maybe<Scalars['String']['output']>;
  normalizedVendorItem?: Maybe<Scalars['String']['output']>;
  stanId?: Maybe<Scalars['String']['output']>;
  tsId?: Maybe<Scalars['String']['output']>;
  udiId?: Maybe<Scalars['String']['output']>;
};

export type MasterCatalogData = {
  __typename?: 'MasterCatalogData';
  description?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerItem?: Maybe<Scalars['String']['output']>;
  med?: Maybe<MasterDataSourceMedType>;
  stn?: Maybe<MasterDataSourceStanType>;
  udi?: Maybe<MasterDataSourceUdiType>;
  vendor?: Maybe<Scalars['String']['output']>;
  vendorItem?: Maybe<Scalars['String']['output']>;
};

export type MasterCatalogResult = {
  __typename?: 'MasterCatalogResult';
  hits: Array<MasterCatalog>;
  totalHits: Scalars['Int']['output'];
};

export type MasterDataSourceMedType = {
  __typename?: 'MasterDataSourceMedType';
  description?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  materialCode?: Maybe<Scalars['String']['output']>;
  normalizedCurvo?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  strippedMaterialCode?: Maybe<Scalars['String']['output']>;
  strippedSku?: Maybe<Scalars['String']['output']>;
};

export type MasterDataSourceStanType = {
  __typename?: 'MasterDataSourceStanType';
  brand?: Maybe<StanBaseData>;
  gic?: Maybe<StanBaseData>;
  id?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<StanBaseData>;
  material?: Maybe<StanBaseData>;
  part?: Maybe<StanPart>;
  productLine?: Maybe<StanBaseData>;
  segment?: Maybe<StanBaseData>;
  subSegment?: Maybe<StanBaseData>;
  suggestions?: Maybe<Array<Scalars['String']['output']>>;
  typeOne?: Maybe<StanBaseData>;
  typeTwo?: Maybe<StanBaseData>;
};

export type MasterDataSourceUdiType = {
  __typename?: 'MasterDataSourceUdiType';
  catalogNumber?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gmdnName?: Maybe<Array<Scalars['String']['output']>>;
  normalizedCurvo?: Maybe<Scalars['String']['output']>;
  primaryIdentifier?: Maybe<Scalars['String']['output']>;
  productCodes?: Maybe<Array<Scalars['String']['output']>>;
  strippedCatalogNumber?: Maybe<Scalars['String']['output']>;
  strippedVersionModelNumber?: Maybe<Scalars['String']['output']>;
  versionModelNumber?: Maybe<Scalars['String']['output']>;
};

export type MemberAnalysisInput = {
  organizationIds: Array<Scalars['String']['input']>;
};

export type MemberAnalysisOutput = {
  __typename?: 'MemberAnalysisOutput';
  contract_description?: Maybe<Scalars['String']['output']>;
  contract_end?: Maybe<Scalars['Date']['output']>;
  contract_mfg?: Maybe<Scalars['String']['output']>;
  contract_mfgitem?: Maybe<Scalars['String']['output']>;
  contract_name?: Maybe<Scalars['String']['output']>;
  contract_price_diff?: Maybe<Scalars['Float']['output']>;
  contract_start?: Maybe<Scalars['Date']['output']>;
  contract_supplier?: Maybe<Scalars['String']['output']>;
  curvo25?: Maybe<Scalars['Float']['output']>;
  curvo50?: Maybe<Scalars['Float']['output']>;
  curvo75?: Maybe<Scalars['Float']['output']>;
  curvo90?: Maybe<Scalars['Float']['output']>;
  curvo_category?: Maybe<Scalars['String']['output']>;
  e_description?: Maybe<Scalars['String']['output']>;
  e_manufacturer?: Maybe<Scalars['String']['output']>;
  e_mfgitem?: Maybe<Scalars['String']['output']>;
  e_uom?: Maybe<Scalars['String']['output']>;
  e_uomconversion?: Maybe<Scalars['Float']['output']>;
  e_vendor?: Maybe<Scalars['String']['output']>;
  e_venitem?: Maybe<Scalars['String']['output']>;
  extprice?: Maybe<Scalars['Float']['output']>;
  facility_name?: Maybe<Scalars['String']['output']>;
  gic?: Maybe<Scalars['String']['output']>;
  last_date_purchase?: Maybe<Scalars['Date']['output']>;
  last_price?: Maybe<Scalars['Float']['output']>;
  material?: Maybe<Scalars['String']['output']>;
  perc_contract_price_diff?: Maybe<Scalars['Float']['output']>;
  perc_price_diff_after_rebates?: Maybe<Scalars['Float']['output']>;
  price_diff_after_rebates?: Maybe<Scalars['Float']['output']>;
  product_line?: Maybe<Scalars['String']['output']>;
  projected_spend?: Maybe<Scalars['Float']['output']>;
  qtypurchase?: Maybe<Scalars['Float']['output']>;
  s_description?: Maybe<Scalars['String']['output']>;
  s_manufacturer?: Maybe<Scalars['String']['output']>;
  s_mfgitem?: Maybe<Scalars['String']['output']>;
  s_uom?: Maybe<Scalars['String']['output']>;
  s_uomconversion?: Maybe<Scalars['Float']['output']>;
  s_vendor?: Maybe<Scalars['String']['output']>;
  s_venitem?: Maybe<Scalars['String']['output']>;
  sourcing_officer?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['String']['output']>;
  tier_price?: Maybe<Scalars['Float']['output']>;
  tier_rebate?: Maybe<Scalars['Float']['output']>;
  tier_uom?: Maybe<Scalars['String']['output']>;
  tier_uom_conversion?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  type_one?: Maybe<Scalars['String']['output']>;
  type_two?: Maybe<Scalars['String']['output']>;
};

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  id: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type MessageTemplateList = {
  __typename?: 'MessageTemplateList';
  data: Array<MessageTemplate>;
};

export type MetricsType = {
  __typename?: 'MetricsType';
  benchmarks: Scalars['Boolean']['output'];
  coverage: Scalars['Boolean']['output'];
  pricingTarget: Scalars['Boolean']['output'];
  quantity: Scalars['Boolean']['output'];
  rank: Scalars['Boolean']['output'];
};

export type MetricsVendorType = {
  benchmarks: Scalars['Boolean']['input'];
  coverage: Scalars['Boolean']['input'];
  pricingTarget: Scalars['Boolean']['input'];
  quantity: Scalars['Boolean']['input'];
  rank: Scalars['Boolean']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptOpportunity?: Maybe<UploadJob>;
  activateCustomer: Customer;
  /** Activate sourcing plan with at least one basket and `draft` status. */
  activateSourcingPlan: SourcingPlan;
  activateVendor: Customer;
  addContractAttachment: ContractAttachment;
  addContractTier: Scalars['Boolean']['output'];
  addInitiativeActivity: InitiativeActivity;
  addNetworkMembers: Array<UserNetwork>;
  addOfferFavorite: OfferFavorite;
  addOrganizationToCustomer: Organization;
  /** Add basket to sourcing plan. */
  addSourcingPlanBasket: SourcingPlanBasket;
  addSourcingPlanBasketStakeholder: SourcingPlanBasketsStakeholders;
  /** Add sourcing plan stakeholder. */
  addSourcingPlanStakeholder: SourcingPlanStakeHolder;
  addUserToOrganization: UserOrganization;
  adminFillCustomBasketProductsInfo: Scalars['Boolean']['output'];
  adminGenerateOfferItemsCrossSuggestion: Scalars['Boolean']['output'];
  adminPopulateOfferItemCatalogPartsBenchmarks: Scalars['Boolean']['output'];
  adminRedeemOpportunity: Offer;
  adminResetPassword: Scalars['Boolean']['output'];
  adminUpdateClustersAndLeaders: Scalars['Boolean']['output'];
  applyAllOfferItemsSuggestion: Scalars['Boolean']['output'];
  applySuggestionsToAllUnlinkedCatalogParts: Scalars['Boolean']['output'];
  archiveBasket: Basket;
  archiveNetwork: Network;
  archiveOpportunity: Offer;
  archiveSupplierOpportunity: SupplierOpportunity;
  archiveSupplierOpportunityOffer: SupplierOpportunity;
  awardScenario: OfferScenario;
  bulkApproveStudyLinks: Array<QueuedLinkProcess>;
  bulkOrganizationUserRoleUpdate: Array<UserOrganization>;
  bulkUpdateBasketSmartRules: Array<SmartRule>;
  bulkUpdateHomepageFeaturedContents: Array<HomepageFeaturedContent>;
  bulkUpdateOfferCrossItems: Array<OfferItem>;
  bulkUpdateOfferItemsLinkage: Array<OfferItem>;
  /** Bulk update offer items' tier prices. If a negative price is passed, this mutation will delete that record, otherwise create or update offer items' tiers' price */
  bulkUpdateOfferItemsTiersPrice: Array<OfferItem>;
  /** Bulk update offer items and its related (affected by same tiers) catalog parts' tier prices. This mutation updates whether OfferItem or CatalogPart based on the prefix of input id, `offerItem#` for updating `OfferItem` and `catalogPart#` for `CatalogPart` . If a negative price is passed, this mutation will delete that record, otherwise create or update offer items' tiers' price */
  bulkUpdateOfferPricingItemsTiersPrice: Array<OfferPricingItem>;
  bulkUpdateOpportunityItemsPricingTarget: Array<OfferItem>;
  bulkUpdateScenarioItems: Scalars['Boolean']['output'];
  bulkUpdateStudyLinks: Array<QueuedLinkProcess>;
  bulkUpsertCatalogPartTiers: Array<CatalogPartTier>;
  bulkUserPermissionsUpdate: Array<UserOrganization>;
  calculateAllBasketsMetrics: Array<BasketMetricsUpdateResult>;
  /** Cancel the `active` `CategoryConsultRequest`, notify Curvo Customer Success team via email and slack */
  cancelCategoryConsultRequest: CategoryConsultRequest;
  /** Check if this opportunity needs to be generated items */
  checkAndGenerateOfferItems: Offer;
  checkExistVendor: Scalars['Boolean']['output'];
  cloakingPermissionsOfUser: Scalars['Boolean']['output'];
  /** Create a copy of a Curvo Basket, newly created Basket will be a normal Custom basket */
  copyCurvoBasket: CopyCurvoBasketResponse;
  createBasketDashboardRelation: Scalars['Boolean']['output'];
  createCatalogParts: Array<CatalogPart>;
  createContact: Contact;
  createContractDashboardRelation: Scalars['Boolean']['output'];
  createCrossReferenceList: UploadJob;
  createCustomer: Customer;
  createDashboard: Dashboard;
  createDashboards: Array<Scalars['String']['output']>;
  createDraftContract: Contract;
  createNetworkWithUsers: Network;
  createNewInitiative: Initiative;
  createOffer: Offer;
  createOfferScenario: OfferScenario;
  createOrganization: Organization;
  createOrganizationType: OrganizationType;
  createSavedMapping: SavedMapping;
  createSupportTicket: Scalars['Boolean']['output'];
  createTiers: Array<Tier>;
  createVendor: Customer;
  createVendorUser: User;
  crossReferenceOfferItems: Offer;
  deactivateCustomer: Customer;
  deactivateVendor: Customer;
  deleteClusterParts: Array<Scalars['String']['output']>;
  deleteCrossReferenceClusters: Array<Scalars['String']['output']>;
  deleteCrossReferenceLists: Array<Scalars['String']['output']>;
  deleteCrossReferenceMaps: Array<CrossReferenceMap>;
  deleteHospitalCrossReferenceLists: Array<Scalars['String']['output']>;
  deleteOrganizationType: Scalars['Boolean']['output'];
  deleteSavedMapping: SavedMapping;
  deleteSavedSearch: SavedSearch;
  deleteScenario: OfferScenario;
  /** Soft delete a Sourcing Plan by id. Only the creator can delete Sourcing Plan */
  deleteSourcingPlan: SourcingPlan;
  deleteSupplierOpportunity: SupplierOpportunity;
  deleteSupplierOpportunityOffer: SupplierOpportunity;
  doAggregatedSearch: AggregatedSearchResult;
  doSavedSearch: SearchResult;
  doUploadAggSearch: UploadJob;
  downloadContractAttachment: Scalars['String']['output'];
  downloadVendorOffer: Scalars['String']['output'];
  duplicateSearch: SavedSearch;
  editSavedMapping: SavedMapping;
  editSavedSearch: SavedSearch;
  editSmartBasket: SmartBasket;
  exportAggregatedSearch: UploadJob;
  exportCatalogParts: ExportCatalogPartsPayload;
  exportConsolidatedReport: Scalars['String']['output'];
  exportItemPoTransactions: UploadJob;
  exportOfferItemsToXlsx: Scalars['String']['output'];
  fetchMappingFields: FetchMappingFieldsResult;
  fillAllPriceScores: Scalars['Boolean']['output'];
  fillPriceScores: Scalars['Boolean']['output'];
  generateBasketSmartRules: Scalars['Boolean']['output'];
  /** Allow user to upload a document file (word, excel, csv or pdf) to a s3 temp folder, the result is a URL which can be only used for uploading and valid for 1 hour */
  generateDocumentUploadUrl: S3UploadGeneratedUrl;
  /** Allow user to upload an image file to a s3 temp folder, the result is a URL which can be only used for uploading and valid for 1 hour */
  generateImageUploadUrl: S3UploadGeneratedUrl;
  /** Hide a Basket/Category */
  hideSourcingPlanBasket: SourcingPlanBasketBase;
  inviteMoreVendors: Array<OfferInvitingVendor>;
  inviteUser: User;
  leaveNetwork: Scalars['Boolean']['output'];
  linkStudy: QueuedStudy;
  migrateVendorUser: Scalars['Boolean']['output'];
  newSmartBasket: SmartBasket;
  onLogin: Scalars['Boolean']['output'];
  opportunityCustomizationSave: Scalars['Boolean']['output'];
  /** Pin a Basket/Category */
  pinSourcingPlanBasket: SourcingPlanBasketBase;
  populateOfferItemCatalogPartsBenchmarks: Scalars['Boolean']['output'];
  processBasketUpload: Scalars['String']['output'];
  processContractUpload: Scalars['String']['output'];
  processContractXrefUpload: Scalars['String']['output'];
  /** Queue a list of ContractMatchingUpdate jobs to be processed by worker. */
  queueAllContractsMatchingUpdateJob: Array<UploadJob>;
  /** Queue a CalculateBasketMetricsJob to be processed by worker. */
  queueCalculateBasketMetricsJob: Array<UploadJob>;
  /** Queue a list of PartsLinkageUpdate jobs to be processed by worker. */
  queueUpdatePartsLinkageJob: Array<UploadJob>;
  quickCreateNewOrganizations: Array<Organization>;
  rebuildQuicksightPermissions: Scalars['Boolean']['output'];
  redeemOpportunity: Offer;
  /** Queue a refresh enrichment job for input opportunity to be processed by worker. */
  refreshOpportunityEnrichment: UploadJob;
  rejectOpportunity: Scalars['Boolean']['output'];
  removeBasketProducts: RemoveBasketProductsResponse;
  removeBasketSmartRules: Scalars['Boolean']['output'];
  /** Remove Cloaked status for current Admin User */
  removeCloak: Scalars['Boolean']['output'];
  removeContractAttachment: Scalars['Boolean']['output'];
  removeContractMemberTierHistories: Array<Scalars['String']['output']>;
  removeContractParts: Scalars['Boolean']['output'];
  removeContractTier: Scalars['Boolean']['output'];
  removeDashboards: Scalars['Boolean']['output'];
  removeInitiativeActivity: Scalars['Boolean']['output'];
  removeMessageTemplate: Scalars['Boolean']['output'];
  removeNetworkMember: UserNetwork;
  removeOfferFavorite: Scalars['Boolean']['output'];
  removeOpportunityItems: Array<OfferItem>;
  /** Remove basket from sourcing plan. */
  removeSourcingPlanBasket: SourcingPlanBasket;
  removeSourcingPlanBasketStakeholder: SourcingPlanBasketsStakeholders;
  /** Remove a sourcing plan stakeholder by ID. But its `Contact` should be persisted */
  removeSourcingPlanStakeholder: SourcingPlanStakeHolder;
  removeUser: User;
  removeUserFromOrganization: UserOrganization;
  reopenSubmittedOffer: Scalars['Boolean']['output'];
  repopulateCatalogPartsIsImplantable: Scalars['Boolean']['output'];
  repopulateStanDataForCatalogParts: Scalars['Boolean']['output'];
  /** Fire new `Category` (`Basket`) consult request, notify Curvo Customer Success team via email and slack */
  requestCategoryConsult: CategoryConsultRequest;
  resendInvite: Scalars['Boolean']['output'];
  resendNetworkInvitation: Scalars['Boolean']['output'];
  resendUserInvitation: Scalars['Boolean']['output'];
  /** Allow a cloaked CS user to wipe out whole catalog of a Supplier */
  resetCatalog: Scalars['Boolean']['output'];
  resetOfferScenario: OfferScenario;
  resetPassword: Scalars['Boolean']['output'];
  /** Retry the Opportunity, create new Offer and start over; Return UploadJob record with new offer id in field resultStats */
  retryOpportunity?: Maybe<UploadJob>;
  saveCrossReferenceMappings: Array<CrossReferenceMap>;
  saveDraftOpportunity: Offer;
  saveMessageTemplate: MessageTemplate;
  saveSearch: SavedSearch;
  searchByUploadedSearchId: SearchByUploadedSearchIdResult;
  sendBasketStakeholdersSurvey: Array<SourcingPlanBasketsStakeholders>;
  sendProjectApertureInvitation: Scalars['Boolean']['output'];
  sendSourcingPlanStakeholdersSurvey: Array<SourcingPlanStakeHolder>;
  setCustomerMainContact: Customer;
  setNormalizedManufacturer: Customer;
  signUp: User;
  /** Snooze a Basket/Category */
  snoozeSourcingPlanBasket: SourcingPlanBasketBase;
  /** Start new draft sourcing plan with draft status. */
  startSourcingPlan: SourcingPlan;
  submitContract: Scalars['Boolean']['output'];
  submitOpportunity: Offer;
  syncCurvoNormalizations: UploadJob;
  syncOpportunitiesWithEventTracker: Scalars['Boolean']['output'];
  syncUserQuickSightGroups: Scalars['Boolean']['output'];
  tierArchivedToggle: Tier;
  toggleUserActivation: User;
  unarchiveBasket: Basket;
  unarchiveOpportunity: Offer;
  updateAutoBaskets: Scalars['Boolean']['output'];
  updateCatalogParts: Array<CatalogPart>;
  updateCatalogPartsWithMasterTsId: Array<CatalogPart>;
  /** Update Cloaked Organization for current Admin User */
  updateCloak: Scalars['Boolean']['output'];
  updateCommitment: OfferScenario;
  updateContractAttachment: ContractAttachment;
  updateContractBaskets: Contract;
  updateContractMemberTierHistories: Array<ContractOrganizationHistory>;
  updateContractMembers: Array<ContractMember>;
  updateContractParts: Array<UpdateContractPartsOutput>;
  updateContractWithCrossReferenceList: Scalars['String']['output'];
  /** Run contract matching for a list of contracts */
  updateContractsMatching: Scalars['Boolean']['output'];
  updateCrossReferenceList: CrossReferenceList;
  updateCustomer: Customer;
  updateDashboard: Scalars['String']['output'];
  updateDraftContract: Contract;
  updateDraftOpportunity: Offer;
  updateLinkedTsIds: Scalars['Boolean']['output'];
  updateMe: User;
  updateMessageTemplate: MessageTemplate;
  updateNetworkMemberRole: UserNetwork;
  updateOffer: UpdateOfferResult;
  updateOfferTier: Offer;
  updateOpportunityAttachment: OpportunityAttachment;
  updateOpportunityItem: OfferItem;
  updateOrganization: Organization;
  updateScenarioItemByOffer: Scalars['Boolean']['output'];
  updateSmartBasketConfig: Basket;
  /** Update sourcing plan. */
  updateSourcingPlan: SourcingPlan;
  /** Update SourcingPlanBasket */
  updateSourcingPlanBasket: SourcingPlanBasketBase;
  /** Update sourcing plan basket stakeholder. */
  updateSourcingPlanBasketStakeholder: SourcingPlanBasketsStakeholders;
  /** Update sourcing plan stakeholder. */
  updateSourcingPlanStakeholder: SourcingPlanStakeHolder;
  updateSupplierOpportunity: SupplierOpportunity;
  updateSupplierOpportunityACL: Array<Acl>;
  updateSupplierOpportunityAttachments: Array<OpportunityAttachment>;
  updateTierRules: Tier;
  updateTiers: Array<Tier>;
  updateUploadBasketBenchmarks: Scalars['Boolean']['output'];
  updateUser: User;
  updateVendor: Customer;
  updateVendorUser: User;
  uploadBasketFile: UploadBasketResult;
  uploadBulkUpdateOfferItems: UploadJob;
  uploadCatalog: Scalars['String']['output'];
  uploadContractFile: UploadContractResult;
  uploadContractXrefFile: UploadContractResult;
  uploadCrossReference: UploadJob;
  uploadOpportunity: UploadJob;
  uploadSearchFile: UploadSearchResult;
  verifyAccount: User;
  viewOffer: Scalars['Boolean']['output'];
};


export type MutationAcceptOpportunityArgs = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type MutationActivateCustomerArgs = {
  input: CustomerActivationInput;
};


export type MutationActivateSourcingPlanArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  sourcingPlanId: Scalars['String']['input'];
};


export type MutationActivateVendorArgs = {
  input: IdArgs;
};


export type MutationAddContractAttachmentArgs = {
  input: AddContractAttachmentInput;
};


export type MutationAddContractTierArgs = {
  input: CreateContractTierInput;
};


export type MutationAddInitiativeActivityArgs = {
  input: InitiativeActivityInput;
};


export type MutationAddNetworkMembersArgs = {
  input: AddNetworkMemberInput;
};


export type MutationAddOfferFavoriteArgs = {
  input: OfferFavoriteInput;
};


export type MutationAddOrganizationToCustomerArgs = {
  input: AddOrganizationToCustomerInput;
};


export type MutationAddSourcingPlanBasketArgs = {
  input: AddSourcingPlanBasketInput;
};


export type MutationAddSourcingPlanBasketStakeholderArgs = {
  input: AddSourcingPlanBasketsStakeholdersInput;
};


export type MutationAddSourcingPlanStakeholderArgs = {
  input: AddSourcingPlanStakeholderInput;
};


export type MutationAddUserToOrganizationArgs = {
  input: UserOrganizationInput;
};


export type MutationAdminFillCustomBasketProductsInfoArgs = {
  basketIds: Array<Scalars['String']['input']>;
};


export type MutationAdminGenerateOfferItemsCrossSuggestionArgs = {
  id: Scalars['String']['input'];
};


export type MutationAdminRedeemOpportunityArgs = {
  input: AdminRedeemOpportunityInput;
};


export type MutationAdminResetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationAdminUpdateClustersAndLeadersArgs = {
  customerId: Scalars['String']['input'];
};


export type MutationApplyAllOfferItemsSuggestionArgs = {
  input: IdArgs;
};


export type MutationArchiveBasketArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveNetworkArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveOpportunityArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveSupplierOpportunityArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveSupplierOpportunityOfferArgs = {
  id: Scalars['String']['input'];
};


export type MutationAwardScenarioArgs = {
  input: AwardScenarioInput;
};


export type MutationBulkApproveStudyLinksArgs = {
  input: BulkApproveStudyLinkInput;
};


export type MutationBulkOrganizationUserRoleUpdateArgs = {
  input: BulkUserOrganizationRoleUpdateInput;
};


export type MutationBulkUpdateBasketSmartRulesArgs = {
  input: BulkUpdateBasketSmartRulesInput;
};


export type MutationBulkUpdateHomepageFeaturedContentsArgs = {
  input: BulkUpdateHomepageFeaturedContentInput;
};


export type MutationBulkUpdateOfferCrossItemsArgs = {
  input: BulkUpdateOfferItemCrossInput;
};


export type MutationBulkUpdateOfferItemsLinkageArgs = {
  input: BulkUpdateOfferItemLinkageInput;
};


export type MutationBulkUpdateOfferItemsTiersPriceArgs = {
  input: BulkUpdateOfferItemTiersPriceInput;
};


export type MutationBulkUpdateOfferPricingItemsTiersPriceArgs = {
  input: BulkUpdateOfferPricingItemTiersPriceInput;
};


export type MutationBulkUpdateOpportunityItemsPricingTargetArgs = {
  input: BulkUpdateOpportunityItemsPricingTargetInput;
};


export type MutationBulkUpdateScenarioItemsArgs = {
  input: BulkUpdateOfferScenarioItemsInput;
};


export type MutationBulkUpdateStudyLinksArgs = {
  input: BulkUpdateStudyLinkInput;
};


export type MutationBulkUpsertCatalogPartTiersArgs = {
  input: BulkUpdateCatalogPartTierInput;
};


export type MutationBulkUserPermissionsUpdateArgs = {
  input: BulkUserPermissionUpdateInput;
};


export type MutationCalculateAllBasketsMetricsArgs = {
  basketId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  onlyNew?: InputMaybe<Scalars['Boolean']['input']>;
  skipNotification?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBefore?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationCancelCategoryConsultRequestArgs = {
  id: Scalars['String']['input'];
};


export type MutationCheckAndGenerateOfferItemsArgs = {
  id: Scalars['String']['input'];
  skipZeroQuantityItems: Scalars['Boolean']['input'];
};


export type MutationCheckExistVendorArgs = {
  input: CheckExistVendorInput;
};


export type MutationCloakingPermissionsOfUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationCopyCurvoBasketArgs = {
  input: CopyCurvoBasketInput;
};


export type MutationCreateBasketDashboardRelationArgs = {
  input: CreateBasketDashboardInput;
};


export type MutationCreateCatalogPartsArgs = {
  input: Array<CreateCatalogPartInput>;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateContractDashboardRelationArgs = {
  input: CreateContractDashboardInput;
};


export type MutationCreateCrossReferenceListArgs = {
  input: CreateCrossReferenceListInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateDashboardArgs = {
  input: CreateDashboardInput;
};


export type MutationCreateDraftContractArgs = {
  input: CreateContractInput;
};


export type MutationCreateNetworkWithUsersArgs = {
  input: CreateNetworkWithUsersInput;
};


export type MutationCreateNewInitiativeArgs = {
  input: CreateInitiativeInput;
};


export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};


export type MutationCreateOfferScenarioArgs = {
  input: CreateOfferScenarioInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateOrganizationTypeArgs = {
  input: CreateOrganizationTypeInput;
};


export type MutationCreateSavedMappingArgs = {
  input: CreateSavedMappingInput;
};


export type MutationCreateSupportTicketArgs = {
  input: SupportTicketInput;
};


export type MutationCreateTiersArgs = {
  input: Array<CreateTierInput>;
};


export type MutationCreateVendorArgs = {
  input: CreateVendorInput;
};


export type MutationCreateVendorUserArgs = {
  input: CreateVendorUserInput;
};


export type MutationCrossReferenceOfferItemsArgs = {
  input: CrossReferenceOfferItemInput;
};


export type MutationDeactivateCustomerArgs = {
  input: CustomerActivationInput;
};


export type MutationDeactivateVendorArgs = {
  input: IdArgs;
};


export type MutationDeleteClusterPartsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationDeleteCrossReferenceClustersArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationDeleteCrossReferenceListsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationDeleteCrossReferenceMapsArgs = {
  input: DeleteCrossReferenceMapsInput;
};


export type MutationDeleteHospitalCrossReferenceListsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationDeleteOrganizationTypeArgs = {
  input: RemoveOrganizationTypeInput;
};


export type MutationDeleteSavedMappingArgs = {
  input: DeleteSavedMappingInput;
};


export type MutationDeleteSavedSearchArgs = {
  input: IdArgs;
};


export type MutationDeleteScenarioArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSourcingPlanArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSupplierOpportunityArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSupplierOpportunityOfferArgs = {
  id: Scalars['String']['input'];
};


export type MutationDoAggregatedSearchArgs = {
  input: CreateSavedSearchInput;
  pagination?: InputMaybe<Pagination>;
};


export type MutationDoSavedSearchArgs = {
  input: IdArgs;
};


export type MutationDoUploadAggSearchArgs = {
  input: DoUploadSearchInput;
  pagination?: InputMaybe<Pagination>;
};


export type MutationDownloadContractAttachmentArgs = {
  id: Scalars['String']['input'];
};


export type MutationDownloadVendorOfferArgs = {
  input: DownloadVendorOfferInput;
};


export type MutationDuplicateSearchArgs = {
  input: IdArgs;
};


export type MutationEditSavedMappingArgs = {
  input: UpdateSavedMappingInput;
};


export type MutationEditSavedSearchArgs = {
  input: UpdateSavedSearchInput;
};


export type MutationEditSmartBasketArgs = {
  input: UpdateSmartBasketInput;
  pagination?: InputMaybe<Pagination>;
};


export type MutationExportAggregatedSearchArgs = {
  input: ExportAggregatedSearchInput;
};


export type MutationExportCatalogPartsArgs = {
  input: ExportCatalogPartsInput;
};


export type MutationExportConsolidatedReportArgs = {
  id: Scalars['String']['input'];
};


export type MutationExportItemPoTransactionsArgs = {
  input: ExportItemPoTransactionsInput;
};


export type MutationExportOfferItemsToXlsxArgs = {
  id: Scalars['String']['input'];
  selectedFields: Array<OfferItemExportsFieldEnum>;
  selectedTierIds?: InputMaybe<Array<Scalars['String']['input']>>;
  withRemainingTiersCatalogParts?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationFetchMappingFieldsArgs = {
  input: FetchMappingFieldsInput;
};


export type MutationFillPriceScoresArgs = {
  opportunityId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGenerateBasketSmartRulesArgs = {
  input: GenerateBasketSmartRulesInput;
};


export type MutationGenerateDocumentUploadUrlArgs = {
  input: UploadInput;
};


export type MutationGenerateImageUploadUrlArgs = {
  input: UploadInput;
};


export type MutationHideSourcingPlanBasketArgs = {
  input: HideSourcingPlanInput;
};


export type MutationInviteMoreVendorsArgs = {
  input: InviteMoreVendorsInput;
};


export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


export type MutationLeaveNetworkArgs = {
  id: Scalars['String']['input'];
};


export type MutationLinkStudyArgs = {
  id: Scalars['Int']['input'];
};


export type MutationMigrateVendorUserArgs = {
  username: Scalars['String']['input'];
};


export type MutationNewSmartBasketArgs = {
  input: CreateSmartBasketInput;
  pagination?: InputMaybe<Pagination>;
};


export type MutationOpportunityCustomizationSaveArgs = {
  input: OpportunityCustomizationSaveInput;
};


export type MutationPinSourcingPlanBasketArgs = {
  input: PinSourcingPlanInput;
};


export type MutationPopulateOfferItemCatalogPartsBenchmarksArgs = {
  id: Scalars['String']['input'];
};


export type MutationProcessBasketUploadArgs = {
  input: ProcessBasketUploadInput;
};


export type MutationProcessContractUploadArgs = {
  input: ProcessContractUploadInput;
};


export type MutationProcessContractXrefUploadArgs = {
  input: ProcessContractXrefUploadInput;
};


export type MutationQueueAllContractsMatchingUpdateJobArgs = {
  contractIds?: InputMaybe<Array<Scalars['String']['input']>>;
  withLinkage?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationQueueCalculateBasketMetricsJobArgs = {
  onlyNew?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationQueueUpdatePartsLinkageJobArgs = {
  processAll?: InputMaybe<Scalars['Boolean']['input']>;
  processContractMatchingNext: Scalars['Boolean']['input'];
};


export type MutationQuickCreateNewOrganizationsArgs = {
  input: Array<QuickCreateOrganizationInput>;
};


export type MutationRebuildQuicksightPermissionsArgs = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRedeemOpportunityArgs = {
  input: RedeemOpportunityInput;
};


export type MutationRefreshOpportunityEnrichmentArgs = {
  id: Scalars['String']['input'];
};


export type MutationRejectOpportunityArgs = {
  input: IdArgs;
};


export type MutationRemoveBasketProductsArgs = {
  input: RemoveBasketProductsInput;
};


export type MutationRemoveBasketSmartRulesArgs = {
  input: RemoveBasketSmartRulesInput;
};


export type MutationRemoveContractAttachmentArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationRemoveContractMemberTierHistoriesArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationRemoveContractPartsArgs = {
  input: RemoveContractPartsInput;
};


export type MutationRemoveContractTierArgs = {
  input: RemoveContractTierInput;
};


export type MutationRemoveDashboardsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationRemoveInitiativeActivityArgs = {
  input: InitiativeActivityInput;
};


export type MutationRemoveMessageTemplateArgs = {
  input: IdArgs;
};


export type MutationRemoveNetworkMemberArgs = {
  input: RemoveNetworkMemberInput;
};


export type MutationRemoveOfferFavoriteArgs = {
  input: OfferFavoriteInput;
};


export type MutationRemoveOpportunityItemsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationRemoveSourcingPlanBasketArgs = {
  input: RemoveSourcingPlanBasketInput;
};


export type MutationRemoveSourcingPlanBasketStakeholderArgs = {
  input: RemoveSourcingPlanBasketStakeholderInput;
};


export type MutationRemoveSourcingPlanStakeholderArgs = {
  input: RemoveSourcingPlanStakeholderInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationRemoveUserFromOrganizationArgs = {
  input: RemoveOrganizationInput;
};


export type MutationReopenSubmittedOfferArgs = {
  input: ReopenOfferInput;
};


export type MutationRepopulateCatalogPartsIsImplantableArgs = {
  customerId: Scalars['String']['input'];
};


export type MutationRepopulateStanDataForCatalogPartsArgs = {
  customerId: Scalars['String']['input'];
};


export type MutationRequestCategoryConsultArgs = {
  input: RequestCategoryConsultInput;
};


export type MutationResendInviteArgs = {
  input: ResendInviteInput;
};


export type MutationResendNetworkInvitationArgs = {
  userNetworkId: Scalars['String']['input'];
};


export type MutationResendUserInvitationArgs = {
  email: Scalars['String']['input'];
};


export type MutationResetOfferScenarioArgs = {
  id: Scalars['String']['input'];
};


export type MutationRetryOpportunityArgs = {
  input: RetryOpportunityInput;
};


export type MutationSaveCrossReferenceMappingsArgs = {
  input: SaveCrossReferenceMaps;
};


export type MutationSaveDraftOpportunityArgs = {
  input: CreateOpportunityInput;
};


export type MutationSaveMessageTemplateArgs = {
  input: CreateMessageTemplateInput;
};


export type MutationSaveSearchArgs = {
  input: CreateSavedSearchInput;
};


export type MutationSearchByUploadedSearchIdArgs = {
  input: SearchByUploadedSearchIdInput;
  pagination?: InputMaybe<Pagination>;
};


export type MutationSendBasketStakeholdersSurveyArgs = {
  input: SendBasketStakeholdersSurveyInput;
};


export type MutationSendProjectApertureInvitationArgs = {
  input: ProjectApertureInvitationInput;
};


export type MutationSendSourcingPlanStakeholdersSurveyArgs = {
  input: SendSourcingPlanStakeholdersSurveyInput;
};


export type MutationSetCustomerMainContactArgs = {
  input: CustomerMainContactInput;
};


export type MutationSetNormalizedManufacturerArgs = {
  input: SetNormalizedManufacturerInput;
};


export type MutationSignUpArgs = {
  input: CreateUserInput;
};


export type MutationSnoozeSourcingPlanBasketArgs = {
  input: SnoozeSourcingPlanInput;
};


export type MutationStartSourcingPlanArgs = {
  input: StartSourcingPlanInput;
};


export type MutationSubmitContractArgs = {
  input: SubmitContractInput;
};


export type MutationSubmitOpportunityArgs = {
  input: IdArgs;
};


export type MutationSyncCurvoNormalizationsArgs = {
  input: Array<SyncCurvoNormalization>;
};


export type MutationSyncUserQuickSightGroupsArgs = {
  createNonExistent?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['String']['input'];
};


export type MutationTierArchivedToggleArgs = {
  input: IdArgs;
};


export type MutationToggleUserActivationArgs = {
  input: ToggleUserActivationInput;
};


export type MutationUnarchiveBasketArgs = {
  id: Scalars['String']['input'];
};


export type MutationUnarchiveOpportunityArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateAutoBasketsArgs = {
  customerId: Scalars['String']['input'];
};


export type MutationUpdateCatalogPartsArgs = {
  input: Array<UpdateCatalogPartInput>;
};


export type MutationUpdateCatalogPartsWithMasterTsIdArgs = {
  input: Array<UpdateCatalogPartWithMasterTsIdInput>;
};


export type MutationUpdateCloakArgs = {
  input: UpdateCloakInput;
};


export type MutationUpdateCommitmentArgs = {
  input: UpdateScenarioCommitmentInput;
};


export type MutationUpdateContractAttachmentArgs = {
  input: UpdateContractAttachmentInput;
};


export type MutationUpdateContractBasketsArgs = {
  input: UpdateContractBasketsInput;
};


export type MutationUpdateContractMemberTierHistoriesArgs = {
  input: Array<UpdateHistoriesInput>;
};


export type MutationUpdateContractMembersArgs = {
  input: UpdateContractMembersInput;
};


export type MutationUpdateContractPartsArgs = {
  input: UpdateContractPartsInput;
};


export type MutationUpdateContractWithCrossReferenceListArgs = {
  crossReferenceListId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type MutationUpdateContractsMatchingArgs = {
  contractIds: Array<Scalars['String']['input']>;
};


export type MutationUpdateCrossReferenceListArgs = {
  input: UpdateCrossReferenceListInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateDashboardArgs = {
  input: UpdateDashboardInput;
};


export type MutationUpdateDraftContractArgs = {
  input: UpdateContractInput;
};


export type MutationUpdateDraftOpportunityArgs = {
  input: UpdateOpportunityInput;
};


export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
};


export type MutationUpdateMessageTemplateArgs = {
  input: UpdateMessageTemplateInput;
};


export type MutationUpdateNetworkMemberRoleArgs = {
  input: UpdateNetworkMemberRoleInput;
};


export type MutationUpdateOfferArgs = {
  input: UpdateOfferInput;
};


export type MutationUpdateOfferTierArgs = {
  input: UpdateOfferTierInput;
};


export type MutationUpdateOpportunityAttachmentArgs = {
  input: SingleOpportunityAttachmentInput;
};


export type MutationUpdateOpportunityItemArgs = {
  input: UpdateOpportunityItemInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdateScenarioItemByOfferArgs = {
  input: UpdateScenarioByOfferInput;
};


export type MutationUpdateSmartBasketConfigArgs = {
  input: UpdateBasketSmartRulesConfigInput;
};


export type MutationUpdateSourcingPlanArgs = {
  input: UpdateSourcingPlanInput;
};


export type MutationUpdateSourcingPlanBasketArgs = {
  input: UpdateSourcingPlanBasketInput;
};


export type MutationUpdateSourcingPlanBasketStakeholderArgs = {
  input: UpdateSourcingPlanBasketStakeholderInput;
};


export type MutationUpdateSourcingPlanStakeholderArgs = {
  input: UpdateSourcingPlanStakeholderInput;
};


export type MutationUpdateSupplierOpportunityArgs = {
  input: UpdateSupplierOpportunityInput;
};


export type MutationUpdateSupplierOpportunityAclArgs = {
  input: UpdateSupplierOpportunityAclInput;
};


export type MutationUpdateSupplierOpportunityAttachmentsArgs = {
  input: UpdateSupplierOpportunityAttachmentsInput;
};


export type MutationUpdateTierRulesArgs = {
  input: UpdateTierRuleInput;
};


export type MutationUpdateTiersArgs = {
  input: Array<UpdateTierInput>;
};


export type MutationUpdateUploadBasketBenchmarksArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateVendorArgs = {
  input: UpdateVendorInput;
};


export type MutationUpdateVendorUserArgs = {
  input: UpdateVendorUserInput;
};


export type MutationUploadBasketFileArgs = {
  input: UploadBasketInput;
};


export type MutationUploadBulkUpdateOfferItemsArgs = {
  input: BulkUpdateOfferItemsUploadInput;
};


export type MutationUploadCatalogArgs = {
  input: UploadCatalogInput;
};


export type MutationUploadContractFileArgs = {
  input: UploadContractInput;
};


export type MutationUploadContractXrefFileArgs = {
  input: UploadContractInput;
};


export type MutationUploadCrossReferenceArgs = {
  input: UploadCrossRefInput;
};


export type MutationUploadOpportunityArgs = {
  input: OfferUploadInput;
};


export type MutationUploadSearchFileArgs = {
  input: UploadSearchFileInput;
};


export type MutationVerifyAccountArgs = {
  input: VerifyAccountInput;
};


export type MutationViewOfferArgs = {
  id: Scalars['String']['input'];
};

export type MyInitiativeNetworkExtra = {
  __typename?: 'MyInitiativeNetworkExtra';
  isAdmin: Scalars['Boolean']['output'];
  memberCount: Scalars['Int']['output'];
};

export type Network = {
  __typename?: 'Network';
  createdAt: Scalars['Date']['output'];
  extra: MyInitiativeNetworkExtra;
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  members: Array<UserNetwork>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type NormalizedManufacturerList = {
  __typename?: 'NormalizedManufacturerList';
  data: Array<Scalars['String']['output']>;
};

export type NormalizedSupplier = {
  __typename?: 'NormalizedSupplier';
  inputSupplier: Scalars['String']['output'];
  normalizedCurvo?: Maybe<Scalars['String']['output']>;
  retry?: Maybe<Array<NormalizedSupplierRetry>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type NormalizedSupplierRetry = {
  __typename?: 'NormalizedSupplierRetry';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type NormalizedSupplierSearchResult = {
  __typename?: 'NormalizedSupplierSearchResult';
  data: Array<Scalars['String']['output']>;
};

export type NormalizedSuppliersForUploadedFileColumn = {
  __typename?: 'NormalizedSuppliersForUploadedFileColumn';
  normalizedSuppliers: Array<NormalizedSupplier>;
  rawData: Array<Scalars['String']['output']>;
};

export type Offer = {
  __typename?: 'Offer';
  additionalInfo?: Maybe<AdditionalInfo>;
  attachment?: Maybe<OpportunityAttachment>;
  attachments: Array<OpportunityAttachment>;
  /** Get offer baskets */
  baskets?: Maybe<Array<Basket>>;
  createdAt: Scalars['Date']['output'];
  crossRefListId?: Maybe<Scalars['String']['output']>;
  crossReferenceList?: Maybe<CrossReferenceList>;
  crossReferenceListId?: Maybe<CrossReferenceList>;
  crossStats: CrossStats;
  customer: Customer;
  customerId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  facilities?: Maybe<Array<OfferItemFacilityInfo>>;
  facilityIds?: Maybe<Array<Scalars['Int']['output']>>;
  facilityInfo?: Maybe<FacilityInfo>;
  gmdns: Array<Scalars['String']['output']>;
  goal?: Maybe<Scalars['Float']['output']>;
  goalThresholdMax?: Maybe<Scalars['Float']['output']>;
  goalThresholdMin?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  invitingVendors?: Maybe<Array<OfferInvitingVendor>>;
  isArchivedOpportunity?: Maybe<Scalars['Boolean']['output']>;
  isCrossRequest?: Maybe<Scalars['Boolean']['output']>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  keyContact?: Maybe<User>;
  keyContactId?: Maybe<Scalars['String']['output']>;
  metrics?: Maybe<MetricsType>;
  name?: Maybe<Scalars['String']['output']>;
  numberSKU?: Maybe<Scalars['Int']['output']>;
  offerBasketInsights?: Maybe<Array<OfferBasketInsightsResult>>;
  /** This resolve field is only meant to be called when the opportunity is being created, to get real time insights from the baskets */
  offerCreationInsights?: Maybe<BasketInsights>;
  /** Get the offer insights, based on the stored data in the offer */
  offerInsights: OfferInsights;
  offerItems: OfferItemResult;
  offerPricingItem: OfferPricingItem;
  offerPricingItems: OfferPricingItemResult;
  opportunityEmailSubjectTemplate?: Maybe<Scalars['String']['output']>;
  opportunityEmailTemplate?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Offer>;
  parentId?: Maybe<Scalars['String']['output']>;
  parentStatus?: Maybe<OppotunityStatusEnum>;
  requestNumber?: Maybe<Scalars['String']['output']>;
  scenarios: Array<OfferScenario>;
  secretCode?: Maybe<Scalars['String']['output']>;
  sharedUsers: Array<SharedUserInfo>;
  skipZeroQuantityItems: Scalars['Boolean']['output'];
  spendDateFrom?: Maybe<Scalars['String']['output']>;
  spendDateRange?: Maybe<BasketSpendDateRanges>;
  spendDateTo?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: OfferStatusEnum;
  tierSummary?: Maybe<Array<TierSummary>>;
  tiers?: Maybe<Array<Tier>>;
  timeline?: Maybe<OfferTimeline>;
  totalBasket?: Maybe<Scalars['Int']['output']>;
  totalOffer?: Maybe<Scalars['Int']['output']>;
  totalSpend?: Maybe<Scalars['Float']['output']>;
  totalVendor?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Date']['output'];
  user?: Maybe<User>;
  vendorResponse?: Maybe<VendorResponsesEnum>;
};


export type OfferAttachmentArgs = {
  id: Scalars['String']['input'];
};


export type OfferOfferCreationInsightsArgs = {
  basketIds?: InputMaybe<Array<Scalars['String']['input']>>;
  spendDateRange?: InputMaybe<SpendDateRangeInput>;
};


export type OfferOfferInsightsArgs = {
  basketIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type OfferOfferItemsArgs = {
  crossStatus?: InputMaybe<Scalars['Boolean']['input']>;
  crossed?: InputMaybe<CrossedStatus>;
  gmdn?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  linked?: InputMaybe<Scalars['Boolean']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  offeredManufacturer?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  pricing?: InputMaybe<Scalars['Boolean']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<OfferItemsSortInput>>;
  submittedManufacturer?: InputMaybe<Scalars['String']['input']>;
};


export type OfferOfferPricingItemArgs = {
  id: Scalars['String']['input'];
};


export type OfferOfferPricingItemsArgs = {
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<Pagination>;
  priceFilter?: InputMaybe<TierPriceFilterInput>;
  quantityFilter?: InputMaybe<OfferPricingItemQuantityFilterInput>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CatalogPartsSortInput>>;
  utilization?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferBasketInsightsResult = {
  __typename?: 'OfferBasketInsightsResult';
  offer: Offer;
  tiers: Array<OfferBasketInsightsResultTier>;
};

export type OfferBasketInsightsResultBasket = {
  __typename?: 'OfferBasketInsightsResultBasket';
  basket: Basket;
  coveredItems?: Maybe<Scalars['Int']['output']>;
  coveredPercentage?: Maybe<Scalars['Float']['output']>;
  incumbentTotalProjected?: Maybe<Scalars['Float']['output']>;
  offeredPriceScore?: Maybe<Scalars['Float']['output']>;
  offeredTotalProjected?: Maybe<Scalars['Float']['output']>;
  savingsPercentage?: Maybe<Scalars['Float']['output']>;
  savingsTotal?: Maybe<Scalars['Float']['output']>;
  totalItems?: Maybe<Scalars['Int']['output']>;
};

export type OfferBasketInsightsResultTier = {
  __typename?: 'OfferBasketInsightsResultTier';
  baskets: Array<OfferBasketInsightsResultBasket>;
  tier: Tier;
};

export type OfferBasketItemsCount = {
  __typename?: 'OfferBasketItemsCount';
  basketId: Scalars['String']['output'];
  itemsCount: Scalars['Float']['output'];
};

export type OfferCodeResult = {
  __typename?: 'OfferCodeResult';
  customer?: Maybe<CustomerView>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type OfferCrossStats = {
  __typename?: 'OfferCrossStats';
  matchableTotal: Scalars['Float']['output'];
};

export type OfferFavorite = {
  __typename?: 'OfferFavorite';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  offerId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type OfferFavoriteInput = {
  offerId: Scalars['String']['input'];
};

export type OfferHospitalSort = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<OfferHospitalSortField>;
};

export enum OfferHospitalSortField {
  Name = 'name'
}

export type OfferInsights = {
  __typename?: 'OfferInsights';
  basketItemsCount: Array<OfferBasketItemsCount>;
  normalizedVendors: Array<Scalars['String']['output']>;
  numItems: Scalars['Int']['output'];
  projectedIqo25: Scalars['Float']['output'];
  projectedIqo50: Scalars['Float']['output'];
  projectedIqo75: Scalars['Float']['output'];
  projectedIqo90: Scalars['Float']['output'];
  projectedSpend: Scalars['Float']['output'];
  statsByVendor: Array<OfferItemStatsByVendor>;
};

export type OfferInvitingVendor = {
  __typename?: 'OfferInvitingVendor';
  createdAt?: Maybe<Scalars['Date']['output']>;
  customer?: Maybe<Customer>;
  customerId: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  joinedVendorCustomer?: Maybe<Customer>;
  joinedVendorCustomerId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offer?: Maybe<Offer>;
  offerId: Scalars['String']['output'];
  offers?: Maybe<Array<Offer>>;
  officer?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type OfferItem = {
  __typename?: 'OfferItem';
  averagePrice?: Maybe<Scalars['Float']['output']>;
  basket?: Maybe<Basket>;
  comment?: Maybe<OfferItemComment>;
  createdAt: Scalars['Date']['output'];
  crossReferenceSuggestions?: Maybe<Array<CatalogPart>>;
  crossStatus?: Maybe<Scalars['Boolean']['output']>;
  curvo25?: Maybe<Scalars['Float']['output']>;
  curvo50?: Maybe<Scalars['Float']['output']>;
  curvo75?: Maybe<Scalars['Float']['output']>;
  curvo90?: Maybe<Scalars['Float']['output']>;
  customer?: Maybe<Customer>;
  customerId: Scalars['String']['output'];
  esId: Scalars['String']['output'];
  facilityId?: Maybe<Scalars['String']['output']>;
  facilityName?: Maybe<Scalars['String']['output']>;
  /** A group ID for items with the same product */
  groupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  item?: Maybe<CatalogPart>;
  itemConversion?: Maybe<Scalars['String']['output']>;
  itemEnrichment?: Maybe<OfferItemEnrichment>;
  itemTsId: Scalars['String']['output'];
  itemUoM?: Maybe<Scalars['String']['output']>;
  masterCatalogData?: Maybe<MasterCatalogData>;
  matchedCrossRefVendorItems?: Maybe<Scalars['Int']['output']>;
  noCross?: Maybe<Scalars['Boolean']['output']>;
  noCrossComment?: Maybe<Scalars['String']['output']>;
  offer?: Maybe<Offer>;
  offerId: Scalars['String']['output'];
  offerItem?: Maybe<CatalogPart>;
  offerTsId?: Maybe<Scalars['String']['output']>;
  percentiles?: Maybe<Array<Scalars['Float']['output']>>;
  priceTiers?: Maybe<Array<CatalogPartTier>>;
  pricingTarget?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  secondOfferItem?: Maybe<CatalogPart>;
  secondOfferTsId?: Maybe<Scalars['String']['output']>;
  submittedItem?: Maybe<CatalogPart>;
  submittedTsId: Scalars['String']['output'];
  suggestion?: Maybe<OfferItemSuggestion>;
  unitprice?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export enum OfferItemComment {
  Instrument = 'Instrument',
  InvalidPartNumber = 'InvalidPartNumber',
  NoComparable = 'NoComparable',
  Other = 'Other',
  OutOfScope = 'OutOfScope'
}

export type OfferItemEnrichment = {
  __typename?: 'OfferItemEnrichment';
  description?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerItem?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  vendorItem?: Maybe<Scalars['String']['output']>;
};

export enum OfferItemExportsFieldEnum {
  AveragePrice = 'averagePrice',
  Comment = 'comment',
  Conversion = 'conversion',
  CrossAveragePrice = 'crossAveragePrice',
  CrossConversion = 'crossConversion',
  CrossCurvo25 = 'crossCurvo25',
  CrossCurvo50 = 'crossCurvo50',
  CrossCurvo75 = 'crossCurvo75',
  CrossCurvo90 = 'crossCurvo90',
  CrossDescription = 'crossDescription',
  CrossGic = 'crossGic',
  CrossGmdn = 'crossGmdn',
  CrossGtin = 'crossGtin',
  CrossManufacturer = 'crossManufacturer',
  CrossManufacturerItem = 'crossManufacturerItem',
  CrossProductCategory = 'crossProductCategory',
  CrossProductSubCategory = 'crossProductSubCategory',
  CrossTypeOne = 'crossTypeOne',
  CrossTypeTwo = 'crossTypeTwo',
  CrossUom = 'crossUom',
  Curvo25 = 'curvo25',
  Curvo50 = 'curvo50',
  Curvo75 = 'curvo75',
  Curvo90 = 'curvo90',
  Description = 'description',
  Gic = 'gic',
  Gmdn = 'gmdn',
  Gtin = 'gtin',
  Id = 'id',
  Manufacturer = 'manufacturer',
  ManufacturerItem = 'manufacturerItem',
  MatchType = 'matchType',
  Quantity = 'quantity',
  TierPricing = 'tierPricing',
  TypeOne = 'typeOne',
  TypeTwo = 'typeTwo',
  Uom = 'uom',
  Vendor = 'vendor',
  VendorItem = 'vendorItem'
}

export type OfferItemFacilityInfo = {
  __typename?: 'OfferItemFacilityInfo';
  facilityId: Scalars['Float']['output'];
  facilityName?: Maybe<Scalars['String']['output']>;
  spendDateFrom?: Maybe<Scalars['Date']['output']>;
  spendDateTo?: Maybe<Scalars['Date']['output']>;
};

export type OfferItemResult = {
  __typename?: 'OfferItemResult';
  crossStats?: Maybe<CrossStats>;
  hits: Array<ExtendedOfferItem>;
  totalHits: Scalars['Int']['output'];
};

export type OfferItemStatsByVendor = {
  __typename?: 'OfferItemStatsByVendor';
  totalSpend?: Maybe<Scalars['Float']['output']>;
  vendor: Scalars['String']['output'];
};

export type OfferItemSuggestion = {
  __typename?: 'OfferItemSuggestion';
  isMine: Scalars['Boolean']['output'];
  item: CatalogPartSuggestionItem;
};

export enum OfferItemsSortFieldEnum {
  Manufacturer = 'manufacturer',
  ManufacturerItem = 'manufacturerItem',
  OfferManufacturer = 'offerManufacturer',
  OfferManufacturerItem = 'offerManufacturerItem',
  SubmittedManufacturer = 'submittedManufacturer',
  SubmittedManufacturerItem = 'submittedManufacturerItem'
}

export type OfferItemsSortInput = {
  direction?: InputMaybe<OrderDirection>;
  field: OfferItemsSortFieldEnum;
};

export type OfferPricingItem = {
  __typename?: 'OfferPricingItem';
  catalogPart: CatalogPart;
  catalogPartPack: Array<CatalogPartPack>;
  catalogPartTiers: Array<CatalogPartTier>;
  id: Scalars['String']['output'];
  offerItem: Array<OfferItem>;
  totalQuantity: Scalars['Int']['output'];
};

export type OfferPricingItemQuantityFilterInput = {
  operator: TierFilterOperatorEnum;
  quantityFirst?: InputMaybe<Scalars['Float']['input']>;
  quantitySecond?: InputMaybe<Scalars['Float']['input']>;
};

export type OfferPricingItemResult = {
  __typename?: 'OfferPricingItemResult';
  hits: Array<OfferPricingItem>;
  totalHits: Scalars['Int']['output'];
};

export enum OfferPricingTargetType {
  Benchmark = 'benchmark',
  Percentage = 'percentage',
  Value = 'value'
}

export type OfferResult = {
  __typename?: 'OfferResult';
  hits: Array<Offer>;
  totalHits: Scalars['Int']['output'];
};

export type OfferScenario = {
  __typename?: 'OfferScenario';
  awardedAt?: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['Date']['output'];
  enableCommitment: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isAwarded: Scalars['Boolean']['output'];
  isInvalid?: Maybe<Scalars['Boolean']['output']>;
  items: OfferScenarioItemResult;
  name: Scalars['String']['output'];
  offers?: Maybe<Array<Offer>>;
  opportunity?: Maybe<Offer>;
  opportunityId: Scalars['String']['output'];
  priorities: Array<OfferScenarioPriorityUnion>;
  stats: ScenarioStats;
  tiers?: Maybe<Array<Tier>>;
  updatedAt: Scalars['Date']['output'];
};


export type OfferScenarioItemsArgs = {
  input?: InputMaybe<OfferScenarioItemsFilterInput>;
  pagination: Pagination;
};

export type OfferScenarioItem = {
  __typename?: 'OfferScenarioItem';
  createdAt: Scalars['Date']['output'];
  excluded?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  offerItem?: Maybe<OfferItem>;
  offerItemId?: Maybe<Scalars['String']['output']>;
  opportunityItem: OfferItem;
  opportunityItemId: Scalars['String']['output'];
  scenario?: Maybe<OfferScenario>;
  scenarioId: Scalars['String']['output'];
  tier?: Maybe<Tier>;
  tierId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type OfferScenarioItemResult = {
  __typename?: 'OfferScenarioItemResult';
  hits: Array<OfferScenarioItem>;
  totalHits: Scalars['Int']['output'];
};

export type OfferScenarioItemsFilterInput = {
  itemType?: Scalars['String']['input'];
};

export type OfferScenarioPriority = IOfferScenarioPriority & {
  __typename?: 'OfferScenarioPriority';
  basketPriority?: Maybe<BasketPriority>;
  isDisabled: Scalars['Boolean']['output'];
  isStrict: Scalars['Boolean']['output'];
  type: OfferScenarioPriorityType;
  vendorPriority?: Maybe<VendorPriority>;
};

export type OfferScenarioPriorityBasketSaving = IOfferScenarioPriority & {
  __typename?: 'OfferScenarioPriorityBasketSaving';
  /** Specify the priority of Basket Saving */
  basketPriority: Scalars['String']['output'];
  isDisabled: Scalars['Boolean']['output'];
  isStrict: Scalars['Boolean']['output'];
  type: OfferScenarioPriorityType;
};

export type OfferScenarioPriorityGreatestSaving = IOfferScenarioPriority & {
  __typename?: 'OfferScenarioPriorityGreatestSaving';
  isDisabled: Scalars['Boolean']['output'];
  isStrict: Scalars['Boolean']['output'];
  type: OfferScenarioPriorityType;
};

export type OfferScenarioPriorityInput = {
  /** Specify the priority of Basket Saving, only for BsketSaving */
  basketPriority?: InputMaybe<Scalars['String']['input']>;
  isDisabled?: Scalars['Boolean']['input'];
  isStrict?: Scalars['Boolean']['input'];
  type: OfferScenarioPriorityType;
  /** Specify the priority of Vendors, only for VendorPriority */
  vendorPriority?: InputMaybe<Scalars['String']['input']>;
};

export enum OfferScenarioPriorityType {
  BasketSaving = 'BasketSaving',
  FewestVendorPossible = 'FewestVendorPossible',
  GreatestSaving = 'GreatestSaving',
  SwitchNoProduct = 'SwitchNoProduct',
  Vendor = 'Vendor'
}

export type OfferScenarioPriorityUnion = OfferScenarioPriority | OfferScenarioPriorityBasketSaving | OfferScenarioPriorityGreatestSaving | OfferScenarioPriorityVendor;

export type OfferScenarioPriorityVendor = IOfferScenarioPriority & {
  __typename?: 'OfferScenarioPriorityVendor';
  isDisabled: Scalars['Boolean']['output'];
  isStrict: Scalars['Boolean']['output'];
  type: OfferScenarioPriorityType;
  /** Specify the priority of Vendors */
  vendorPriority: Scalars['String']['output'];
};

export type OfferStats = {
  __typename?: 'OfferStats';
  opportunityGoal?: Maybe<Scalars['Float']['output']>;
  /** Opportunity's Total Spend from last purchase */
  opportunityTotalSpend?: Maybe<Scalars['Float']['output']>;
  tiers: Array<OfferTierSummary>;
};

export enum OfferStatusEnum {
  Archived = 'archived',
  Finished = 'finished',
  InProgress = 'inProgress',
  Pending = 'pending',
  Processing = 'processing',
  Rejected = 'rejected'
}

export type OfferTierInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type OfferTierRankItem = {
  __typename?: 'OfferTierRankItem';
  rank: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type OfferTierSummary = {
  __typename?: 'OfferTierSummary';
  /** Rank among tiers which have coverage from 50% to 75% */
  coverage50pctRank?: Maybe<OfferTierRankItem>;
  /** Rank among tiers which have coverage from 75% to 90% */
  coverage75pctRank?: Maybe<OfferTierRankItem>;
  /** Rank among tiers which have more than 90% coverage */
  coverage90pctRank?: Maybe<OfferTierRankItem>;
  /** Rank by coverage percentage */
  coverageRank?: Maybe<OfferTierRankItem>;
  itemCoverage: Scalars['Float']['output'];
  /** Rank by pricing */
  pricingRank?: Maybe<OfferTierRankItem>;
  tier: Tier;
  totalIncumbentSpend: Scalars['Float']['output'];
  totalProjectedSpend: Scalars['Float']['output'];
};

export type OfferTimeline = {
  __typename?: 'OfferTimeline';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  offerId: Scalars['String']['output'];
  status: OfferTimelineStatus;
  updatedAt: Scalars['Date']['output'];
};

export enum OfferTimelineStatus {
  Accepted = 'Accepted',
  Invited = 'Invited',
  Rejected = 'Rejected',
  Responded = 'Responded',
  Responding = 'Responding',
  Viewed = 'Viewed'
}

export type OfferUploadInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  data: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  fileName: Scalars['String']['input'];
  hospital?: InputMaybe<Scalars['String']['input']>;
  keyContact?: InputMaybe<OfferUploadKeyContactInput>;
  mapping: UploadOfferMapping;
  name: Scalars['String']['input'];
  provider?: InputMaybe<OfferUploadProviderInput>;
  selectedExcelSheet?: InputMaybe<Scalars['String']['input']>;
};

export type OfferUploadKeyContactInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type OfferUploadProviderInput = {
  address: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type OffersInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  hospital?: InputMaybe<Scalars['String']['input']>;
  invitationStatus?: InputMaybe<InvitationStatusEnum>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isCrossRequest?: InputMaybe<Scalars['Boolean']['input']>;
  isMissedDeadline?: InputMaybe<Scalars['Boolean']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  opportunityOnly?: InputMaybe<Scalars['Boolean']['input']>;
  opportunityStatus?: InputMaybe<OppotunityStatusEnum>;
  owner?: InputMaybe<OpportunityOwnerEnum>;
  requestNumber?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OfferStatusEnum>;
};

export type OpportunityAttachment = {
  __typename?: 'OpportunityAttachment';
  base64: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** Contains list of `userId`s, which have downloaded this document */
  downloadedBy?: Maybe<Array<Scalars['String']['output']>>;
  hidden: Scalars['Boolean']['output'];
  /** Contains list of `userId`, which this document will be hidden from documents grid */
  hiddenFor?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  /** For vendor specific attachments, if null then it is available for all vendors */
  invitingVendorIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Indicate that this user has never downloaded this file before */
  isNew: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** For Offer specific attachments */
  offerId?: Maybe<Scalars['String']['output']>;
  opportunityId: Scalars['String']['output'];
  s3Key: Scalars['String']['output'];
  uploadedBy: AttachmentUploadedFrom;
  /** Uploaded by vendor */
  vendor?: Maybe<Customer>;
  /** Uploaded by vendor's customerId, if null then this attachment is uploaded by Hospital */
  vendorCustomerId?: Maybe<Scalars['String']['output']>;
};

export type OpportunityAttachmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  hide?: InputMaybe<Scalars['Boolean']['input']>;
  /** Attachment ID, null if you want to create new Attachment */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Inviting Vendor Ids, use this for Vendors specific attachments */
  invitingVendorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
};

export type OpportunityCustomizationInfoInput = {
  key: Scalars['String']['input'];
  offerId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type OpportunityCustomizationInfoResult = {
  __typename?: 'OpportunityCustomizationInfoResult';
  data: Scalars['String']['output'];
};

export type OpportunityCustomizationInput = {
  id: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type OpportunityCustomizationResult = {
  __typename?: 'OpportunityCustomizationResult';
  taskId: Scalars['String']['output'];
};

export type OpportunityCustomizationSaveInput = {
  data: Scalars['String']['input'];
  key: Scalars['String']['input'];
  offerId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type OpportunityEmailTemplatePreviewInput = {
  isSubject?: InputMaybe<Scalars['Boolean']['input']>;
  opportunityId: Scalars['String']['input'];
  template: Scalars['String']['input'];
};

export type OpportunityInviteBaseInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officer?: InputMaybe<Scalars['String']['input']>;
};

export type OpportunityKeyContact = {
  __typename?: 'OpportunityKeyContact';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export enum OpportunityOwnerEnum {
  Mine = 'Mine',
  Shared = 'Shared'
}

export type OpportunityProvider = {
  __typename?: 'OpportunityProvider';
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export enum OpportunityStatBenchmark {
  Curvo25 = 'Curvo25',
  Curvo50 = 'Curvo50',
  Curvo75 = 'Curvo75',
  Curvo90 = 'Curvo90'
}

export type OpportunityStatValue = {
  __typename?: 'OpportunityStatValue';
  name?: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
};

export type OpportunityStats = {
  __typename?: 'OpportunityStats';
  gicMarketShare: Array<OpportunityStatValue>;
  gmdnMarketShare: Array<OpportunityStatValue>;
  vendorMarketShare: Array<OpportunityStatValue>;
};

export type OpportunityStatsQueryInput = {
  benchMarkType: OpportunityStatBenchmark;
  opportunityId: Scalars['String']['input'];
};

export enum OpportunitySupplierStatusEnum {
  Archived = 'archived',
  Completed = 'completed',
  Declined = 'declined',
  InProgress = 'inProgress',
  New = 'new'
}

export enum OppotunityStatusEnum {
  Active = 'active',
  Archived = 'archived',
  Complete = 'complete',
  Draft = 'draft'
}

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Organization = {
  __typename?: 'Organization';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  allParents: Array<Organization>;
  city?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  customers: Array<Customer>;
  description?: Maybe<Scalars['String']['output']>;
  facilityId?: Maybe<Scalars['Int']['output']>;
  geoCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  medicareId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationType?: Maybe<OrganizationType>;
  organizationTypeId?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Organization>;
  parentId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  subOrganizations: Array<Organization>;
  topPowerUsers: Array<User>;
  topUsers: Array<User>;
  totalSubOrganizations: Scalars['Int']['output'];
  totalUsers: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
  users: Array<UserOrganization>;
  website?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type OrganizationPermission = {
  isPowerUser: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  customer?: Maybe<Customer>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PaginatedSavedSearch = {
  __typename?: 'PaginatedSavedSearch';
  data: Array<SavedSearch>;
  total: Scalars['Int']['output'];
};

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Part = {
  __typename?: 'Part';
  commodityId?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<Contract>;
  createdAt: Scalars['Date']['output'];
  customer?: Maybe<Customer>;
  description: Scalars['String']['output'];
  gudId?: Maybe<Scalars['String']['output']>;
  manufacturer: Scalars['String']['output'];
  mfgitem: Scalars['String']['output'];
  normalizedManufacturer: Scalars['String']['output'];
  partTiers?: Maybe<Array<PartTier>>;
  stanId?: Maybe<Scalars['String']['output']>;
  strippedManufacturerItem: Scalars['String']['output'];
  tsId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  vendor?: Maybe<Scalars['String']['output']>;
  venitem?: Maybe<Scalars['String']['output']>;
};

export type PartTier = {
  __typename?: 'PartTier';
  createdAt: Scalars['Date']['output'];
  part: Part;
  partsTsId: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  tier: Tier;
  tiersId: Scalars['ID']['output'];
  uom?: Maybe<Scalars['String']['output']>;
  uomConversion?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type PartTierInput = {
  partsTsId: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  tiersId: Scalars['ID']['input'];
  uom?: InputMaybe<Scalars['String']['input']>;
  uomConversion?: InputMaybe<Scalars['Int']['input']>;
};

export type PinSourcingPlanInput = {
  basketId: Scalars['String']['input'];
  pin: Scalars['Boolean']['input'];
  sourcingPlanId?: InputMaybe<Scalars['String']['input']>;
};

export type PlainCrossReferenceCrossItem = {
  __typename?: 'PlainCrossReferenceCrossItem';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gicId?: Maybe<Scalars['Int']['output']>;
  gicName?: Maybe<Scalars['String']['output']>;
  gmdn?: Maybe<Array<Scalars['String']['output']>>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerItem?: Maybe<Scalars['String']['output']>;
  matchType?: Maybe<CrossReferenceType>;
  materialId?: Maybe<Scalars['String']['output']>;
  pack?: Maybe<Array<CatalogPartPack>>;
  sizeOne: Scalars['Float']['output'];
  sizeThree: Scalars['Float']['output'];
  sizeTwo: Scalars['Float']['output'];
  subCategory?: Maybe<Scalars['String']['output']>;
  tsId: Scalars['String']['output'];
  typeOneName?: Maybe<Scalars['String']['output']>;
  typeTwoName?: Maybe<Scalars['String']['output']>;
};

/** Plain cross-references by cross-reference list id */
export type PlainCrossReferenceList = {
  __typename?: 'PlainCrossReferenceList';
  hits: Array<PlainCrossReferenceMapping>;
  totalHits: Scalars['Int']['output'];
};

export type PlainCrossReferenceMapping = {
  __typename?: 'PlainCrossReferenceMapping';
  crossItem?: Maybe<PlainCrossReferenceCrossItem>;
  submittedItem: PlainCrossReferenceSubmittedItem;
};

export type PlainCrossReferenceSubmittedItem = {
  __typename?: 'PlainCrossReferenceSubmittedItem';
  category?: Maybe<Scalars['String']['output']>;
  /** No-Cross type */
  comment?: Maybe<OfferItemComment>;
  crossStatus?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gicId?: Maybe<Scalars['Int']['output']>;
  gicName?: Maybe<Scalars['String']['output']>;
  gmdn?: Maybe<Array<Scalars['String']['output']>>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerItem?: Maybe<Scalars['String']['output']>;
  materialId?: Maybe<Scalars['String']['output']>;
  /** No-Cross comment */
  noCrossComment?: Maybe<Scalars['String']['output']>;
  sizeOne: Scalars['Float']['output'];
  sizeThree: Scalars['Float']['output'];
  sizeTwo: Scalars['Float']['output'];
  subCategory?: Maybe<Scalars['String']['output']>;
  tsId: Scalars['String']['output'];
  typeOneName?: Maybe<Scalars['String']['output']>;
  typeTwoName?: Maybe<Scalars['String']['output']>;
};

export type PowerUserListItem = {
  __typename?: 'PowerUserListItem';
  facilityId?: Maybe<Scalars['Int']['output']>;
  isPowerUser: Scalars['Boolean']['output'];
  organizationId: Scalars['String']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
  rank: Scalars['Int']['output'];
  userRank: Scalars['Int']['output'];
};

export type PriceSpray = {
  __typename?: 'PriceSpray';
  facilityId: Scalars['Float']['output'];
  priceSpray: Array<PriceSprayData>;
};

export type PriceSprayData = {
  __typename?: 'PriceSprayData';
  totalQtyPurchase: Scalars['Float']['output'];
  unitprice: Scalars['Float']['output'];
};

export type PriceTierInput = {
  /** Tier ID */
  id: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  uom: Scalars['String']['input'];
  uomConversion: Scalars['Int']['input'];
};

export type ProcessBasketUploadInput = {
  basketId?: InputMaybe<Scalars['String']['input']>;
  basketName: Scalars['String']['input'];
  mappedFacilities: Scalars['String']['input'];
  mappedFields: MappedFieldsType;
  mappedManufacturers: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
  selectedExcelSheet?: InputMaybe<Scalars['String']['input']>;
};

export type ProcessContractUploadInput = {
  contractId: Scalars['String']['input'];
  mappedFields: ContractMappedFieldsType;
  replace?: Scalars['Boolean']['input'];
  s3Key: Scalars['String']['input'];
  selectedExcelSheet?: InputMaybe<Scalars['String']['input']>;
};

export type ProcessContractXrefUploadInput = {
  contractId: Scalars['String']['input'];
  mappedFields: ContractXrefMappedFieldsType;
  name: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
  selectedExcelSheet?: InputMaybe<Scalars['String']['input']>;
};

export type ProcessedBasketResult = {
  __typename?: 'ProcessedBasketResult';
  basketId: Scalars['String']['output'];
};

export type ProductCode = {
  __typename?: 'ProductCode';
  productcode: Scalars['String']['output'];
  productcodename?: Maybe<Scalars['String']['output']>;
};

export type Project = {
  __typename?: 'Project';
  createdAt: Scalars['Date']['output'];
  creator: User;
  customer: Customer;
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  status: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  users: Array<User>;
};

export type ProjectApertureInvitationInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  networkId?: InputMaybe<Scalars['String']['input']>;
  /** Network role */
  role?: InputMaybe<UserNetworkRoleEnum>;
};

export type PublicVendorsResult = {
  __typename?: 'PublicVendorsResult';
  hits: Array<CustomerView>;
  totalHits: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  activate: Activate;
  adminGetString: Scalars['String']['output'];
  adminSupplierOpportunities: SupplierOpportunitiesResult;
  allowedGicTypeOnes: Array<GicTypeOne>;
  allowedGicTypeTwos: Array<GicTypeTwo>;
  awardPriorities: AwardPrioritiesResult;
  basket: SmartBasket;
  basketInsights: BasketInsights;
  basketLastPurchaseDate: LastPurchaseDateResult;
  basketProducts: Array<BasketProduct>;
  basketStakeholderSurveyPreview: Scalars['String']['output'];
  baskets: BasketsResult;
  basketsByACL: Array<Basket>;
  basketsFacilitiesStats: Array<BasketFacilityStat>;
  catalogPart: CatalogPart;
  catalogPartTierInfo: CatalogPartTier;
  catalogParts: CatalogPartResult;
  catalogSearch: CatalogPartResult;
  catalogStats: CatalogStats;
  /** Get clinical Data Relevant Studies by Gudid */
  clinicalStudies: ClinicalStudiesResult;
  contactUsers: Array<ContactUserWrapper>;
  contract: Contract;
  contractBasket: Basket;
  contractCategories: Array<Scalars['String']['output']>;
  contractHistories: ContractHistoryResult;
  contractMember: ContractMember;
  contractMemberTierHistories: Array<ContractOrganizationHistory>;
  contractMembers: Array<ContractMember>;
  contractOrganizations: Array<ContractOrganization>;
  contractPartsCount: Scalars['Int']['output'];
  contractSubCategories: Array<Scalars['String']['output']>;
  contractTiers: Array<ContractTier>;
  contracts: Array<Contract>;
  contractsByACL: Array<Contract>;
  createQuicksightGroup: CreateQuicksightGroupType;
  crossReferenceClusters: CrossReferenceClustersResult;
  crossReferenceList: CrossReferenceList;
  crossReferenceLists: CrossReferenceListResult;
  crossReferenceListsByACL: Array<CrossReferenceList>;
  crossReferenceMapStats: CrossReferenceMapStats;
  curvoErrors: Array<CurvoErrorCode>;
  customer: Customer;
  customerContracts: Array<Contract>;
  customers: Array<Customer>;
  customersWithStats: Array<Customer>;
  dashboards: DashboardsResult;
  defaultPermissions: UserAbilities;
  detailedItem: ItemSpend;
  doBasketSearch: SearchBasketResult;
  /** Return Base64 XLSX file containing cross-references given cross-reference list id. */
  exportCrossReferences: Scalars['String']['output'];
  exportErrors: Scalars['String']['output'];
  exportHistories: ExportHistories;
  exportSupplierOpportunityItems: Scalars['String']['output'];
  externalAuthConnections: Array<ExternalAuthConnection>;
  featuredBaskets: Array<FeaturedBasket>;
  filterUploadedFileColumn: FilterColumnResult;
  getConsoleEmbedUrl: QuicksightDashboardEmbedUrlType;
  getDashboardById: Dashboard;
  getDashboardEmbedUrl: QuicksightDashboardEmbedUrlType;
  getListOfDashboards: Array<QuicksightDashboardItemType>;
  getSpendDataForVendors: Array<SpendDataForVendorsResult>;
  getString: Scalars['String']['output'];
  getSupplierDashboards: Array<Dashboard>;
  gics: Array<Gic>;
  /** Filter master ids with existing Similar Products */
  hasSimilarProducts: Array<Scalars['String']['output']>;
  homepageFeaturedContents: Array<HomepageFeaturedContent>;
  initiative: Initiative;
  initiativeNetwork: Network;
  initiativeSelectorsList: InitiativeSelectorsList;
  initiatives: InitiativesResult;
  /** Get transaction data by ts id and facility id */
  itemTransactions: ItemTransactionResult;
  keyContacts: Array<OpportunityKeyContact>;
  lastPurchaseDate: LastPurchaseDateResult;
  linkageSearch: MasterCatalogResult;
  listDashboardsForUser: Array<QuicksightDashboardItemType>;
  me: UserContext;
  memberAnalysis: Array<MemberAnalysisOutput>;
  messageTemplates: MessageTemplateList;
  myInitiativeNetworks: Array<Network>;
  normalizedManufacturers: NormalizedManufacturerList;
  normalizedSupplierNamesForUploadedFileColumn: NormalizedSuppliersForUploadedFileColumn;
  normalizedSuppliers: Array<NormalizedSupplier>;
  offer: Offer;
  offerCode: OfferCodeResult;
  offerDetail: Offer;
  offerHospitals: Array<SupplierHospital>;
  offerItem?: Maybe<OfferItem>;
  offerManufacturers: Array<Scalars['String']['output']>;
  offerOfferedManufacturers: Array<Scalars['String']['output']>;
  offerScenario: OfferScenario;
  offerStats: OfferStats;
  offerSubmittedManufacturers: Array<Scalars['String']['output']>;
  offers: OfferResult;
  opportunityCustomization: OpportunityCustomizationResult;
  opportunityCustomizationInfo: OpportunityCustomizationInfoResult;
  opportunityInvitingVendors: Array<OfferInvitingVendor>;
  opportunityScenarios: Array<OfferScenario>;
  organization: Organization;
  organizationTreeByCustomer: Array<Organization>;
  organizationTypes: Array<OrganizationType>;
  organizationUsers: Array<UserOrganization>;
  organizations: Array<Organization>;
  partTiers: Array<PartTier>;
  partsByACL: Array<Part>;
  permissionTo: Scalars['String']['output'];
  ping: Scalars['String']['output'];
  pinnedSavedSearches: Array<SavedSearch>;
  previewOpportunityEmailTemplate: Scalars['String']['output'];
  projects: Array<Project>;
  publicVendors: PublicVendorsResult;
  queryOpportunityStats: OpportunityStats;
  quickSightGetGroups: Array<Scalars['String']['output']>;
  relatedSearch: CatalogPartResult;
  savedMappings: SavedMappingList;
  savedSearch: SavedSearch;
  savedSearches: PaginatedSavedSearch;
  scenarioTierConversionBreakdown: Array<ScenarioTierConversionBreakdown>;
  searchGmdnNames: Array<Scalars['String']['output']>;
  searchGudidBrands: Array<Scalars['String']['output']>;
  searchMasterCatalog: MasterCatalogResult;
  searchNormalizedManufacturers: NormalizedSupplierSearchResult;
  searchNormalizedSuppliers: Array<NormalizedSupplier>;
  searchUnspscTitles: Array<Unspsc>;
  /** Get similar products by gudid */
  similarProducts: SimilarProductResult;
  /** Get similar products manufacturers list */
  similarProductsManufacturers: Array<Scalars['String']['output']>;
  /** Get sourcing plan by ID. */
  sourcingPlan: SourcingPlan;
  sourcingPlanBasketBase: SourcingPlanBasketBase;
  sourcingPlanBasketBases: Array<SourcingPlanBasketBase>;
  sourcingPlanSurveyEmailPreview: Scalars['String']['output'];
  /** Get all sourcing plans of particular user. */
  sourcingPlans: Array<SourcingPlan>;
  studiesGet: StudiesGetResult;
  studiesTransactionsGet: Array<QueuedTransaction>;
  studyLinkCount: Scalars['Int']['output'];
  studyLinks: Array<ExtendedQueuedLinkProcess>;
  studyTransactionsCount: Scalars['Int']['output'];
  suggestedVendors: SuggestedVendorsResult;
  supplierOpportunities: SupplierOpportunitiesResult;
  supplierOpportunity: SupplierOpportunity;
  systems: Array<System>;
  tier: Tier;
  tierStats: TierStats;
  tiers: TiersResult;
  uploadHistories: UploadHistories;
  uploadJob: UploadJob;
  uploadedFileKey: UploadedFileKeyResult;
  user: User;
  userActivities: Array<UserActivity>;
  userBaskets: Array<Basket>;
  userHomepageFeaturedContents: Array<HomepageFeaturedContent>;
  users: UsersResult;
  vendor: Customer;
  vendorUsers: VendorUsersResult;
  vendors: VendorsResult;
};


export type QueryAdminSupplierOpportunitiesArgs = {
  customerId: Scalars['String']['input'];
  input: SupplierOpportunitiesInput;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<Sort>>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllowedGicTypeOnesArgs = {
  gicId: Scalars['Int']['input'];
};


export type QueryAllowedGicTypeTwosArgs = {
  typeOneId: Scalars['String']['input'];
};


export type QueryAwardPrioritiesArgs = {
  input: AwardPrioritiesInput;
};


export type QueryBasketArgs = {
  input: IdArgs;
  pagination?: InputMaybe<Pagination>;
};


export type QueryBasketInsightsArgs = {
  input: IdArgs;
};


export type QueryBasketLastPurchaseDateArgs = {
  basketId: Scalars['String']['input'];
};


export type QueryBasketProductsArgs = {
  input: GetBasketProductsByCriteriaInput;
};


export type QueryBasketsArgs = {
  input: GetBasketsByCriteriaInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryBasketsByAclArgs = {
  input: GetBasketAclByCriteriaInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryBasketsFacilitiesStatsArgs = {
  facilityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  filter?: InputMaybe<BasketsFacilitiesChartFilter>;
  pagination: Pagination;
  sort?: InputMaybe<BasketSort>;
  targetPercentile?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCatalogPartArgs = {
  input: IdArgs;
};


export type QueryCatalogPartTierInfoArgs = {
  id: Scalars['String']['input'];
};


export type QueryCatalogPartsArgs = {
  input: GetCatalogPartsByCriteriaInput;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<Array<CatalogPartsSortInput>>;
};


export type QueryCatalogSearchArgs = {
  input: CatalogSearchInput;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<CatalogPartsSortInput>;
};


export type QueryClinicalStudiesArgs = {
  input: ClinicalStudiesInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryContactUsersArgs = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryContractArgs = {
  input: IdArgs;
};


export type QueryContractBasketArgs = {
  input: ContractBasketInput;
};


export type QueryContractHistoriesArgs = {
  input: ContractHistoryInput;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryContractMemberArgs = {
  input: GetContractMemberInput;
};


export type QueryContractMemberTierHistoriesArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryContractMembersArgs = {
  input: GetContractMembersInput;
};


export type QueryContractOrganizationsArgs = {
  input: GetContractOrganizationsByCriteriaInput;
};


export type QueryContractPartsCountArgs = {
  input: GetContractPartsInput;
};


export type QueryContractSubCategoriesArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
};


export type QueryContractTiersArgs = {
  input: ContractTierInput;
};


export type QueryContractsArgs = {
  input: GetContractsByCriteriaInput;
};


export type QueryContractsByAclArgs = {
  input: GetContractAclByCriteriaInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCreateQuicksightGroupArgs = {
  input: CreateQuicksightGroupInput;
};


export type QueryCrossReferenceClustersArgs = {
  id: Scalars['String']['input'];
  pagination?: InputMaybe<Pagination>;
};


export type QueryCrossReferenceListArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCrossReferenceListsArgs = {
  input: GetCrossReferenceListsByCriteriaInput;
};


export type QueryCrossReferenceListsByAclArgs = {
  input: GetCrossReferenceListAclByCriteriaInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCustomerArgs = {
  input: IdArgs;
};


export type QueryCustomerContractsArgs = {
  input: IdArgs;
};


export type QueryCustomersArgs = {
  input: GetCustomersByCriteriaInput;
};


export type QueryCustomersWithStatsArgs = {
  input: GetCustomersByCriteriaInput;
};


export type QueryDashboardsArgs = {
  input: GetDashboardsByCriteriaInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryDetailedItemArgs = {
  input: IdArgs;
};


export type QueryDoBasketSearchArgs = {
  input: BasketSearchInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryExportCrossReferencesArgs = {
  id: Scalars['String']['input'];
  option?: InputMaybe<ExportCrossReferencesOption>;
};


export type QueryExportErrorsArgs = {
  input: IdArgs;
};


export type QueryExportSupplierOpportunityItemsArgs = {
  id: Scalars['String']['input'];
};


export type QueryFeaturedBasketsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryFilterUploadedFileColumnArgs = {
  input: FilterColumnInput;
};


export type QueryGetDashboardByIdArgs = {
  input: GetDashboardInput;
};


export type QueryGetDashboardEmbedUrlArgs = {
  input: GetDashboardEmbedUrlInput;
};


export type QueryGetSpendDataForVendorsArgs = {
  dataset?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetSupplierDashboardsArgs = {
  input: GetSupplierDashboardsInput;
};


export type QueryGicsArgs = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHasSimilarProductsArgs = {
  input: HasSimilarProductsInput;
};


export type QueryHomepageFeaturedContentsArgs = {
  input: HomepageFeaturedContentQueryInput;
};


export type QueryInitiativeArgs = {
  id: Scalars['String']['input'];
};


export type QueryInitiativeNetworkArgs = {
  id: Scalars['String']['input'];
};


export type QueryInitiativesArgs = {
  input: GetInitiativesByCriteriaInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryItemTransactionsArgs = {
  input: DetailedSearchInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryLastPurchaseDateArgs = {
  facilityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryLinkageSearchArgs = {
  input: LinkageSearchInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryListDashboardsForUserArgs = {
  input: ListDashboardsForUserInput;
};


export type QueryMemberAnalysisArgs = {
  input: MemberAnalysisInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryNormalizedSupplierNamesForUploadedFileColumnArgs = {
  input: FilterColumnInput;
};


export type QueryNormalizedSuppliersArgs = {
  input: Array<Scalars['String']['input']>;
};


export type QueryOfferArgs = {
  id: Scalars['String']['input'];
};


export type QueryOfferCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryOfferDetailArgs = {
  id: Scalars['String']['input'];
};


export type QueryOfferHospitalsArgs = {
  sort?: InputMaybe<OfferHospitalSort>;
};


export type QueryOfferItemArgs = {
  id: Scalars['String']['input'];
};


export type QueryOfferManufacturersArgs = {
  id: Scalars['String']['input'];
  linked?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryOfferOfferedManufacturersArgs = {
  crossed?: InputMaybe<CrossedStatus>;
  id: Scalars['String']['input'];
  linked?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryOfferScenarioArgs = {
  id: Scalars['String']['input'];
};


export type QueryOfferStatsArgs = {
  input: IdArgs;
};


export type QueryOfferSubmittedManufacturersArgs = {
  crossed?: InputMaybe<CrossedStatus>;
  id: Scalars['String']['input'];
  linked?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryOffersArgs = {
  input: OffersInput;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryOpportunityCustomizationArgs = {
  input: OpportunityCustomizationInput;
};


export type QueryOpportunityCustomizationInfoArgs = {
  input: OpportunityCustomizationInfoInput;
};


export type QueryOpportunityInvitingVendorsArgs = {
  input: IdArgs;
};


export type QueryOpportunityScenariosArgs = {
  input: IdArgs;
};


export type QueryOrganizationArgs = {
  input: IdArgs;
};


export type QueryOrganizationTreeByCustomerArgs = {
  input: IdArgs;
};


export type QueryOrganizationTypesArgs = {
  input: GetOrganizationTypeByCriteriaInput;
};


export type QueryOrganizationUsersArgs = {
  input: IdArgs;
};


export type QueryOrganizationsArgs = {
  input: GetOrganizationsByCriteriaInput;
};


export type QueryPartTiersArgs = {
  input: GetPartsTiersByCriteriaInput;
};


export type QueryPartsByAclArgs = {
  input: GetPartAclByCriteriaInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryPermissionToArgs = {
  input: CheckPermissionInput;
};


export type QueryPreviewOpportunityEmailTemplateArgs = {
  input: OpportunityEmailTemplatePreviewInput;
};


export type QueryProjectsArgs = {
  input: GetProjectsByCriteriaInput;
};


export type QueryPublicVendorsArgs = {
  input: GetVendorsByCriteriaInput;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryQueryOpportunityStatsArgs = {
  input: OpportunityStatsQueryInput;
};


export type QueryRelatedSearchArgs = {
  input: GetRelatedSearchInput;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<CatalogPartsSortInput>;
};


export type QuerySavedMappingsArgs = {
  input: GetSavedMappingInput;
};


export type QuerySavedSearchArgs = {
  input: IdArgs;
};


export type QuerySavedSearchesArgs = {
  input: SavedSearchesInput;
};


export type QueryScenarioTierConversionBreakdownArgs = {
  scenarioId: Scalars['String']['input'];
  tierId: Scalars['String']['input'];
};


export type QuerySearchGmdnNamesArgs = {
  input: SearchGmdnNameInput;
};


export type QuerySearchGudidBrandsArgs = {
  input: SearchGudidBrandInput;
};


export type QuerySearchMasterCatalogArgs = {
  input: SearchMasterCatalogByPartNumberInput;
};


export type QuerySearchNormalizedManufacturersArgs = {
  input: SearchNormalizedManufacturersInput;
};


export type QuerySearchNormalizedSuppliersArgs = {
  searchText: Scalars['String']['input'];
};


export type QuerySearchUnspscTitlesArgs = {
  input: SearchUnspscInput;
};


export type QuerySimilarProductsArgs = {
  input: SimilarProductsInput;
  pagination?: InputMaybe<Pagination>;
};


export type QuerySimilarProductsManufacturersArgs = {
  input: SimilarProductsManufacturersInput;
};


export type QuerySourcingPlanArgs = {
  id: Scalars['String']['input'];
};


export type QuerySourcingPlanBasketBaseArgs = {
  id: Scalars['String']['input'];
  sourcingPlanId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySourcingPlanBasketBasesArgs = {
  input?: InputMaybe<SourcingPlanBasketsQueryInput>;
};


export type QuerySourcingPlansArgs = {
  input?: InputMaybe<GetSourcingPlansByCriteriaInput>;
};


export type QueryStudiesGetArgs = {
  input: StudiesGetInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryStudiesTransactionsGetArgs = {
  input: StudiesTransactionsGetInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryStudyLinkCountArgs = {
  input: StudyLinksQueryArgs;
};


export type QueryStudyLinksArgs = {
  input: StudyLinksQueryArgs;
};


export type QueryStudyTransactionsCountArgs = {
  input: StudiesTransactionsCountInput;
};


export type QuerySuggestedVendorsArgs = {
  input: GetSuggestedVendorsInput;
};


export type QuerySupplierOpportunitiesArgs = {
  input: SupplierOpportunitiesInput;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<Sort>>;
};


export type QuerySupplierOpportunityArgs = {
  input: IdArgs;
};


export type QuerySystemsArgs = {
  input: GetSystemByCriteriaInput;
};


export type QueryTierArgs = {
  id: Scalars['String']['input'];
};


export type QueryTiersArgs = {
  input: GetTiersByCriteriaInput;
  pagination: Pagination;
  sort?: InputMaybe<TiersSortInput>;
};


export type QueryUploadJobArgs = {
  id: IdArgs;
};


export type QueryUploadedFileKeyArgs = {
  input: UploadedFileKeyInput;
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserActivitiesArgs = {
  input: GetUserActivitiesByCriteriaInput;
};


export type QueryUserBasketsArgs = {
  input: IdArgs;
};


export type QueryUsersArgs = {
  input: GetUsersByCriteriaInput;
  pagination?: InputMaybe<Pagination>;
};


export type QueryVendorArgs = {
  input: IdArgs;
};


export type QueryVendorUsersArgs = {
  input: GetUsersByCriteriaInput;
};


export type QueryVendorsArgs = {
  input: GetVendorsByCriteriaInput;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueuedLinkProcess = {
  __typename?: 'QueuedLinkProcess';
  aiGudidOverwrite?: Maybe<Scalars['Boolean']['output']>;
  aiMedOverwrite?: Maybe<Scalars['Boolean']['output']>;
  aiStanOverwrite?: Maybe<Scalars['Boolean']['output']>;
  asp?: Maybe<Scalars['Float']['output']>;
  bamfGudidId?: Maybe<Scalars['String']['output']>;
  bamfMedId?: Maybe<Scalars['String']['output']>;
  bamfStanId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionOrg?: Maybe<Scalars['String']['output']>;
  gmdn?: Maybe<Array<Scalars['String']['output']>>;
  gudidCatalogNumber?: Maybe<Scalars['String']['output']>;
  gudidCompanyName?: Maybe<Scalars['String']['output']>;
  gudidDescription?: Maybe<Scalars['String']['output']>;
  gudidManufacturer?: Maybe<Scalars['String']['output']>;
  gudidVersionModelNumber?: Maybe<Scalars['String']['output']>;
  hide?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  ignoreManufacturer?: Maybe<Scalars['Boolean']['output']>;
  ignoreRow?: Maybe<Scalars['Boolean']['output']>;
  ignoreVendor?: Maybe<Scalars['Boolean']['output']>;
  isGudidApproved?: Maybe<Scalars['Boolean']['output']>;
  isGudidSupplierVen?: Maybe<Scalars['Boolean']['output']>;
  isMedApproved?: Maybe<Scalars['Boolean']['output']>;
  isMedSupplierVen?: Maybe<Scalars['Boolean']['output']>;
  isReprocessed?: Maybe<Scalars['Boolean']['output']>;
  isStanApproved?: Maybe<Scalars['Boolean']['output']>;
  isStanSupplierVen?: Maybe<Scalars['Boolean']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerGudidType?: Maybe<Scalars['String']['output']>;
  manufacturerMedType?: Maybe<Scalars['String']['output']>;
  manufacturerOrg?: Maybe<Scalars['String']['output']>;
  manufacturerStanType?: Maybe<Scalars['String']['output']>;
  matchGudidId?: Maybe<Scalars['String']['output']>;
  matchMedId?: Maybe<Scalars['String']['output']>;
  matchStanId?: Maybe<Scalars['String']['output']>;
  medDescription?: Maybe<Scalars['String']['output']>;
  medManufacturer?: Maybe<Scalars['String']['output']>;
  medMaterialCode?: Maybe<Scalars['String']['output']>;
  medSku?: Maybe<Scalars['String']['output']>;
  mfgitem?: Maybe<Scalars['String']['output']>;
  mfgitemOrg?: Maybe<Scalars['String']['output']>;
  priceTiers?: Maybe<Array<TransactionTier>>;
  qtypurchase?: Maybe<Scalars['Int']['output']>;
  stanManufacturer?: Maybe<Scalars['String']['output']>;
  stanManufacturerId?: Maybe<Scalars['String']['output']>;
  stanManufacturerOb?: Maybe<Manufacturer>;
  stanPartDescription?: Maybe<Scalars['String']['output']>;
  stanPartNumber?: Maybe<Scalars['String']['output']>;
  study: QueuedStudy;
  totalSpend?: Maybe<Scalars['Float']['output']>;
  tsId: Scalars['String']['output'];
  uom?: Maybe<Scalars['String']['output']>;
  uomconversion?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  vendorOrg?: Maybe<Scalars['String']['output']>;
  venitem?: Maybe<Scalars['String']['output']>;
  venitemOrg?: Maybe<Scalars['String']['output']>;
};

export type QueuedStudy = {
  __typename?: 'QueuedStudy';
  backupTrxJobId?: Maybe<Scalars['String']['output']>;
  backupTrxJobStatus?: Maybe<Scalars['String']['output']>;
  bamfMatchedCount?: Maybe<Scalars['Int']['output']>;
  checkConflictsJobId?: Maybe<Scalars['String']['output']>;
  checkConflictsJobStatus?: Maybe<Scalars['String']['output']>;
  committedStudyLink?: Maybe<Scalars['Boolean']['output']>;
  conflicts?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  customQuery?: Maybe<Scalars['String']['output']>;
  cxUserId?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  errorLog?: Maybe<Scalars['String']['output']>;
  facilityId?: Maybe<Scalars['String']['output']>;
  facilityName?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  groomedPercentage?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  level?: Maybe<Scalars['Int']['output']>;
  linkedTsId?: Maybe<Scalars['Int']['output']>;
  maxDatePurchase?: Maybe<Scalars['Date']['output']>;
  minDatePurchase?: Maybe<Scalars['Date']['output']>;
  recordCount?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<StudyStatusEnum>;
  totalUniqueTsId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Date']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type QueuedTransaction = {
  __typename?: 'QueuedTransaction';
  ageGroup?: Maybe<Scalars['String']['output']>;
  aiOverwrite?: Maybe<Scalars['Boolean']['output']>;
  bamfManufacturer?: Maybe<Scalars['String']['output']>;
  bamfPartDescription?: Maybe<Scalars['String']['output']>;
  bamfPartId?: Maybe<Scalars['String']['output']>;
  bamfPartNumber?: Maybe<Scalars['String']['output']>;
  blacklistManufacturer?: Maybe<Scalars['Boolean']['output']>;
  blacklistMfgItem?: Maybe<Scalars['Boolean']['output']>;
  blacklistVenItem?: Maybe<Scalars['Boolean']['output']>;
  blacklistVendor?: Maybe<Scalars['Boolean']['output']>;
  clusterId?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  curvoId?: Maybe<Scalars['Int']['output']>;
  customFieldsD1?: Maybe<Scalars['String']['output']>;
  customFieldsD2?: Maybe<Scalars['String']['output']>;
  customFieldsD3?: Maybe<Scalars['String']['output']>;
  customFieldsT1?: Maybe<Scalars['String']['output']>;
  customFieldsT2?: Maybe<Scalars['String']['output']>;
  customFieldsT3?: Maybe<Scalars['String']['output']>;
  datePurchase?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  extPrice?: Maybe<Scalars['Float']['output']>;
  facItem?: Maybe<Scalars['String']['output']>;
  facilityId?: Maybe<Scalars['String']['output']>;
  gicId?: Maybe<Scalars['String']['output']>;
  gicName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ignoreManufacturer?: Maybe<Scalars['Boolean']['output']>;
  ignoreVendor?: Maybe<Scalars['Boolean']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isSupplierVen?: Maybe<Scalars['Boolean']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  matchManufacturer?: Maybe<Manufacturer>;
  matchManufacturerId?: Maybe<Scalars['String']['output']>;
  matchPartId?: Maybe<Scalars['String']['output']>;
  materialId?: Maybe<Scalars['String']['output']>;
  materialName?: Maybe<Scalars['String']['output']>;
  mfgItem?: Maybe<Scalars['String']['output']>;
  nDescription?: Maybe<Scalars['String']['output']>;
  nManufacturer?: Maybe<Scalars['String']['output']>;
  nManufacturerCurvo?: Maybe<Scalars['String']['output']>;
  nMfgItem?: Maybe<Scalars['String']['output']>;
  nStrippedMfgItem?: Maybe<Scalars['String']['output']>;
  nStrippedVenItem?: Maybe<Scalars['String']['output']>;
  nVenItem?: Maybe<Scalars['String']['output']>;
  nVendor?: Maybe<Scalars['String']['output']>;
  nVendorCurvo?: Maybe<Scalars['String']['output']>;
  poNum?: Maybe<Scalars['String']['output']>;
  priceTiers?: Maybe<Array<TransactionTier>>;
  qtyPurchase?: Maybe<Scalars['Float']['output']>;
  segmentId?: Maybe<Scalars['String']['output']>;
  segmentName?: Maybe<Scalars['String']['output']>;
  sizeOne?: Maybe<Scalars['Float']['output']>;
  sizeThree?: Maybe<Scalars['Float']['output']>;
  sizeTwo?: Maybe<Scalars['Float']['output']>;
  study?: Maybe<QueuedStudy>;
  studyId?: Maybe<Scalars['Int']['output']>;
  surgeon?: Maybe<Scalars['String']['output']>;
  tsId?: Maybe<Scalars['String']['output']>;
  typeOneId?: Maybe<Scalars['String']['output']>;
  typeOneName?: Maybe<Scalars['String']['output']>;
  typeTwoId?: Maybe<Scalars['String']['output']>;
  typeTwoName?: Maybe<Scalars['String']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  unmatchedFields?: Maybe<Scalars['String']['output']>;
  uom?: Maybe<Scalars['String']['output']>;
  uomConversion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  venItem?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  waste?: Maybe<Scalars['Boolean']['output']>;
};

export type QuickCreateOrganizationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type QuicksightDashboardEmbedUrlType = {
  __typename?: 'QuicksightDashboardEmbedUrlType';
  EmbedUrl?: Maybe<Scalars['String']['output']>;
  cloakingUser?: Maybe<User>;
};

export type QuicksightDashboardItemType = {
  __typename?: 'QuicksightDashboardItemType';
  dashboardId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type QuicksightGroupType = {
  __typename?: 'QuicksightGroupType';
  GroupName?: Maybe<Scalars['String']['output']>;
};

export type RedeemOpportunityInput = {
  secretCode: Scalars['String']['input'];
};

export type RemoveBasketProductsInput = {
  basketId: Scalars['String']['input'];
  products: Array<BasketProductInput>;
};

export type RemoveBasketProductsResponse = {
  __typename?: 'RemoveBasketProductsResponse';
  updateMetricsJobId: Scalars['String']['output'];
};

export type RemoveBasketSmartRulesInput = {
  basketId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']>;
};

export type RemoveContractPartsInput = {
  tierIds: Array<Scalars['String']['input']>;
  tsIds: Array<Scalars['String']['input']>;
};

export type RemoveContractTierInput = {
  contractsId: Scalars['String']['input'];
  tiersId: Scalars['String']['input'];
};

export type RemoveNetworkMemberInput = {
  id: Scalars['String']['input'];
};

export type RemoveOrganizationInput = {
  organizationsId: Scalars['String']['input'];
  usersId: Scalars['String']['input'];
};

export type RemoveOrganizationTypeInput = {
  id: Scalars['String']['input'];
};

export type RemoveSourcingPlanBasketInput = {
  id: Scalars['String']['input'];
  sourcingPlanId: Scalars['String']['input'];
};

export type RemoveSourcingPlanBasketStakeholderInput = {
  /** `SourcingPlanBasketStakeholders` ID */
  id: Scalars['String']['input'];
  /** Force to remove associated `Contact`, cannot remove associated `User` */
  removeContact?: InputMaybe<Scalars['Boolean']['input']>;
  sourcingPlanId: Scalars['String']['input'];
};

export type RemoveSourcingPlanStakeholderInput = {
  /** `SourcingPlanStakeholder` ID */
  id: Scalars['String']['input'];
  /** Force to remove associated `Contact`, cannot remove associated `User` */
  removeContact?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RemoveUserInput = {
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type ReopenOfferInput = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  offerId: Scalars['String']['input'];
};

export type RequestCategoryConsultInput = {
  id: Scalars['String']['input'];
  request: CategoryConsultRequestInput;
};

export type ResendInviteInput = {
  invitingVendorId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
};

export type RetryOpportunityInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  offerId: Scalars['String']['input'];
};

export enum RuleSignEnum {
  Between = 'between',
  Equal = 'equal',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotEqual = 'notEqual'
}

export type S3UploadGeneratedUrl = {
  __typename?: 'S3UploadGeneratedUrl';
  hiddenInputs: Scalars['JSON']['output'];
  s3Key: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type SaveCrossReferenceMaps = {
  /** If this parameter is null, the System will try to use the System Generated List */
  crossReferenceListId?: InputMaybe<Scalars['String']['input']>;
  /** If true, overwrite other conflicts, otherwise throw an error */
  force?: InputMaybe<Scalars['Boolean']['input']>;
  pack?: InputMaybe<Array<CatalogPartPackInput>>;
  parts: Array<SaveCrossReferenceMapsPart>;
  vendorItemTsId?: InputMaybe<Scalars['String']['input']>;
};

/** The Part information, one of `masterId`, `tsId` or `raw` is required */
export type SaveCrossReferenceMapsPart = {
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Elasticsearch Id of the Part */
  masterId?: InputMaybe<Scalars['String']['input']>;
  noCross?: InputMaybe<OfferItemComment>;
  /** Raw Part information */
  raw?: InputMaybe<SaveCrossReferenceMapsPartRaw>;
  /** TsId of the input Part */
  tsId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CrossReferenceType>;
};

export type SaveCrossReferenceMapsPartRaw = {
  description?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  manufacturerItem?: InputMaybe<Scalars['String']['input']>;
};

export type SavedMapping = {
  __typename?: 'SavedMapping';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  mappedFields: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: MappingTypesEnum;
  updatedAt: Scalars['Date']['output'];
  user: User;
};

export type SavedMappingList = {
  __typename?: 'SavedMappingList';
  data: Array<SavedMapping>;
};

export type SavedSearch = {
  __typename?: 'SavedSearch';
  advancedSearch?: Maybe<Array<AdvancedSearchGroupObj>>;
  createdAt: Scalars['Date']['output'];
  grouping?: Maybe<Array<GroupField>>;
  id: Scalars['ID']['output'];
  isRecent: Scalars['Boolean']['output'];
  jsonAdvancedSearch: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pin: Scalars['Boolean']['output'];
  resultCount: Scalars['Int']['output'];
  searchFields: Array<ColumnAttributes>;
  searchText: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  user: User;
};

export type SavedSearchesInput = {
  isRecent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  pin?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ScenarioStats = {
  __typename?: 'ScenarioStats';
  basketTierStats: Array<ScenarioStatsTierBasket>;
  convertedItems: Scalars['Int']['output'];
  coveredItems: Scalars['Int']['output'];
  estimatedRebate: Scalars['Float']['output'];
  excludedItems: Scalars['Int']['output'];
  excludedSpend: Scalars['Float']['output'];
  pricingTarget: Scalars['Float']['output'];
  savings: Scalars['Float']['output'];
  tierStats: Array<ScenarioStatsTier>;
  totalIncumbentSpend: Scalars['Float']['output'];
  totalIncumbentUnits: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalProjectedSpend: Scalars['Float']['output'];
  totalSpendAfterRebate: Scalars['Float']['output'];
  uncoveredItems: Scalars['Int']['output'];
  validateTiers: Array<TierValidation>;
};

export type ScenarioStatsTier = {
  __typename?: 'ScenarioStatsTier';
  /** Total Spend from the opportunity for items which this tier covers */
  coveredIncumbentSpend: Scalars['Float']['output'];
  coveredItems: Scalars['Int']['output'];
  coveredUnits: Scalars['Int']['output'];
  excludedItems: Scalars['Int']['output'];
  pricingScore?: Maybe<Scalars['Float']['output']>;
  remainingCoveredUnits: Scalars['Float']['output'];
  remainingCurrentSpend: Scalars['Float']['output'];
  remainingIncumbentSavings: Scalars['Float']['output'];
  remainingIncumbentSpend: Scalars['Float']['output'];
  remainingIncumbentUnits: Scalars['Float']['output'];
  remainingTotalProjectedSavings: Scalars['Float']['output'];
  remainingTotalProjectedSpend: Scalars['Float']['output'];
  tierId: Scalars['String']['output'];
  /** Total offered price from this tier */
  totalProjectedSpend: Scalars['Float']['output'];
};

export type ScenarioStatsTierBasket = {
  __typename?: 'ScenarioStatsTierBasket';
  basketId: Scalars['String']['output'];
  /** Total Spend from the opportunity for items which this tier covers */
  coveredIncumbentSpend: Scalars['Float']['output'];
  coveredItems: Scalars['Int']['output'];
  coveredUnits: Scalars['Int']['output'];
  pricingScore?: Maybe<Scalars['Float']['output']>;
  tierId: Scalars['String']['output'];
  totalItems: Scalars['Float']['output'];
  /** Total offered price from this tier */
  totalProjectedSpend: Scalars['Float']['output'];
};

export type ScenarioTierConversionBreakdown = {
  __typename?: 'ScenarioTierConversionBreakdown';
  /** Current total spend in opportunity items */
  currentIncumbentSpend: Scalars['Float']['output'];
  /** Amount of savings compare to Projected Spend from opportunity items */
  projectedSavings: Scalars['Float']['output'];
  scenarioId: Scalars['String']['output'];
  tierId: Scalars['String']['output'];
  vendor: Scalars['String']['output'];
};

export enum ScenarioTierType {
  Converted = 'Converted',
  Covered = 'Covered',
  Incumbent = 'Incumbent'
}

export type SearchBasket = {
  __typename?: 'SearchBasket';
  name: Scalars['String']['output'];
  numItems: Scalars['Float']['output'];
};

export type SearchBasketResult = {
  __typename?: 'SearchBasketResult';
  hits: Array<SearchBasket>;
  totalHits: Scalars['Int']['output'];
};

export type SearchByUploadedSearchIdInput = {
  includeAggs?: InputMaybe<IncludeAggsEnum>;
  searchFields: Array<ColumnAttributesInput>;
  uploadedSearchId: Scalars['String']['input'];
};

export type SearchByUploadedSearchIdResult = {
  __typename?: 'SearchByUploadedSearchIdResult';
  hits: Array<AggregatedItemSpend>;
  searchFieldsFilterTerms: Array<SearchFieldFilterTerms>;
  totalHits: Scalars['Int']['output'];
  totalSpend: Scalars['Float']['output'];
};

export enum SearchField {
  ActualImpact = 'actualImpact',
  Benchmarks = 'benchmarks',
  ContactDate = 'contactDate',
  Contract = 'contract',
  CurrentPrice = 'currentPrice',
  CurvoCategory = 'curvoCategory',
  Description = 'description',
  EsDescription = 'esDescription',
  EsProductNormalized = 'esProductNormalized',
  EsSupplierNormalized = 'esSupplierNormalized',
  EsUoM = 'esUoM',
  EsVendorItemNormalized = 'esVendorItemNormalized',
  EsVendorNormalized = 'esVendorNormalized',
  Facility = 'facility',
  FacilityItem = 'facilityItem',
  FacilityName = 'facilityName',
  FdaCodeName = 'fdaCodeName',
  GicId = 'gicId',
  Gmdn = 'gmdn',
  Impact3m = 'impact3m',
  Impact12m = 'impact12m',
  L12mBenchmarks = 'l12mBenchmarks',
  LastPurchase = 'lastPurchase',
  LastPurchaseDate = 'lastPurchaseDate',
  Manufacturer = 'manufacturer',
  ManufacturerItem = 'manufacturerItem',
  MarketTrends = 'marketTrends',
  MasterMatched = 'masterMatched',
  MatchId = 'matchId',
  Month = 'month',
  Opportunities = 'opportunities',
  Organization = 'organization',
  OrganizationItem = 'organizationItem',
  PoNums = 'poNums',
  PricingTrends = 'pricingTrends',
  ToLastPurchaseDays = 'toLastPurchaseDays',
  TopOpportunity = 'topOpportunity',
  TotalQuantity = 'totalQuantity',
  TotalSpend = 'totalSpend',
  Trend = 'trend',
  TypeOne = 'typeOne',
  TypeTwo = 'typeTwo',
  Uom = 'uom',
  UomConversion = 'uomConversion',
  Utilization = 'utilization',
  Vendor = 'vendor',
  VendorItem = 'vendorItem',
  Venitem = 'venitem'
}

export type SearchFieldFilterTerms = {
  __typename?: 'SearchFieldFilterTerms';
  field: SearchField;
  terms: Array<SearchFieldSuggestion>;
};

export type SearchFieldSuggestion = {
  __typename?: 'SearchFieldSuggestion';
  term: Scalars['String']['output'];
  totalHits: Scalars['Int']['output'];
};

export type SearchGmdnNameInput = {
  search: Scalars['String']['input'];
};

export type SearchGudidBrandInput = {
  search: Scalars['String']['input'];
};

export type SearchMasterCatalogByPartNumberInput = {
  search: Scalars['String']['input'];
};

export type SearchNormalizedManufacturersInput = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  fullResponse: Scalars['Boolean']['output'];
  hits: Array<ItemSpend>;
  savedSearch?: Maybe<SavedSearch>;
  searchFieldsFilterTerms: Array<SearchFieldFilterTerms>;
  totalHits: Scalars['Int']['output'];
  totalSpend: Scalars['Float']['output'];
};

export type SearchUnspscInput = {
  search: Scalars['String']['input'];
};

export type SendBasketStakeholdersSurveyInput = {
  basketId: Scalars['String']['input'];
  /** `SourcingPlan`'s ID */
  sourcingPlanId: Scalars['String']['input'];
  /** Stakeholder IDs */
  stakeholderIds: Array<Scalars['String']['input']>;
};

export type SendSourcingPlanStakeholdersSurveyInput = {
  /** `SourcingPlan`'s ID */
  sourcingPlanId: Scalars['String']['input'];
  /** Stakeholder IDs */
  stakeholderIds: Array<Scalars['String']['input']>;
};

export type SetNormalizedManufacturerInput = {
  customerId: Scalars['String']['input'];
  normalizedManufacturer: Scalars['String']['input'];
};

export type SharedSupplierUser = {
  __typename?: 'SharedSupplierUser';
  appianId?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type SharedSupplierUserInfo = {
  __typename?: 'SharedSupplierUserInfo';
  type: AcTypeEnum;
  user: SharedSupplierUser;
};

export type SharedUser = {
  __typename?: 'SharedUser';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type SharedUserInfo = {
  __typename?: 'SharedUserInfo';
  type: AcTypeEnum;
  user: SharedUser;
  validationError?: Maybe<Scalars['String']['output']>;
};

export type SimilarProduct = {
  __typename?: 'SimilarProduct';
  benchmarks?: Maybe<Benchmark>;
  description?: Maybe<Scalars['String']['output']>;
  gicId?: Maybe<Scalars['String']['output']>;
  gudid?: Maybe<Scalars['String']['output']>;
  gudidBrand?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerItem?: Maybe<Scalars['String']['output']>;
  purchasedByFacilities: Array<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  submissionNumber?: Maybe<Scalars['String']['output']>;
  totalSpend?: Maybe<Scalars['Float']['output']>;
  uom?: Maybe<Scalars['String']['output']>;
  uomConversion?: Maybe<Scalars['String']['output']>;
};

export type SimilarProductResult = {
  __typename?: 'SimilarProductResult';
  hits: Array<SimilarProduct>;
  suggestions?: Maybe<Scalars['String']['output']>;
  totalHit: Scalars['Int']['output'];
};

export type SimilarProductsInput = {
  /** Extended use graph clustered 510k data to cross with not only direct 510k items but all 510k items connected in the cluster */
  extended?: InputMaybe<Scalars['Boolean']['input']>;
  gudid: Scalars['String']['input'];
  /** Manufacturer */
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  /** Use OpenAI to select the 10 best suggestions */
  useAI?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SimilarProductsManufacturersInput = {
  gudid: Scalars['String']['input'];
};

export type SingleOpportunityAttachmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  hide?: InputMaybe<Scalars['Boolean']['input']>;
  /** Attachment ID */
  id: Scalars['String']['input'];
  /** Inviting Vendor Ids, use this for Vendors specific attachments */
  invitingVendorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  s3Key?: InputMaybe<Scalars['String']['input']>;
};

export type SmartBasket = {
  __typename?: 'SmartBasket';
  basket: Basket;
  hits: Array<ItemSpend>;
  updateMetricsJobId?: Maybe<Scalars['String']['output']>;
};

export type SmartBasketConfig = {
  __typename?: 'SmartBasketConfig';
  allowOverwrite?: Maybe<Scalars['Boolean']['output']>;
  facilityIds?: Maybe<Array<Scalars['Int']['output']>>;
};

export type SmartBasketConfigInput = {
  allowOverwrite?: InputMaybe<Scalars['Boolean']['input']>;
  facilityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SmartBasketRule = {
  __typename?: 'SmartBasketRule';
  field: SearchField;
  secondValue?: Maybe<Scalars['String']['output']>;
  sign: RuleSignEnum;
  value: Scalars['String']['output'];
};

export type SmartBasketRuleInput = {
  field: SearchField;
  secondValue?: InputMaybe<Scalars['String']['input']>;
  sign: RuleSignEnum;
  value: Scalars['String']['input'];
};

export type SmartRule = {
  __typename?: 'SmartRule';
  basket?: Maybe<Basket>;
  basketId: Scalars['String']['output'];
  commodityId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  customer?: Maybe<Customer>;
  customerId: Scalars['String']['output'];
  gic?: Maybe<Gic>;
  gicTypeOneTypeTwo?: Maybe<Scalars['String']['output']>;
  gmdnName?: Maybe<Scalars['String']['output']>;
  gudidBrand?: Maybe<Scalars['String']['output']>;
  gudidId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  manufacturer?: Maybe<Scalars['String']['output']>;
  part?: Maybe<ItemRulePartDetail>;
  stanId?: Maybe<Scalars['String']['output']>;
  tsId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  typeOne?: Maybe<GicTypeOne>;
  typeTwo?: Maybe<GicTypeOne>;
  unspscClassTitle?: Maybe<Scalars['String']['output']>;
  unspscClassTitleDesc?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  vendor?: Maybe<Scalars['String']['output']>;
};

export type SnoozeSourcingPlanInput = {
  basketId: Scalars['String']['input'];
  /** Specify number of days that the Sourcing Plan should not be available, Unit: day, set it to null to disable snooze */
  snoozeDateCount?: InputMaybe<Scalars['Int']['input']>;
  sourcingPlanId?: InputMaybe<Scalars['String']['input']>;
};

export type Sort = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<Scalars['String']['input']>;
};

export type SourcingPlan = {
  __typename?: 'SourcingPlan';
  author?: Maybe<User>;
  authorId: Scalars['String']['output'];
  /** Get sourcing plan baskets by sourcing plan ID. */
  baskets: Array<SourcingPlanBasket>;
  customer?: Maybe<Customer>;
  customerId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  facilities?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['ID']['output'];
  isEditable?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  savingsGoal?: Maybe<Scalars['Float']['output']>;
  sharedUsers: Array<Acl>;
  /** Get all stakeholders by sourcing plan ID. */
  stakeholders: Array<SourcingPlanStakeHolder>;
  status: SourcingPlanStatus;
  support?: Maybe<SourcingPlanSupport>;
  targetCompletion?: Maybe<Scalars['Date']['output']>;
  targetStart?: Maybe<Scalars['Date']['output']>;
};


export type SourcingPlanBasketsArgs = {
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Represent a `SourcingPlan`'s `Category`. Basically a `Basket` wrapper with `targetSpend` and `targetSavings` */
export type SourcingPlanBasket = {
  __typename?: 'SourcingPlanBasket';
  basket: Basket;
  basketId: Scalars['String']['output'];
  creator?: Maybe<User>;
  customer?: Maybe<Customer>;
  /** Hide this Category in Recommendation list (Top Savings Categories) */
  hide?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  organization?: Maybe<Organization>;
  pin?: Maybe<Scalars['Boolean']['output']>;
  /** Snooze this Category in Recommendation list (Top Savings Categories) */
  snoozeToDate?: Maybe<Scalars['Date']['output']>;
  sourcingPlan?: Maybe<SourcingPlan>;
  sourcingPlanId?: Maybe<Scalars['String']['output']>;
  support: SourcingPlanBasketSupport;
  targetSavings?: Maybe<Scalars['Float']['output']>;
  targetSpend?: Maybe<Scalars['Float']['output']>;
  /** Total spend of basket's products in last 12 months */
  totalSpend?: Maybe<Scalars['Float']['output']>;
  type: SourcingPlanBasketType;
};

/** A wrapper Object type represents either a `Basket` or a `SourcingPlanBasket`, if it' a `Basket` then the `sourcingPlanBasket` field is null, if it' a `SourcingPlanBasket`, the `basket` field will contains basket information from `SourcingPlanBasket` */
export type SourcingPlanBasketBase = {
  __typename?: 'SourcingPlanBasketBase';
  /** Get the `active` `CategoryConsultRequest`. */
  activeConsultRequest?: Maybe<CategoryConsultRequest>;
  basket: Basket;
  inProgressSourcingPlan?: Maybe<SourcingPlan>;
  sourcingPlanBasket?: Maybe<SourcingPlanBasket>;
  /** Draft and InProgress Sourcing Plans are using this Basket */
  sourcingPlans?: Maybe<Array<SourcingPlan>>;
  stakeholders: Array<SourcingPlanBasketsStakeholders>;
};

export type SourcingPlanBasketSort = {
  direction?: OrderDirection;
  field: BasketSortEnum;
};

export enum SourcingPlanBasketSupport {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  Unverified = 'Unverified'
}

export enum SourcingPlanBasketType {
  Contract = 'contract',
  Curvo = 'curvo',
  Custom = 'custom'
}

export type SourcingPlanBasketsQueryInput = {
  basketType?: InputMaybe<BasketType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Set this to false to hide categories already enrolled in active sourcing plans */
  showInProgress?: InputMaybe<Scalars['Boolean']['input']>;
  showSnooze?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<SourcingPlanBasketSort>;
};

export type SourcingPlanBasketsStakeholders = {
  __typename?: 'SourcingPlanBasketsStakeholders';
  contact?: Maybe<Contact>;
  id: Scalars['ID']['output'];
  role: SourcingPlanStakeholderRole;
  sourcingPlanBasket?: Maybe<SourcingPlanBasket>;
  sourcingPlanBasketId: Scalars['String']['output'];
  support?: Maybe<Scalars['Boolean']['output']>;
  supportDate?: Maybe<Scalars['Date']['output']>;
  supportSurveyDate?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<User>;
};

export enum SourcingPlanOwnerEnum {
  Mine = 'Mine',
  Shared = 'Shared'
}

/** Contain stakeholder information of a `SourcingPlan`, the contact information comes from either a `Contact` or a `User`, not both */
export type SourcingPlanStakeHolder = {
  __typename?: 'SourcingPlanStakeHolder';
  /** Return the associated `Contact` if available, if not return associated `User` information instead */
  contact: Contact;
  id: Scalars['ID']['output'];
  role: SourcingPlanStakeholderRole;
  sourcingPlan?: Maybe<SourcingPlan>;
  sourcingPlanId?: Maybe<Scalars['String']['output']>;
  support?: Maybe<Scalars['Boolean']['output']>;
  supportDate?: Maybe<Scalars['Date']['output']>;
  supportSurveyDate?: Maybe<Scalars['Date']['output']>;
  /** Return the associated `User` if available */
  user?: Maybe<User>;
};

export enum SourcingPlanStakeholderRole {
  DepartmentLeader = 'departmentLeader',
  ExecutiveLeader = 'executiveLeader',
  Other = 'other',
  PhysicianLeader = 'physicianLeader',
  SubjectMatterExpert = 'subjectMatterExpert'
}

export enum SourcingPlanStatus {
  Archived = 'archived',
  Draft = 'draft',
  Finished = 'finished',
  InProgress = 'inProgress'
}

export type SourcingPlanSupplier = {
  __typename?: 'SourcingPlanSupplier';
  coverage?: Maybe<Scalars['Float']['output']>;
  estSavings: Scalars['Float']['output'];
  isIncumbent: Scalars['Boolean']['output'];
  isSuggested: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  numItems: Scalars['Float']['output'];
  spend: Scalars['Float']['output'];
  totalQty: Scalars['Float']['output'];
};

export type SourcingPlanSuppliers = {
  __typename?: 'SourcingPlanSuppliers';
  suppliers?: Maybe<Array<SourcingPlanSupplier>>;
  targetPercentile?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export enum SourcingPlanSupport {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  Unverified = 'Unverified'
}

export type SourcingPlanTopIncumbent = {
  __typename?: 'SourcingPlanTopIncumbent';
  name: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
  totalSpend: Scalars['Float']['output'];
};

export type SpendDataForVendorsResult = {
  __typename?: 'SpendDataForVendorsResult';
  case_id?: Maybe<Scalars['String']['output']>;
  construct_group?: Maybe<Scalars['String']['output']>;
  construct_name?: Maybe<Scalars['String']['output']>;
  curvo_category?: Maybe<Scalars['String']['output']>;
  customer_id: Scalars['String']['output'];
  customer_name: Scalars['String']['output'];
  facility_id: Scalars['String']['output'];
  facility_name: Scalars['String']['output'];
  gudid_brand_name?: Maybe<Scalars['String']['output']>;
  gudid_gmdn_name?: Maybe<Scalars['String']['output']>;
  iqo25?: Maybe<Scalars['Float']['output']>;
  iqo50?: Maybe<Scalars['Float']['output']>;
  iqo75?: Maybe<Scalars['Float']['output']>;
  iqo90?: Maybe<Scalars['Float']['output']>;
  manufacturer: Scalars['String']['output'];
  manufacturer_item: Scalars['String']['output'];
  procedure_type?: Maybe<Scalars['String']['output']>;
  qtypurchase: Scalars['Float']['output'];
  stan_brand?: Maybe<Scalars['String']['output']>;
  stan_gic_id?: Maybe<Scalars['String']['output']>;
  stan_gic_name?: Maybe<Scalars['String']['output']>;
  stan_product_line?: Maybe<Scalars['String']['output']>;
  stan_type_one?: Maybe<Scalars['String']['output']>;
  stan_type_two?: Maybe<Scalars['String']['output']>;
  surgeon?: Maybe<Scalars['String']['output']>;
  uom: Scalars['String']['output'];
  uom_conversion: Scalars['String']['output'];
};

export type SpendDateRange = {
  dateFrom: Scalars['Date']['input'];
  dateTo: Scalars['Date']['input'];
};

export type SpendDateRangeInput = {
  dateRange?: InputMaybe<BasketSpendDateRanges>;
  spendDateRange?: InputMaybe<SpendDateRange>;
};

export type SpendDateRangeSearch = {
  dateFrom: Scalars['Date']['input'];
  dateTo: Scalars['Date']['input'];
};

export type SpendDateRangeSearchInput = {
  spendDateRange?: InputMaybe<SpendDateRangeSearch>;
};

export type StanBaseData = {
  __typename?: 'StanBaseData';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type StanPart = {
  __typename?: 'StanPart';
  id?: Maybe<Scalars['String']['output']>;
  partName?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
};

export type StartSourcingPlanInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  facilities?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  savingsGoal: Scalars['Float']['input'];
  sharedUsers?: InputMaybe<Array<BaseAclRecordInput>>;
  targetCompletion: Scalars['Date']['input'];
  targetStart: Scalars['Date']['input'];
};

export type StudiesGetInput = {
  facilityId?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<Array<StudyFileTypeEnum>>;
  importedFrom?: InputMaybe<Scalars['Date']['input']>;
  importedTo?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Array<StudyStatusEnum>>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type StudiesGetResult = {
  __typename?: 'StudiesGetResult';
  hits: Array<QueuedStudy>;
  totalHits: Scalars['Int']['output'];
};

export type StudiesTransactionsCountInput = {
  masterGroup?: InputMaybe<ConstructMasterGroup>;
  studyId: Scalars['Int']['input'];
};

export type StudiesTransactionsGetInput = {
  datePurchaseFrom?: InputMaybe<Scalars['Date']['input']>;
  datePurchaseTo?: InputMaybe<Scalars['Date']['input']>;
  manufacturerType?: InputMaybe<ManufacturerType>;
  poNum?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<StudyTransactionState>;
  studyId: Scalars['Int']['input'];
};

export enum StudyFileTypeEnum {
  Contract = 'Contract',
  Epic = 'EPIC',
  Other = 'Other',
  XRef = 'XRef'
}

/** Study Catalog Enum */
export enum StudyLinkCatalogEnum {
  Gudid = 'gudid',
  Medline = 'medline',
  Stan = 'stan'
}

export type StudyLinksQueryArgs = {
  catalog?: InputMaybe<StudyLinkCatalogEnum>;
  first?: InputMaybe<Scalars['Int']['input']>;
  manufacturerType?: InputMaybe<ManufacturerType>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<StudyTransactionState>;
  studyId: Scalars['Int']['input'];
};

export enum StudyStatusEnum {
  Archived = 'Archived',
  Archiving = 'Archiving',
  AutoLinking = 'AutoLinking',
  AutoMatching = 'AutoMatching',
  CaseReview = 'CaseReview',
  Committing = 'Committing',
  CommittingCase = 'CommittingCase',
  Completed = 'Completed',
  Failed = 'Failed',
  Grooming = 'Grooming',
  Linking = 'Linking',
  New = 'New',
  QueuedAutoMatching = 'QueuedAutoMatching',
  QueuedForCommitting = 'QueuedForCommitting',
  QueuedForGrooming2 = 'QueuedForGrooming2',
  QueuedForPushingToGrooming1 = 'QueuedForPushingToGrooming1',
  Submitted = 'Submitted'
}

/** Study Transaction processing state */
export enum StudyTransactionState {
  BamfMatching = 'bamfMatching',
  BlacklistStrippedItem = 'blacklistStrippedItem',
  BlacklistSupplier = 'blacklistSupplier',
  CaseReview = 'caseReview',
  CaseReviewDetail = 'caseReviewDetail',
  Conflict = 'conflict',
  FinalReview = 'finalReview',
  Hidden = 'hidden',
  IgnoreRow = 'ignoreRow',
  IgnoreSupplier = 'ignoreSupplier',
  SupplierMatching = 'supplierMatching',
  TwinApproved = 'twinApproved',
  TwinMatch = 'twinMatch'
}

export type SubmitContractInput = {
  id: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  exportedSearch: ExportEventResultType;
  studyUpdated: QueuedStudy;
  updatedJob: UploadJob;
  uploadSearch: DoUploadSearchResult;
  uploadedBasket: ProcessedBasketResult;
};


export type SubscriptionExportedSearchArgs = {
  id: Scalars['String']['input'];
};


export type SubscriptionStudyUpdatedArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionUpdatedJobArgs = {
  id: Scalars['String']['input'];
};


export type SubscriptionUploadSearchArgs = {
  id: Scalars['String']['input'];
};


export type SubscriptionUploadedBasketArgs = {
  id: Scalars['String']['input'];
};

export type SuggestedVendorsResult = {
  __typename?: 'SuggestedVendorsResult';
  hits: Array<CustomerView>;
  totalHits: Scalars['Int']['output'];
};

/** Cross Reference Maps grouped by a vendor Product TsId (`xrefTsId`) */
export type SupplierCrossReference = {
  __typename?: 'SupplierCrossReference';
  /** No-Cross type */
  comment?: Maybe<OfferItemComment>;
  crossReferenceListId: Scalars['String']['output'];
  crossStatus: Scalars['Boolean']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  /** List of mappings, might be sliced if `mappingsLimit` is set */
  mappings: Array<CrossReferenceMap>;
  /** Mappings count in case `mappingsLimit` is set and not returning all mappings */
  mappingsCount: Scalars['Int']['output'];
  /** No-Cross comment */
  noCrossComment?: Maybe<Scalars['String']['output']>;
  xrefCatalogPart?: Maybe<CatalogPart>;
  xrefTsId?: Maybe<Scalars['String']['output']>;
};

export type SupplierCrossReferenceResult = {
  __typename?: 'SupplierCrossReferenceResult';
  hits: Array<SupplierCrossReference>;
  totalHits: Scalars['Int']['output'];
};

export enum SupplierCrossReferenceSearchField {
  CrossDescription = 'crossDescription',
  CrossManufacturer = 'crossManufacturer',
  CrossManufacturerItem = 'crossManufacturerItem',
  Description = 'description',
  Manufacturer = 'manufacturer',
  ManufacturerItem = 'manufacturerItem'
}

export type SupplierCrossReferenceSearchOnField = {
  searchOnField: SupplierCrossReferenceSearchField;
  searchText: Scalars['String']['input'];
};

export enum SupplierCrossReferenceSortField {
  Category = 'category',
  CrossCategory = 'crossCategory',
  CrossDescription = 'crossDescription',
  CrossGicId = 'crossGicId',
  CrossGicName = 'crossGicName',
  CrossGmdn = 'crossGmdn',
  CrossManufacturer = 'crossManufacturer',
  CrossManufacturerItem = 'crossManufacturerItem',
  CrossMaterialId = 'crossMaterialId',
  CrossSizeOne = 'crossSizeOne',
  CrossSizeThree = 'crossSizeThree',
  CrossSizeTwo = 'crossSizeTwo',
  CrossSubCategory = 'crossSubCategory',
  CrossTypeOneName = 'crossTypeOneName',
  CrossTypeTwoName = 'crossTypeTwoName',
  Description = 'description',
  GicId = 'gicId',
  GicName = 'gicName',
  Gmdn = 'gmdn',
  Manufacturer = 'manufacturer',
  ManufacturerItem = 'manufacturerItem',
  MaterialId = 'materialId',
  SizeOne = 'sizeOne',
  SizeThree = 'sizeThree',
  SizeTwo = 'sizeTwo',
  SubCategory = 'subCategory',
  Type = 'type',
  TypeOneName = 'typeOneName',
  TypeTwoName = 'typeTwoName',
  UpdatedAt = 'updatedAt'
}

export type SupplierCrossReferencesSortInput = {
  direction?: InputMaybe<OrderDirection>;
  field: SupplierCrossReferenceSortField;
};

/** In case the hospital is provided by the customer (i.e., the hospital shares the same customerId), the customerId field is an empty string */
export type SupplierHospital = {
  __typename?: 'SupplierHospital';
  customerId: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<OpportunityProvider>;
};

/** A hospital could consist of both customerId and name or just name (in which case customerId is an empty string) */
export type SupplierHospitalInput = {
  customerId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SupplierOpportunitiesInput = {
  hospital?: InputMaybe<SupplierHospitalInput>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isCrossRequest?: InputMaybe<Scalars['Boolean']['input']>;
  isManual?: InputMaybe<Scalars['Boolean']['input']>;
  isMissedDeadline?: InputMaybe<Scalars['Boolean']['input']>;
  opportunityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  opportunityStatus?: InputMaybe<OppotunityStatusEnum>;
  requestNumber?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  supplierStatus?: InputMaybe<OpportunitySupplierStatusEnum>;
};

export type SupplierOpportunitiesResult = {
  __typename?: 'SupplierOpportunitiesResult';
  hits: Array<SupplierOpportunity>;
  totalHits: Scalars['Float']['output'];
};

export type SupplierOpportunity = {
  __typename?: 'SupplierOpportunity';
  acl: Array<SharedSupplierUserInfo>;
  adminSupplierStatus: OpportunitySupplierStatusEnum;
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  export: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isArchivedOpportunity: Scalars['Boolean']['output'];
  isManual: Scalars['Boolean']['output'];
  keyContact?: Maybe<OpportunityKeyContact>;
  name?: Maybe<Scalars['String']['output']>;
  numberSKU?: Maybe<Scalars['Int']['output']>;
  offers: OfferResult;
  opportunityAttachment: OpportunityAttachment;
  opportunityAttachments: Array<OpportunityAttachment>;
  parentStatus?: Maybe<OppotunityStatusEnum>;
  provider?: Maybe<OpportunityProvider>;
  status: OfferStatusEnum;
  supplierStatus: OpportunitySupplierStatusEnum;
  uniqueNumberSku: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
  vendorCustomerId: Scalars['String']['output'];
};


export type SupplierOpportunityAdminSupplierStatusArgs = {
  customerId: Scalars['String']['input'];
};


export type SupplierOpportunityExportArgs = {
  selectedFields: Array<OfferItemExportsFieldEnum>;
};


export type SupplierOpportunityOffersArgs = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type SupplierOpportunityOpportunityAttachmentArgs = {
  id: Scalars['String']['input'];
};


export type SupplierOpportunityOpportunityAttachmentsArgs = {
  uploadedBy?: InputMaybe<AttachmentUploadedFrom>;
};

export enum SupplierSectionEnum {
  Catalog = 'catalog',
  Crosses = 'crosses',
  Offer = 'offer'
}

export type SupportTicketInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SyncCurvoNormalization = {
  deleted: Scalars['Boolean']['input'];
  inputSupplier: Scalars['String']['input'];
  normalizedCurvo: Scalars['String']['input'];
};

export type System = {
  __typename?: 'System';
  createdAt: Scalars['Date']['output'];
  creator: User;
  customer: Customer;
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  parent?: Maybe<System>;
  updatedAt: Scalars['Date']['output'];
};

export type Tier = {
  __typename?: 'Tier';
  booleanOperator?: Maybe<TierRuleBooleanOperator>;
  catalogPartTiers: CatalogPartTierResult;
  catalogPartTiersPaginated: CatalogPartTierResult;
  children?: Maybe<Array<Tier>>;
  contracts: Array<ContractTier>;
  createdAt: Scalars['Date']['output'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountMetric?: Maybe<TierRuleMetricType>;
  discountPeriod?: Maybe<TierRulePeriodType>;
  discountValue?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  offer?: Maybe<Offer>;
  offerId?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Tier>;
  parentId?: Maybe<Scalars['String']['output']>;
  readableRules: Scalars['String']['output'];
  rebate?: Maybe<TierRule>;
  rules?: Maybe<Array<TierRule>>;
  updatedAt: Scalars['Date']['output'];
};


export type TierCatalogPartTiersArgs = {
  pagination: Pagination;
};


export type TierCatalogPartTiersPaginatedArgs = {
  input?: InputMaybe<CatalogPartTiersFilterInput>;
  pagination: Pagination;
  sorts?: InputMaybe<Array<CatalogPartsSortInput>>;
};


export type TierReadableRulesArgs = {
  skipParent?: InputMaybe<Scalars['Boolean']['input']>;
};


export type TierRulesArgs = {
  skipParent?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TierFilterOperatorEnum {
  Between = 'between',
  Equal = 'equal',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  IsEmpty = 'isEmpty',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual'
}

export type TierInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type TierMapping = {
  field: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type TierPriceFilterInput = {
  operator: TierFilterOperatorEnum;
  priceFirst?: InputMaybe<Scalars['Float']['input']>;
  priceSecond?: InputMaybe<Scalars['Float']['input']>;
  tierId: Scalars['String']['input'];
};

export type TierRule = {
  __typename?: 'TierRule';
  basketIds?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['Date']['output'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  metricType: TierRuleMetricType;
  name?: Maybe<Scalars['String']['output']>;
  operator: TierRuleOperator;
  periodType: TierRulePeriodType;
  ruleType: TierRuleType;
  scope?: Maybe<TierRuleScope>;
  tier?: Maybe<Tier>;
  tierId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  /** value for the requirement, which can be a range, can be paired with valueSecond */
  valueFirst: Scalars['Float']['output'];
  /** value for the requirement, which can be a range, paired with valueFirst */
  valueSecond?: Maybe<Scalars['Float']['output']>;
};

export enum TierRuleBooleanOperator {
  And = 'and',
  Or = 'or'
}

export type TierRuleInput = {
  booleanOperator?: TierRuleBooleanOperator;
  rules: Array<BaseTierRuleInput>;
};

export enum TierRuleMetricType {
  Dollar = 'dollar',
  DollarsPerUnit = 'dollarsPerUnit',
  PercentByDollar = 'percentByDollar',
  PercentByUnit = 'percentByUnit',
  PercentPerUnit = 'percentPerUnit',
  Unit = 'unit'
}

export enum TierRuleOperator {
  Between = 'between',
  Equal = 'equal',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual'
}

export enum TierRulePeriodType {
  Annually = 'annually',
  Quarterly = 'quarterly'
}

export enum TierRuleScope {
  Corporate = 'corporate',
  Facility = 'facility',
  TopParent = 'topParent'
}

export enum TierRuleType {
  MarketShare = 'marketShare',
  PurchaseVolume = 'purchaseVolume',
  Rebate = 'rebate'
}

export type TierRuleValidation = {
  __typename?: 'TierRuleValidation';
  message: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export enum TierSortFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type TierStats = {
  __typename?: 'TierStats';
  lastUpdatedAt?: Maybe<Scalars['Date']['output']>;
};

export type TierSummary = {
  __typename?: 'TierSummary';
  itemCoverage: Scalars['Float']['output'];
  tier: Tier;
  totalIncumbentSpend: Scalars['Float']['output'];
  totalProjectedSpend: Scalars['Float']['output'];
};

export type TierValidation = {
  __typename?: 'TierValidation';
  message: Scalars['String']['output'];
  tier: Tier;
  validation: Scalars['Boolean']['output'];
};

export type TiersResult = {
  __typename?: 'TiersResult';
  hits: Array<Tier>;
  totalHits: Scalars['Int']['output'];
};

export type TiersSortInput = {
  direction?: InputMaybe<OrderDirection>;
  field: TierSortFieldEnum;
};

export type ToggleUserActivationInput = {
  userId: Scalars['String']['input'];
};

export type TransactionTier = {
  __typename?: 'TransactionTier';
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type Trend = {
  __typename?: 'Trend';
  marketTrend?: Maybe<MarketTrend>;
  metrics?: Maybe<TrendMetrics>;
  pricing: Array<TrendPoint>;
  spend: Array<TrendPoint>;
  utilization: Array<TrendPoint>;
};

export type TrendMetrics = {
  __typename?: 'TrendMetrics';
  priceDelta: Scalars['Float']['output'];
  priceDeltaPercentage: Scalars['Float']['output'];
};

export type TrendPoint = {
  __typename?: 'TrendPoint';
  x: Scalars['String']['output'];
  y: Scalars['Float']['output'];
};

export type Unspsc = {
  __typename?: 'Unspsc';
  class: Scalars['String']['output'];
  classTitle: Scalars['String']['output'];
};

export type UpdateBasketSmartRulesConfigInput = {
  id: Scalars['ID']['input'];
  smartBasketConfig?: InputMaybe<SmartBasketConfigInput>;
};

export type UpdateCatalogPartInput = {
  commodityId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  gudId?: InputMaybe<Scalars['String']['input']>;
  linkedTsId?: InputMaybe<Scalars['String']['input']>;
  partTiers?: InputMaybe<Array<PriceTierInput>>;
  stanId?: InputMaybe<Scalars['String']['input']>;
  tsId: Scalars['String']['input'];
};

export type UpdateCatalogPartWithMasterTsIdInput = {
  masterCatalogTsId?: InputMaybe<Scalars['String']['input']>;
  tsId: Scalars['String']['input'];
};

export type UpdateCloakInput = {
  cloakUserId: Scalars['String']['input'];
};

export type UpdateContractAttachmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateContractBasketsInput = {
  basketIds: Array<Scalars['String']['input']>;
  contractId: Scalars['String']['input'];
};

export type UpdateContractInput = {
  activeDue: Scalars['Date']['input'];
  activeSince: Scalars['Date']['input'];
  category?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sourcingOfficer?: InputMaybe<Scalars['String']['input']>;
  subCategories?: InputMaybe<Array<Scalars['String']['input']>>;
  supplier: Scalars['String']['input'];
};

export type UpdateContractMembersInput = {
  contractId: Scalars['String']['input'];
  members: Array<ContractMemberInput>;
  tierIds: Array<Scalars['String']['input']>;
};

export type UpdateContractPartInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  manufacturer: Scalars['String']['input'];
  mfgitem: Scalars['String']['input'];
  partTiers?: InputMaybe<Array<PartTierInput>>;
  rowId: Scalars['String']['input'];
  subCategory?: InputMaybe<Scalars['String']['input']>;
  tsId: Scalars['String']['input'];
  vendor?: InputMaybe<Scalars['String']['input']>;
  venitem?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContractPartsInput = {
  parts: Array<UpdateContractPartInput>;
  tierIds: Array<Scalars['String']['input']>;
};

export type UpdateContractPartsOutput = {
  __typename?: 'UpdateContractPartsOutput';
  category?: Maybe<Scalars['String']['output']>;
  part: Part;
  rowId: Scalars['String']['output'];
  subCategory?: Maybe<Scalars['String']['output']>;
};

export type UpdateCrossReferenceListInput = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  geoCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  maxUser?: InputMaybe<Scalars['Int']['input']>;
  medicareId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  vendorFeatures?: InputMaybe<VendorFeaturesInput>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDashboardInput = {
  angleTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  arnValue?: InputMaybe<Scalars['String']['input']>;
  /** Valid CSS color value */
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  basketList?: InputMaybe<Array<Scalars['String']['input']>>;
  contractList?: InputMaybe<Array<Scalars['String']['input']>>;
  dashboardId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of Ant Design Icons e.g DashboardOutlined */
  icon?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateHistoriesInput = {
  id: Scalars['String']['input'];
  priceActivationDate?: InputMaybe<Scalars['Date']['input']>;
  priceDeactivationDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateMeInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedIn?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** Re-authentication token, required for updating sensitivity information like email */
  token?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMessageTemplateInput = {
  id: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNetworkMemberRoleInput = {
  networkId: Scalars['String']['input'];
  role: UserNetworkRoleEnum;
  userId: Scalars['String']['input'];
};

export type UpdateOfferInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OfferStatusEnum>;
};

export type UpdateOfferResult = {
  __typename?: 'UpdateOfferResult';
  offer: Offer;
  validationError?: Maybe<Scalars['String']['output']>;
  validationPassed: Scalars['Boolean']['output'];
};

export type UpdateOfferTierInput = {
  add?: InputMaybe<Array<OfferTierInput>>;
  id: Scalars['String']['input'];
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateOfferUploadKeyContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOfferUploadProviderInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOpportunityInput = {
  attachmentsUpdate?: InputMaybe<Array<OpportunityAttachmentInput>>;
  basketIds?: InputMaybe<Array<Scalars['String']['input']>>;
  basketSpendDateRange?: InputMaybe<BasketSpendDateRangeInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  estimateDueDate?: InputMaybe<Scalars['Date']['input']>;
  estimateStartDate?: InputMaybe<Scalars['Date']['input']>;
  goal?: InputMaybe<Scalars['Float']['input']>;
  goalThresholdMax?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  keyContactId?: InputMaybe<Scalars['String']['input']>;
  metrics?: InputMaybe<MetricsVendorType>;
  name?: InputMaybe<Scalars['String']['input']>;
  opportunityEmailSubjectTemplate?: InputMaybe<Scalars['String']['input']>;
  opportunityEmailTemplate?: InputMaybe<Scalars['String']['input']>;
  sharedUsers?: InputMaybe<Array<BaseAclRecordInput>>;
  skipZeroQuantityItems?: InputMaybe<Scalars['Boolean']['input']>;
  vendors?: InputMaybe<Array<OpportunityInviteBaseInput>>;
};

export type UpdateOpportunityItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  facilityId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  manufacturer: Scalars['String']['input'];
  manufacturerItem: Scalars['String']['input'];
  pricingTarget?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  uom: Scalars['String']['input'];
  uomConversion: Scalars['Int']['input'];
  vendor?: InputMaybe<Scalars['String']['input']>;
  vendorItem?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  customerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  facilityId?: InputMaybe<Scalars['Int']['input']>;
  geoCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  medicareId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationTypeId?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSavedMappingInput = {
  id: Scalars['String']['input'];
  mappedFields?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateSavedSearchInput = {
  grouping?: InputMaybe<Array<GroupField>>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  pin?: InputMaybe<Scalars['Boolean']['input']>;
  searchFields?: InputMaybe<Array<ColumnAttributesInput>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateScenarioByOfferInput = {
  award?: InputMaybe<Scalars['Boolean']['input']>;
  exclude?: InputMaybe<Scalars['Boolean']['input']>;
  /** A.k.a uncovered */
  remaining: Scalars['Boolean']['input'];
  scenarioId: Scalars['String']['input'];
  tierId: Scalars['String']['input'];
  type: ScenarioTierType;
  /** Scenario Opportunity item vendor */
  vendor?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateScenarioCommitmentInput = {
  enableCommitment: Scalars['Boolean']['input'];
  scenarioId: Scalars['String']['input'];
};

export type UpdateSmartBasketInput = {
  addTsIds?: InputMaybe<Array<BasketProductInput>>;
  id: Scalars['ID']['input'];
  isSearchAll?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  rules?: InputMaybe<Array<SmartBasketRuleInput>>;
  smartBasketConfig?: InputMaybe<SmartBasketConfigInput>;
};

export type UpdateSourcingPlanBasketInput = {
  /** SourcingPlanBasket's ID */
  id: Scalars['String']['input'];
  targetSavings?: InputMaybe<Scalars['Float']['input']>;
  targetSpend?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateSourcingPlanBasketStakeholderInput = {
  contactId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  role: SourcingPlanStakeholderRole;
  sourcingPlanId: Scalars['String']['input'];
  support?: InputMaybe<Scalars['Boolean']['input']>;
  supportDate?: InputMaybe<Scalars['Date']['input']>;
  supportSurveyDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateSourcingPlanInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  facilities?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  savingsGoal: Scalars['Float']['input'];
  sharedUsers?: InputMaybe<Array<BaseAclRecordInput>>;
  targetCompletion: Scalars['Date']['input'];
  targetStart: Scalars['Date']['input'];
};

export type UpdateSourcingPlanStakeholderInput = {
  contactId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  role: SourcingPlanStakeholderRole;
  support?: InputMaybe<Scalars['Boolean']['input']>;
  supportDate?: InputMaybe<Scalars['Date']['input']>;
  supportSurveyDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateSupplierOpportunityAclInput = {
  appianIds: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type UpdateSupplierOpportunityAttachmentsInput = {
  attachmentsUpdate: Array<OpportunityAttachmentInput>;
  id: Scalars['String']['input'];
};

export type UpdateSupplierOpportunityInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['String']['input'];
  keyContact?: InputMaybe<UpdateOfferUploadKeyContactInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<UpdateOfferUploadProviderInput>;
};

export type UpdateTierInput = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateTierRuleInput = {
  booleanOperator?: TierRuleBooleanOperator;
  rules: Array<BaseTierRuleInput>;
  tierId: Scalars['String']['input'];
};

export type UpdateUserInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isTopPowerUser?: InputMaybe<Scalars['Boolean']['input']>;
  isTopUser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<Array<OrganizationPermission>>;
  permissions?: InputMaybe<Array<UserPermissionFeature>>;
  qsGroups?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateVendorInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  geoCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  maxUser?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  vendorFeatures?: InputMaybe<VendorFeaturesInput>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVendorUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type UploadBasketInput = {
  data: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type UploadBasketResult = {
  __typename?: 'UploadBasketResult';
  data: Array<MapField>;
  s3Key: Scalars['String']['output'];
};

export type UploadCatalogInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  data: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  mapping: UploadCatalogMapping;
  selectedExcelSheet?: InputMaybe<Scalars['String']['input']>;
};

export type UploadCatalogMapping = {
  category?: InputMaybe<FieldLiteralMapping>;
  description?: InputMaybe<FieldLiteralMapping>;
  gtin?: InputMaybe<FieldLiteralMapping>;
  manufacturer: FieldLiteralMapping;
  manufacturerItem: FieldLiteralMapping;
  subCategory?: InputMaybe<FieldLiteralMapping>;
  tiers?: InputMaybe<Array<TierMapping>>;
  uom?: InputMaybe<FieldLiteralMapping>;
  uomConversion?: InputMaybe<FieldLiteralMapping>;
  vendor?: InputMaybe<FieldLiteralMapping>;
  vendorItem?: InputMaybe<FieldLiteralMapping>;
};

export type UploadContractInput = {
  data: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type UploadContractResult = {
  __typename?: 'UploadContractResult';
  data: Array<MapField>;
  s3Key: Scalars['String']['output'];
};

export type UploadCrossRefInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  data: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  mapping: UploadCrossRefMapping;
  /** New cross reference list's name */
  name: Scalars['String']['input'];
  selectedExcelSheet?: InputMaybe<Scalars['String']['input']>;
};

export type UploadCrossRefMapping = {
  competitorDescription?: InputMaybe<FieldLiteralMapping>;
  competitorGtin?: InputMaybe<FieldLiteralMapping>;
  competitorManufacturer: FieldLiteralMapping;
  competitorManufacturerItem: FieldLiteralMapping;
  competitorVendor?: InputMaybe<FieldLiteralMapping>;
  competitorVendorItem?: InputMaybe<FieldLiteralMapping>;
  description?: InputMaybe<FieldLiteralMapping>;
  gtin?: InputMaybe<FieldLiteralMapping>;
  manufacturer: FieldLiteralMapping;
  manufacturerItem: FieldLiteralMapping;
  matchType?: InputMaybe<FieldLiteralMapping>;
  vendor?: InputMaybe<FieldLiteralMapping>;
  vendorItem?: InputMaybe<FieldLiteralMapping>;
};

export type UploadHistories = {
  __typename?: 'UploadHistories';
  data: Array<UploadJob>;
};

export type UploadInput = {
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
};

export type UploadJob = {
  __typename?: 'UploadJob';
  callbackUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currentProgress?: Maybe<JobProgressEvent>;
  errors?: Maybe<Scalars['JSON']['output']>;
  hasErrors: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** id of EC2 instance that processes the job */
  instance?: Maybe<Scalars['String']['output']>;
  jobType: Scalars['String']['output'];
  mappings?: Maybe<Scalars['JSON']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  overrideCustomer?: Maybe<Customer>;
  parameters?: Maybe<Scalars['JSON']['output']>;
  queryString: Scalars['String']['output'];
  result_stats?: Maybe<Scalars['JSON']['output']>;
  s3Key?: Maybe<Scalars['String']['output']>;
  selectedExcelSheet?: Maybe<Scalars['String']['output']>;
  status: UploadJobStatusEnum;
  updatedAt: Scalars['Date']['output'];
  uploaded_search_id?: Maybe<Scalars['String']['output']>;
  user: User;
};

export enum UploadJobStatusEnum {
  Failed = 'failed',
  Finished = 'finished',
  Processing = 'processing',
  Queue = 'queue'
}

export type UploadOfferMapping = {
  conversion?: InputMaybe<FieldLiteralMapping>;
  description?: InputMaybe<FieldLiteralMapping>;
  manufacturer: FieldLiteralMapping;
  manufacturerItem: FieldLiteralMapping;
  quantity?: InputMaybe<FieldLiteralMapping>;
  uom?: InputMaybe<FieldLiteralMapping>;
  vendor?: InputMaybe<FieldLiteralMapping>;
  vendorItem?: InputMaybe<FieldLiteralMapping>;
};

export type UploadSearchFileInput = {
  data: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type UploadSearchResult = {
  __typename?: 'UploadSearchResult';
  data: Array<MapField>;
  s3Key: Scalars['String']['output'];
};

export type UploadedFileKeyInput = {
  uploadedSearchId: Scalars['String']['input'];
};

export type UploadedFileKeyResult = {
  __typename?: 'UploadedFileKeyResult';
  s3Key: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  appianId?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  cloakCustomerId?: Maybe<Scalars['String']['output']>;
  cloakOrganizationIds?: Maybe<Array<Scalars['String']['output']>>;
  cloakUserId?: Maybe<Scalars['String']['output']>;
  cognitoId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  externalAuthConnectionId?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isCurvoAdmin: Scalars['Boolean']['output'];
  isManageable: Scalars['Boolean']['output'];
  isSupplierUser: Scalars['Boolean']['output'];
  isTopPowerUser: Scalars['Boolean']['output'];
  isTopUser: Scalars['Boolean']['output'];
  lastActionAt?: Maybe<Scalars['Date']['output']>;
  lastName: Scalars['String']['output'];
  linkedIn?: Maybe<Scalars['String']['output']>;
  organizations: Array<Organization>;
  permissions: UserAbilities;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  powerUserList: Array<PowerUserListItem>;
  projects: Array<Project>;
  provider?: Maybe<Scalars['String']['output']>;
  qsGroups?: Maybe<Array<Scalars['String']['output']>>;
  quicksightId?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  status: UserStatus;
  title?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  userOrganizations: Array<UserOrganization>;
  username: Scalars['String']['output'];
};

/** For CASL, contains 2 lists of user abilities, can and cannot */
export type UserAbilities = {
  __typename?: 'UserAbilities';
  can: Array<UserCaslAbility>;
  cannot: Array<UserCaslAbility>;
};

export type UserActivity = {
  __typename?: 'UserActivity';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  user: User;
};

/** For CASL, user ability */
export type UserCaslAbility = {
  __typename?: 'UserCASLAbility';
  permissionType: Scalars['String']['output'];
  value: UserPermissionFeature;
};

export type UserContext = {
  __typename?: 'UserContext';
  isAdmin: Scalars['Boolean']['output'];
  powerUserList: Array<PowerUserListItem>;
  user: User;
};

export type UserNetwork = {
  __typename?: 'UserNetwork';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  invitationMeta?: Maybe<InvitationMeta>;
  networkId: Scalars['ID']['output'];
  role: UserNetworkRoleEnum;
  status: UserNetworkStatusEnum;
  updatedAt: Scalars['Date']['output'];
  user?: Maybe<UserNetworkMemberInfo>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type UserNetworkMemberInfo = {
  __typename?: 'UserNetworkMemberInfo';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export enum UserNetworkRoleEnum {
  Admin = 'admin',
  User = 'user'
}

export enum UserNetworkStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending'
}

export type UserOrganization = {
  __typename?: 'UserOrganization';
  addedByTopUser: Scalars['Boolean']['output'];
  isPowerUser: Scalars['Boolean']['output'];
  organizationsId: Scalars['ID']['output'];
  user: User;
  usersId: Scalars['ID']['output'];
};

export type UserOrganizationInput = {
  isPowerUser?: InputMaybe<Scalars['Boolean']['input']>;
  organizationsId: Scalars['String']['input'];
  usersId: Scalars['String']['input'];
};

export type UserOrganizationPermissionInput = {
  isPowerUser: Scalars['Boolean']['input'];
  organizationsId: Scalars['String']['input'];
  usersId: Scalars['String']['input'];
};

export type UserOrganizationRoleUpdateInputBase = {
  isPowerUser: Scalars['Boolean']['input'];
  usersId: Scalars['String']['input'];
};

export enum UserPermissionFeature {
  Activate = 'Activate',
  Analyze = 'Analyze',
  Enrich = 'Enrich',
  Null = 'Null',
  ProjectAperture = 'ProjectAperture',
  Baskets = 'baskets',
  Catalog = 'catalog',
  Contracts = 'contracts',
  CxProjectTracker = 'cxProjectTracker',
  ImportStudy = 'importStudy',
  Opportunities = 'opportunities',
  ProjectTracker = 'projectTracker',
  Reports = 'reports',
  SourcingPlans = 'sourcingPlans',
  Spending = 'spending',
  TeamQueue = 'teamQueue'
}

export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending'
}

export type UsersResult = {
  __typename?: 'UsersResult';
  hits: Array<User>;
  totalHits: Scalars['Int']['output'];
};

export type VendorFeatures = {
  __typename?: 'VendorFeatures';
  opportunityUpload?: Maybe<Scalars['Boolean']['output']>;
  premiumFields?: Maybe<Scalars['Boolean']['output']>;
};

export type VendorFeaturesInput = {
  opportunityUpload?: InputMaybe<Scalars['Boolean']['input']>;
  premiumFields?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VendorPriority = {
  __typename?: 'VendorPriority';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum VendorResponsesEnum {
  Awaiting = 'Awaiting',
  Finished = 'Finished',
  Receiving = 'Receiving',
  Rejected = 'Rejected',
  Responding = 'Responding'
}

export type VendorUsersResult = {
  __typename?: 'VendorUsersResult';
  hits: Array<User>;
  totalHits: Scalars['Int']['output'];
};

export type VendorsResult = {
  __typename?: 'VendorsResult';
  hits: Array<Customer>;
  totalHits: Scalars['Int']['output'];
};

export type VerifyAccountInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export enum CrossReferenceCrossStatus {
  Crossed = 'Crossed',
  Instrument = 'Instrument',
  InvalidPartNumber = 'InvalidPartNumber',
  NoComparable = 'NoComparable',
  Other = 'Other',
  OutOfScope = 'OutOfScope',
  Uncrossed = 'Uncrossed'
}
